<script lang="ts" setup>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@/store';
import useVuelidate from '@vuelidate/core';
import { Company, companyValidations } from '@/models/company';
import Field from '@/components/common/Field.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import Loading from '@/components/common/Loading.vue';
import AddressFields from '@/components/common/AddressFields.vue';
import { useLoading } from '../common/loading.composable';

const store = useStore();
let company = reactive<Company>({ ...store.state.company });
const isNew = computed<boolean>(() => company.id === -1);
const { loading, setLoading } = useLoading();

const $v = useVuelidate(companyValidations, { company });
const emit = defineEmits(['close']);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreCompany, company);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit('close');
};
</script>
<template>
  <section class="drawer-content company-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="company"
    >
      <h2 v-text="isNew ? 'Add company' : 'Edit company'"></h2>
      <p v-if="isNew">Fill out the details of the company</p>
      <p v-else>Edit the details of the company</p>
      <Field
        label="Name"
        :validation="$v.company.name"
        v-model="company.name"
      ></Field>
      <Field
        label="Label"
        :validation="$v.company.label"
        v-model="company.label"
      ></Field>
      <FieldLong
        label="Description"
        :validation="$v.company.description"
        v-model="company.description"
      ></FieldLong>
      <Field
        label="Contact"
        :validation="$v.company.contact"
        v-model="company.contact"
      ></Field>
      <AddressFields
        v-model="company.address"
        :validation="$v.company.address"
        :loading="loading"
      ></AddressFields>
      <div class="buttons">
        <button class="confirm" type="submit" :disabled="loading">
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
