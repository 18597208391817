<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { ProjectOverview } from '@/models/timesheet';

const route = useRoute();
const { project } = defineProps({
  project: {
    type: Object as PropType<ProjectOverview>,
    required: true,
  },
});

const timesheet = computed(() => {
  return route.params.date
    ? `/timesheet/project/${project.id}/${route.params.date}`
    : `/timesheet/project/${project.id}`;
});
</script>
<template>
  <tr>
    <td>
      <RouterLink :to="timesheet">{{ project.name }}</RouterLink>
    </td>
    <td class="hours" v-text="project.hours"></td>
  </tr>
</template>
