import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["textContent"]

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Tooltip',
  props: {
  text: { type: String, default: '' },
  highlight: { type: Boolean, default: false },
  inline: { type: Boolean, default: false },
  containerStyle: { type: String, default: '' },
  tooltipStyle: { type: String, default: 'tooltip-top' },
},
  setup(__props) {



const visible = ref<boolean>(false);

const toggle = () => {
  visible.value = !visible.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tooltip-container", [{ highlight: visible.value && __props.highlight, inline: __props.inline }, __props.containerStyle]]),
    onMouseover: toggle,
    onMouseout: toggle
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["tooltip", [__props.tooltipStyle]])
        }, [
          _createElementVNode("div", {
            class: "tooltip-text",
            textContent: _toDisplayString(__props.text)
          }, null, 8, _hoisted_1)
        ], 2))
      : _createCommentVNode("", true)
  ], 34))
}
}

})