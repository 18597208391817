<script lang="ts" setup>
import {
  computed,
  markRaw,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';
import { TimesheetDay } from '@/models/timesheet-day';
import { KeyUtil } from '@/core/util/key.util';
import { TimeUtil } from '@/core/util/time.util';
import { format } from 'date-fns';
import DayOverview from './overview/DayOverview.vue';
import HoursCalculator from './HoursCalculator.vue';
import HoursDetails from './HoursDetails.vue';
import HoursHelp from './HoursHelp.vue';
import { useEvents, Event } from '@/core/services/events.composable';

const events = useEvents();

const props = defineProps({
  data: {
    type: Object as PropType<TimesheetDay>,
    required: true,
  },
  project_id: {
    type: Number,
    required: true,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
});

const { data, project_id, autocomplete } = props;

const hoursInput = ref<HTMLInputElement | null>(null);

const day = ref<TimesheetDay>(
  new TimesheetDay({
    day: 1,
    week_number: 1,
    date: format(new Date(), 'yyyy-MM-dd'),
  }),
);
const children = ref<ChildComponent[]>([]);
const focussed = ref<boolean>(false);
const name = computed(() => `day_${props.data.date}`);

watch(props.data, () => {
  day.value.setData({
    ...props.data,
    project_id,
  });
});

onMounted(() => {
  day.value.setData({
    ...data,
    project_id: props.project_id,
  });
  events.on(Event.HoursFocus, checkFocus);
  events.on(Event.HoursStored, stored);
});

onBeforeUnmount(() => {
  events.off(Event.HoursFocus, checkFocus);
  events.off(Event.HoursStored, stored);
});

const change = () => {
  if (day.value.isChanged()) {
    day.value.stage();
    events.emit(Event.HoursChanged, day.value as TimesheetDay);
  } else if (day.value.staged) {
    events.emit(Event.HoursResetChanged, day.value as TimesheetDay);
  }
};

const formatValue = () => {
  const f = parseFloat(day.value.hours!);
  if (TimeUtil.isValidTime(day.value.hours!)) {
    day.value.hours = `${TimeUtil.timeToDecimal(day.value.hours!).toFixed(2)}`;
  } else if (!isNaN(f)) {
    day.value.hours = f.toFixed(2);
  }
  change();
};

const focus = () => {
  events.emit(Event.HoursFocus, data);
  hoursInput.value && hoursInput.value.select();
};

const checkFocus = (d: TimesheetDay | null) => {
  focussed.value = d ? d.date === data.date : false;

  if (!focussed.value) {
    children.value.pop();
  }
};

const blur = () => {
  formatValue();
  if (!children.value.length) {
    events.emit(Event.HoursFocus, null);
  }
};

const save = () => {
  formatValue();
  events.emit(Event.HoursStore, day.value);
};

const stored = () => {
  day.value.setData({
    ...props.data,
    project_id,
  });
  day.value.unstage();
  day.value.setState();
};

const keydown = (evt: KeyboardEvent) => {
  // disallow letters and such
  if (!KeyUtil.isAllowedHoursKey(evt.keyCode)) {
    evt.preventDefault();
  }
};

const addHour = () => {
  day.value.hours = (parseFloat(day.value.hours || '0') + 1).toFixed(2);
  change();
};

const subtractHour = () => {
  day.value.hours = (parseFloat(day.value.hours || '0') - 1).toFixed(2);
  change();
};

// called when a child component closes, or when escape is pressed
const closeChild = (type?: string, value?: string) => {
  if (type === 'details' && value) {
    day.value.description = value;
    change();
  } else if (type === 'help' && value && typeof value === 'string') {
    day.value.hours = value;
    change();
  } else if (type === 'calculator' && value && typeof value === 'string') {
    day.value.hours = value;
    change();
  }
  focus();
  children.value.pop();
};

class ChildComponent {
  component: any;
  data: any;

  constructor({ component, data }: { component: any; data?: any }) {
    this.component = markRaw(component);
    this.data = data;
  }

  get key() {
    return this.component;
  }
}

const showDetails = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursDetails,
      data: {
        description: day.value.description,
      },
    }),
  );
};

const showHoursHelp = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursHelp,
    }),
  );
};

const showHoursCalculator = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursCalculator,
    }),
  );
};

const showDayOverview = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: DayOverview,
      data: {
        date: day.value.date,
      },
    }),
  );
};
</script>
<template>
  <li
    :data-day="props.data.day"
    class="form-item form-item-hours"
    :class="{
      othermonth: props.data.next || props.data.previous,
      today: props.data.today,
      holiday: props.data.holiday,
      changed: day.isChanged(),
      focussed,
    }"
    :title="props.data.holiday ? props.data.holiday.name : ''"
    v-if="data"
  >
    <input
      type="text"
      class="date"
      ref="hoursInput"
      step="1.00"
      :name="name"
      v-model="day.hours"
      @focus="focus"
      @blur="blur"
      :autocomplete="autocomplete"
      @keydown.exact="keydown"
      @keydown.escape.exact.prevent="() => closeChild()"
      @keydown.enter.exact.prevent="save"
      @keyup.ctrl.84.exact.prevent="showDayOverview"
      @keyup.up.ctrl.exact.prevent="showHoursHelp"
      @keyup.up.alt.exact.prevent="showHoursCalculator"
      @keyup.down.ctrl.exact.prevent="showDetails"
      @keyup.up.exact.prevent="addHour"
      @keyup.down.exact.prevent="subtractHour"
    />
    <component
      v-for="child in children"
      :key="child.key"
      :is="child.component"
      @close="closeChild"
      :data="child.data"
    ></component>
  </li>
</template>
