<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const auth = computed(() => store.state.auth);
</script>
<template>
  <nav>
    <ul class="menu" v-if="auth && auth.isUser">
      <li>
        <RouterLink to="/timesheet"> Timesheet </RouterLink>
      </li>
      <li>
        <RouterLink to="/company"> Companies </RouterLink>
      </li>
      <li>
        <RouterLink to="/timesheet/overview"> Overview </RouterLink>
      </li>
      <li>
        <RouterLink to="/balance"> Balance </RouterLink>
      </li>
    </ul>
  </nav>
</template>
