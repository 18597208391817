<script lang="ts" setup>
import { PropType } from 'vue';
import { ProjectInfo } from '@/models/project-info';
import Tooltip from '@/components/common/Tooltip.vue';

const props = defineProps({
  project: {
    type: Object as PropType<ProjectInfo>,
    required: true,
  },
});

const emit = defineEmits(['edit:project']);

const edit = (id: number) => {
  emit('edit:project', id);
};
</script>
<template>
  <div class="project-info">
    <h2>
      <span v-text="props.project.name"></span>
      <Tooltip text="Edit project" inline>
        <button class="option icononly" @click="edit(props.project.id)">
          <span class="icon icon-pencil"></span>
        </button>
      </Tooltip>
    </h2>
    <p class="start-date">
      Started {{ props.project.started
      }}<span v-if="props.project.ended">
        , ended {{ props.project.ended }}
      </span>
    </p>

    <p v-text="props.project.description"></p>
  </div>
</template>
