<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import {
  Invoice,
  InvoiceLine,
  InvoiceOption,
  InvoiceType,
  invoiceValidations,
} from '@/models/invoice';
import { Timesheet } from '../../models/timesheet';
import { Option } from '../../models/option';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import FieldOptions from '@/components/common/FieldOptions.vue';
import InvoiceCompanyInfo from './InvoiceCompanyInfo.vue';
import InvoiceFormLine from './InvoiceFormLine.vue';
import InvoiceFormOption from './InvoiceFormOption.vue';
import Loading from '@/components/common/Loading.vue';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';
import { getMonthName } from '@/core/util/date.util';
import { TimesheetActions } from '@/stores/timesheet.store';

const store = useStore();
const route = useRoute();
const router = useRouter();
const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const { loading, setLoading } = useLoading();

const subject = (): string => {
  return `${timesheet.value.info.title}`;
};

const getFirstLineDescription = (l: 'en' | 'nl', date: Date): string => {
  const hours = l === 'en' ? 'hours' : 'uren';
  const rounded = l === 'en' ? 'rounded' : 'afgerond';
  return `${timesheet.value.project.name} ${hours} ${getMonthName(
    date.getMonth(),
    l,
  )} (${rounded})`;
};

const date = new Date(route.params.date as string);
const invoice = reactive<Invoice>(
  new Invoice({
    id: -1,
    project_id: timesheet.value.project.id,
    company_id: timesheet.value.company.id!,
    number: '',
    date_created: date.toISOString(),
    date_reference: `${timesheet.value.info.month}-01`,
    subject: subject(),
    lines: [
      {
        name: 'one',
        description: getFirstLineDescription('en', date),
        amount: timesheet.value.monthTotal,
        price: timesheet.value.project.rate,
        vat: 0.21,
        total: timesheet.value.monthTotal * timesheet.value.project.rate,
      },
    ],
    options: [
      ...(timesheet.value.project.options || []).map((i) => ({
        label: i.label,
        value: i.value,
      })),
    ],
    paid: false,
    type: InvoiceType.Hours,
    language: 'en',
  }),
);
const $v = useVuelidate(invoiceValidations, invoice);
const language = ref<string>('en');
const languages = ref<Option<string>[]>([
  {
    name: 'Nederlands',
    value: 'nl',
  },
  {
    name: 'English',
    value: 'en',
  },
]);
const types = ref<Option<string>[]>([
  {
    name: 'Hours',
    value: 'hours',
  },
  {
    name: 'Other',
    value: 'items',
  },
]);

const load = async () => {
  const id = route.params.id;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheet, {
    id,
    date: route.params.date,
  });
  setLoading(false);
};

onMounted(load);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(BalanceActions.AddInvoice, invoice);
    router.push('/balance');
    setLoading(false);
  }
};

const cancel = () => {
  router.push(`/timesheet/project/${route.params.id}/${route.params.month}-01`);
};

const removeLine = (line: InvoiceLine) => {
  invoice.removeLine(line);
};

const addLine = () => {
  invoice.addLine();
};

const removeOption = (option: InvoiceOption) => {
  invoice.removeOption(option);
};

const addOption = () => {
  invoice.addOption();
};

const updateLanguage = (l: 'en' | 'nl') => {
  language.value = l;
  invoice.language = l;
  invoice.subject = `${getMonthName(date.getMonth(), l)} ${date.getFullYear()}`;
  const line = invoice.lines[0];

  if (line) {
    invoice.lines[0] = {
      name: line.name,
      description: getFirstLineDescription(l, date),
      amount: line.amount,
      price: line.price,
      vat: line.vat,
      total: line.total,
    };
  }
};
</script>
<template>
  <section>
    <form
      class="form invoice-form"
      @submit.prevent="submit"
      :class="{ submitting: loading }"
    >
      <h2>Add Invoice</h2>
      <div class="invoice" v-if="timesheet && invoice">
        <InvoiceCompanyInfo :company="timesheet.company"></InvoiceCompanyInfo>
        <ul class="items invoice-info">
          <li>
            <label>Invoice number</label>
            <div>{{ invoice.invoiceNumber }}</div>
          </li>
          <li>
            <label>Invoice date</label>
            <div>{{ timesheet.info.date_formatted }}</div>
          </li>
          <li>
            <label>Invoice period</label>
            <div>{{ invoice.subject }}</div>
          </li>
        </ul>
        <section class="field">
          <label>References</label>
          <div style="flex: 1">
            <InvoiceFormOption
              v-model="invoice.options"
              v-for="option in invoice.options"
              :option="option"
              :key="option.value"
              :isOnly="invoice.options.length === 1"
              @remove="removeOption(option)"
              @add="addOption()"
            ></InvoiceFormOption>
          </div>
        </section>
        <div class="columns">
          <Field
            label="Invoice period"
            placeholder="Invoice period"
            styling="col two-quarters"
            v-model="invoice.subject"
            :validation="$v.subject"
          ></Field>
        </div>
        <div class="columns">
          <FieldOptions
            label="Language"
            @update:modelValue="updateLanguage"
            styling="col two-quarters"
            type="radio"
            :options="languages"
            v-model="invoice.language"
          ></FieldOptions>
          <FieldOptions
            label="Type"
            styling="col two-quarters"
            type="radio"
            :options="types"
            v-model="invoice.type"
          ></FieldOptions>
        </div>
        <p>
          Enter the VAT and the amount of the invoice. Add more lines as needed.
        </p>
        <InvoiceFormLine
          v-for="line in invoice.lines"
          :line="line"
          :key="line.name"
          :isOnly="invoice.lines.length === 1"
          @remove="removeLine(line)"
          @add="addLine()"
        ></InvoiceFormLine>
        <div class="balance-total-line columns">
          <div class="col third" />
          <div class="col onetenth" />
          <div class="col onefifth" />
          <div class="col oneeight field">
            <label>Subtotal</label>
          </div>
          <FieldNumber
            label="Subtotal"
            hide-label
            readonly
            styling="col oneeight"
            v-model="invoice.sub_total"
            step="0.01"
          ></FieldNumber>
          <div class="col onetenth" />
        </div>
        <div class="balance-total-line columns">
          <div class="col third" />
          <div class="col onetenth" />
          <div class="col onetenth" />
          <div class="col onetenth field">
            <label>VAT</label>
          </div>
          <FieldNumber
            label="VatTotal"
            hide-label
            readonly
            styling="col oneeight"
            v-model="invoice.vat_total"
            step="0.01"
          ></FieldNumber>
          <div class="col onetenth" />
        </div>
        <div class="balance-total-line columns">
          <div class="col third" />
          <div class="col onetenth" />
          <div class="col onefifth" />
          <div class="col oneeight field">
            <label>Total</label>
          </div>
          <FieldNumber
            label="total"
            hide-label
            readonly
            styling="col oneeight"
            v-model="invoice.total"
            step="0.01"
          ></FieldNumber>
          <div class="col onetenth" />
        </div>
        <div class="buttons">
          <button class="confirm" type="submit" :disabled="loading">
            <span class="icon icon-ok"></span>
            <span class="text">Add</span>
          </button>
          <button class="cancel" type="button" @click="cancel">
            <span class="icon icon-remove"></span>
            <span class="text">Clear</span>
          </button>
          <Loading v-if="loading"></Loading>
        </div>
      </div>
    </form>
  </section>
</template>
