import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen balance"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "language-switch" }

import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from '@/core/i18n/i18n.composable';
import { Actions } from '@/store';
import { BalanceActions } from '@/stores/balance.store';
import BalanceOverview from './BalanceOverview.vue';
import TaxExpenseList from './TaxExpenseList.vue';
import TaxInvoiceList from './TaxInvoiceList.vue';
import Loading from '@/components/common/Loading.vue';
import LanguageSwitch from '@/components/common/LanguageSwitch.vue';
import { Balance } from '@/models/balance';
import { Company } from '@/models/company';
import { I18NLanguage } from '@/core/i18n';
import { useLoading } from '../common/loading.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxBalance',
  setup(__props) {

const store = useStore();
const route = useRoute();
const { loading, setLoading } = useLoading();

const balance = computed<Balance>(() => store.state.bs.balance);
const companies = computed<Company[]>(() => store.state.companies);

const { language, i18nData, setLanguage } = useI18n(I18NLanguage.en);

const type = () => {
  const is_year = balance.value.info.is_year;
  const is_quarter = balance.value.info.is_quarter;
  const is_month = balance.value.info.is_month;
  return is_year
    ? 'year'
    : is_quarter
    ? 'quarter'
    : is_month
    ? 'month'
    : 'quarter';
};

const year = computed(() => {
  return `/balance/year/${balance.value.info.title}`;
});

const load = async () => {
  const type = route.params.type;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetBalance, {
    type,
    date,
  });
  await store.dispatch(Actions.GetCompanies);
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (balance.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (!balance.value)
          ? (_openBlock(), _createBlock(Loading, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("h2", {
          textContent: _toDisplayString(`Front Crafter ${_unref(i18nData).overview} ${balance.value.info.title}`)
        }, null, 8, _hoisted_2),
        _createVNode(LanguageSwitch, {
          language: _unref(language),
          onSetLanguage: _cache[0] || (_cache[0] = ($event: any) => (_unref(setLanguage)($event)))
        }, null, 8, ["language"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_RouterLink, { to: year.value }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "icon icon-calendar" }, null, -1),
              _createTextVNode("See year balance ")
            ])),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(BalanceOverview, {
          i18n: _unref(i18nData),
          overview: balance.value.overview,
          loading: _unref(loading),
          "hide-title": ""
        }, null, 8, ["i18n", "overview", "loading"]),
        _createVNode(TaxInvoiceList, {
          i18n: _unref(i18nData),
          invoices: balance.value.invoices,
          companies: companies.value
        }, null, 8, ["i18n", "invoices", "companies"]),
        _createVNode(TaxExpenseList, {
          i18n: _unref(i18nData),
          expenses: balance.value.expenses
        }, null, 8, ["i18n", "expenses"])
      ]))
    : _createCommentVNode("", true)
}
}

})