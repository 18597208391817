import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drawer-content project-form" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "field field-long" }
const _hoisted_6 = { class: "field block" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "buttons" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["textContent"]

import { computed, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { Actions } from '@/store';
import {
  INVOICE_TYPE,
  DIRECT_TYPE,
  MONTHLY_BILLING,
  PERIODIC_BILLING,
  Project,
  projectValidations,
} from '@/models/project';
import { Company } from '@/models/company';
import { Option } from '@/models/option';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import FieldSelect from '@/components/common/FieldSelect.vue';
import FieldSwitch from '@/components/common/FieldSwitch.vue';
import { useLoading } from '../common/loading.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectForm',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const store = useStore();
const project = reactive<Project>({ ...store.state.project });
const isNew = computed<boolean>(() => project.id === -1);
const companies = computed<Company[]>(() => store.state.companies);
const companyOptions = computed((): Option<number>[] => {
  return companies.value.map((company: Company) => ({
    name: company.name,
    value: company.id,
  }));
});
const { loading, setLoading } = useLoading();

const typeOptions: Option<string>[] = [
  {
    name: 'No specific type',
    value: '',
  },
  {
    name: 'Direct',
    value: DIRECT_TYPE,
  },
  {
    name: 'Invoiceable',
    value: INVOICE_TYPE,
  },
];
const periodOptions: Option<string | null>[] = [
  {
    name: 'Choose an invoice period',
    value: null,
  },
  {
    name: 'Monthly',
    value: MONTHLY_BILLING,
  },
  {
    name: 'Periodically',
    value: PERIODIC_BILLING,
  },
];

const $v = useVuelidate(projectValidations, { project });
const emit = __emit;

watch(
  () => project.type,
  (type: string) => {
    if (type !== 'invoice') {
      project.period = null;
    }
  },
);

onMounted(async () => {
  await store.dispatch(Actions.GetCompanies);
});

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreProject, project);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit('close');
};

const addOption = () => {
  if (!project.options) {
    project.options = [];
  }
  project.options.push({
    label: '',
    value: '',
  });
};

const removeOption = (index: number) => {
  project.options.splice(index, 1);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (project && companyOptions.value.length)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _withModifiers(submit, ["prevent"]),
          class: _normalizeClass({ submitting: _unref(loading) })
        }, [
          _createElementVNode("h2", {
            textContent: _toDisplayString(isNew.value ? 'Add project' : 'Edit project')
          }, null, 8, _hoisted_2),
          (isNew.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Fill out the details of the project"))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, "Edit the details of the project")),
          _createVNode(FieldSelect, {
            label: "Company",
            validation: _unref($v).project.company_id,
            modelValue: project.company_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((project.company_id) = $event)),
            options: companyOptions.value
          }, null, 8, ["validation", "modelValue", "options"]),
          _createVNode(Field, {
            label: "Name",
            validation: _unref($v).project.name,
            modelValue: project.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((project.name) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(FieldSelect, {
            label: "Type",
            validation: _unref($v).project.type,
            modelValue: project.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((project.type) = $event)),
            options: typeOptions,
            "show-null": false
          }, null, 8, ["validation", "modelValue"]),
          (project.type == 'invoice')
            ? (_openBlock(), _createBlock(FieldNumber, {
                key: 2,
                label: "Current rate",
                placeholder: "Rate",
                validation: _unref($v).project.rate,
                modelValue: project.rate,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((project.rate) = $event)),
                modelModifiers: { number: true },
                step: "1.00",
                type: "number",
                styling: "field-number"
              }, null, 8, ["validation", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(Field, {
            label: "Start",
            validation: _unref($v).project.date_start,
            modelValue: project.date_start,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((project.date_start) = $event)),
            type: "date",
            styling: "field-date"
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(Field, {
            label: "End",
            modelValue: project.date_end,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((project.date_end) = $event)),
            type: "date",
            styling: "field-date"
          }, null, 8, ["modelValue"]),
          (project.type == 'invoice')
            ? (_openBlock(), _createBlock(FieldSelect, {
                key: 3,
                label: "Invoice period",
                validation: _unref($v).project.period,
                modelValue: project.period,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((project.period) = $event)),
                options: periodOptions
              }, null, 8, ["validation", "modelValue"]))
            : _createCommentVNode("", true),
          (project.period == 'periodic')
            ? (_openBlock(), _createBlock(Field, {
                key: 4,
                label: "Period start",
                validation: _unref($v).project.period_start,
                modelValue: project.period_start,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((project.period_start) = $event)),
                type: "date",
                styling: "field-date"
              }, null, 8, ["validation", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(FieldLong, {
            label: "Description",
            validation: _unref($v).project.description,
            modelValue: project.description,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((project.description) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(FieldSwitch, {
            label: "Archived",
            modelValue: project.archived,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((project.archived) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("section", _hoisted_5, [
            _cache[12] || (_cache[12] = _createElementVNode("label", null, "Reference", -1)),
            _createElementVNode("section", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.options, (option, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "fields"
                }, [
                  _createVNode(Field, {
                    label: "Label",
                    modelValue: option.label,
                    "onUpdate:modelValue": ($event: any) => ((option.label) = $event),
                    type: "text",
                    styling: "block"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(Field, {
                    label: "Value",
                    modelValue: option.value,
                    "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                    type: "text",
                    styling: "block"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createElementVNode("button", {
                    class: "option field-option icononly",
                    type: "button",
                    onClick: _withModifiers(($event: any) => (removeOption(index)), ["prevent"])
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("span", { class: "icon icon-minus" }, null, -1)
                  ]), 8, _hoisted_7)
                ]))
              }), 128)),
              _createElementVNode("button", {
                class: "option",
                type: "button",
                onClick: _withModifiers(addOption, ["prevent"])
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("span", { class: "icon icon-plus" }, null, -1),
                _createTextVNode(" Add ")
              ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "confirm",
              type: "button",
              disabled: _unref(loading),
              onClick: submit
            }, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)),
              _createElementVNode("span", {
                class: "text",
                textContent: _toDisplayString(isNew.value ? 'Add' : 'Edit')
              }, null, 8, _hoisted_10)
            ], 8, _hoisted_9),
            _createElementVNode("button", {
              class: "cancel",
              type: "button",
              onClick: cancel
            }, _cache[14] || (_cache[14] = [
              _createElementVNode("span", { class: "text" }, "Cancel", -1)
            ])),
            (_unref(loading))
              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ], 34))
      : _createCommentVNode("", true)
  ]))
}
}

})