import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notifications"
}

import { computed } from 'vue';
import { useStore } from 'vuex';
import { Mutations } from '@/store';
import { Notification } from '@/models/notification';
import Message from './Message.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Notifications',
  setup(__props) {

const store = useStore();
const notifications = computed<Notification[]>(() => store.state.notifications);

const dismiss = (id: number) => {
  store.commit(Mutations.DismissNotification, id);
};

return (_ctx: any,_cache: any) => {
  return (notifications.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.value, (notification) => {
          return (_openBlock(), _createBlock(Message, {
            key: notification.id,
            type: notification.type,
            message: notification.message,
            onDismiss: ($event: any) => (dismiss(notification.id)),
            show: ""
          }, null, 8, ["type", "message", "onDismiss"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})