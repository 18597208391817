<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { useStore } from 'vuex';
import { TimeUtil } from '@/core/util/time.util';
import { isWeekTotal, Days, WeekTotal } from '@/models/timesheet';
import { TimesheetDay } from '@/models/timesheet-day';
import Tooltip from '@/components/common/Tooltip.vue';

const store = useStore();

const timesheet_hours = computed<TimesheetDay[]>(
  () => store.getters['ts/timesheet_hours'],
);

const { data } = defineProps({
  data: {
    type: Object as PropType<WeekTotal>,
    default: () => {
      return {};
    },
  },
});

const week_total = computed<string>(() => {
  return TimeUtil.minutesToTime(
    timesheet_hours.value
      .filter(
        (day: Days) =>
          day.week_number === data.week_number && !isWeekTotal(day),
      )
      .reduce((acc: number, day: Days) => {
        acc += (day as TimesheetDay).minutes || 0;
        return acc;
      }, 0),
  );
});

const week_all_total = computed(() => {
  return TimeUtil.minutesToTime(
    TimeUtil.decimalToMinutes(data.week_all_total) -
      TimeUtil.decimalToMinutes(data.week_total) +
      TimeUtil.timeToMinutes(week_total.value),
  );
});

const getTooltipWeek = () => {
  return `Total hours for this project in week ${data.week_number}`;
};
const getTooltipWeekAll = () => {
  return `Total hours for all projects in week ${data.week_number}`;
};
</script>
<template>
  <li class="weektotals">
    <Tooltip :text="getTooltipWeek()" containerStyle="total week-project-total">
      <span v-text="week_total"></span>
    </Tooltip>
    <Tooltip :text="getTooltipWeekAll()" containerStyle="total week-all-total">
      <span v-text="week_all_total"></span>
    </Tooltip>
  </li>
</template>
