import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "timesheet-overview"
}
const _hoisted_2 = { class: "small-loader-header" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "options" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "month-hours" }
const _hoisted_8 = {
  key: 0,
  colspan: "4"
}
const _hoisted_9 = ["rowspan"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]

import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TimesheetActions } from '@/stores/timesheet.store';
import { DayHours } from '../../../models/timesheet';
import { useLoading } from '@/components/common/loading.composable';
import Loading from '@/components/common/Loading.vue';

interface DayByDay {
  [index: string]: DayByDayHours;
}

interface DayByDayHours {
  date: string;
  format_date: string;
  hours: DayHours[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DayByDayOverview',
  setup(__props) {

const store = useStore();
const route = useRoute();
const { loading, setLoading } = useLoading();

const overview_by_day = computed(() => store.state.ts.overview_by_day);

const previous = computed(
  () => `/timesheet/overview/day-by-day/${overview_by_day.value.info.previous}`,
);

const next = computed(
  () => `/timesheet/overview/day-by-day/${overview_by_day.value.info.next}`,
);

const regular = computed(
  () => `/timesheet/overview/${overview_by_day.value.info.current}`,
);

const totalHours = computed((): string => {
  return overview_by_day.value.hours
    .reduce((s: number, h: DayHours) => s + parseFloat(h.hours), 0)
    .toFixed(2);
});

const day_by_day = computed((): DayByDayHours[] => {
  const days = overview_by_day.value.hours.reduce(
    (acc: DayByDay, h: DayHours) => {
      if (!acc[h.date]) {
        acc[h.date] = {
          date: h.date,
          format_date: h.format_date,
          hours: [h],
        };
      } else {
        acc[h.date].hours.push(h);
      }
      return acc;
    },
    {},
  );
  return Object.values(days);
});

const load = async () => {
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetDayByDayOverview, { date });
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_raise_ordinal = _resolveDirective("raise-ordinal")!

  return (overview_by_day.value)
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, [
          _createTextVNode(" Day by day overview " + _toDisplayString(overview_by_day.value.info.month_name) + " ", 1),
          (_unref(loading))
            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        (!overview_by_day.value)
          ? (_openBlock(), _createBlock(Loading, { key: 0 }))
          : (_openBlock(), _createElementBlock("section", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: previous.value }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon icon-caret-left" }, null, -1)),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(overview_by_day.value.info.previous_name)
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: next.value }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon icon-caret-right" }, null, -1)),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(overview_by_day.value.info.next_name)
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: regular.value }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("span", { class: "icon icon-archive" }, null, -1),
                      _createElementVNode("span", null, "See month and totals", -1)
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]),
              _createElementVNode("table", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", { width: "120px" }, "Date"),
                    _createElementVNode("th", { width: "200px" }, "Project"),
                    _createElementVNode("th", null, "Description"),
                    _createElementVNode("th", {
                      width: "80px",
                      class: "align-right"
                    }, "Hours")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (!day_by_day.value.length)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_8, "There are no hours yet"))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day_by_day.value, (day) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: `${day.date}-day`
                    }, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          rowspan: day.hours.length
                        }, [
                          _withDirectives(_createElementVNode("span", {
                            textContent: _toDisplayString(day.format_date)
                          }, null, 8, _hoisted_10), [
                            [_directive_raise_ordinal]
                          ])
                        ], 8, _hoisted_9),
                        _createElementVNode("td", {
                          textContent: _toDisplayString(day.hours[0].project_name)
                        }, null, 8, _hoisted_11),
                        _createElementVNode("td", {
                          textContent: _toDisplayString(day.hours[0].description)
                        }, null, 8, _hoisted_12),
                        _createElementVNode("td", {
                          textContent: _toDisplayString(day.hours[0].hours),
                          class: "align-right"
                        }, null, 8, _hoisted_13)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.hours.slice(1), (hour) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: `${hour.date}-${hour.project_id}`
                        }, [
                          _createElementVNode("td", {
                            textContent: _toDisplayString(hour.project_name)
                          }, null, 8, _hoisted_14),
                          _createElementVNode("td", {
                            textContent: _toDisplayString(hour.description)
                          }, null, 8, _hoisted_15),
                          _createElementVNode("td", {
                            class: "align-right",
                            textContent: _toDisplayString(hour.hours)
                          }, null, 8, _hoisted_16)
                        ]))
                      }), 128))
                    ], 64))
                  }), 128))
                ]),
                _createElementVNode("tfoot", null, [
                  _createElementVNode("tr", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("td", {
                      class: "empty",
                      colspan: "3"
                    }, null, -1)),
                    _createElementVNode("td", {
                      class: "align-right",
                      textContent: _toDisplayString(totalHours.value)
                    }, null, 8, _hoisted_17)
                  ])
                ])
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}
}

})