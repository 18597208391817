<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SubMenu from '@/components/common/SubMenu.vue';
import CompanyProjectList from './CompanyProjectList.vue';

const store = useStore();
const auth = computed(() => store.state.auth);
</script>
<template>
  <section class="system-page">
    <h2>System</h2>
    <SubMenu>
      <RouterLink to="/system/holidays" v-if="auth && auth.isAdmin"
        >Holidays</RouterLink
      >
    </SubMenu>
    <CompanyProjectList />
  </section>
</template>
