<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { Actions } from '@/store';
import {
  INVOICE_TYPE,
  DIRECT_TYPE,
  MONTHLY_BILLING,
  PERIODIC_BILLING,
  Project,
  projectValidations,
} from '@/models/project';
import { Company } from '@/models/company';
import { Option } from '@/models/option';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import FieldSelect from '@/components/common/FieldSelect.vue';
import FieldSwitch from '@/components/common/FieldSwitch.vue';
import { useLoading } from '../common/loading.composable';

const store = useStore();
const project = reactive<Project>({ ...store.state.project });
const isNew = computed<boolean>(() => project.id === -1);
const companies = computed<Company[]>(() => store.state.companies);
const companyOptions = computed((): Option<number>[] => {
  return companies.value.map((company: Company) => ({
    name: company.name,
    value: company.id,
  }));
});
const { loading, setLoading } = useLoading();

const typeOptions: Option<string>[] = [
  {
    name: 'No specific type',
    value: '',
  },
  {
    name: 'Direct',
    value: DIRECT_TYPE,
  },
  {
    name: 'Invoiceable',
    value: INVOICE_TYPE,
  },
];
const periodOptions: Option<string | null>[] = [
  {
    name: 'Choose an invoice period',
    value: null,
  },
  {
    name: 'Monthly',
    value: MONTHLY_BILLING,
  },
  {
    name: 'Periodically',
    value: PERIODIC_BILLING,
  },
];

const $v = useVuelidate(projectValidations, { project });
const emit = defineEmits(['close']);

watch(
  () => project.type,
  (type: string) => {
    if (type !== 'invoice') {
      project.period = null;
    }
  },
);

onMounted(async () => {
  await store.dispatch(Actions.GetCompanies);
});

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreProject, project);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit('close');
};

const addOption = () => {
  if (!project.options) {
    project.options = [];
  }
  project.options.push({
    label: '',
    value: '',
  });
};

const removeOption = (index: number) => {
  project.options.splice(index, 1);
};
</script>
<template>
  <section class="drawer-content project-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="project && companyOptions.length"
    >
      <h2 v-text="isNew ? 'Add project' : 'Edit project'"></h2>
      <p v-if="isNew">Fill out the details of the project</p>
      <p v-else>Edit the details of the project</p>
      <FieldSelect
        label="Company"
        :validation="$v.project.company_id"
        v-model="project.company_id"
        :options="companyOptions"
      ></FieldSelect>
      <Field
        label="Name"
        :validation="$v.project.name"
        v-model="project.name"
      ></Field>
      <FieldSelect
        label="Type"
        :validation="$v.project.type"
        v-model="project.type"
        :options="typeOptions"
        :show-null="false"
      ></FieldSelect>
      <FieldNumber
        v-if="project.type == 'invoice'"
        label="Current rate"
        placeholder="Rate"
        :validation="$v.project.rate"
        v-model.number="project.rate"
        step="1.00"
        type="number"
        styling="field-number"
      ></FieldNumber>
      <Field
        label="Start"
        :validation="$v.project.date_start"
        v-model="project.date_start"
        type="date"
        styling="field-date"
      ></Field>
      <Field
        label="End"
        v-model="project.date_end"
        type="date"
        styling="field-date"
      ></Field>
      <FieldSelect
        v-if="project.type == 'invoice'"
        label="Invoice period"
        :validation="$v.project.period"
        v-model="project.period"
        :options="periodOptions"
      ></FieldSelect>
      <Field
        v-if="project.period == 'periodic'"
        label="Period start"
        :validation="$v.project.period_start"
        v-model="project.period_start"
        type="date"
        styling="field-date"
      ></Field>
      <FieldLong
        label="Description"
        :validation="$v.project.description"
        v-model="project.description"
      ></FieldLong>
      <FieldSwitch label="Archived" v-model="project.archived"></FieldSwitch>
      <section class="field field-long">
        <label>Reference</label>
        <section class="field block">
          <div
            v-for="(option, index) in project.options"
            :key="index"
            class="fields"
          >
            <Field
              label="Label"
              v-model="option.label"
              type="text"
              styling="block"
            ></Field>
            <Field
              label="Value"
              v-model="option.value"
              type="text"
              styling="block"
            ></Field>
            <button
              class="option field-option icononly"
              type="button"
              @click.prevent="removeOption(index)"
            >
              <span class="icon icon-minus"></span>
            </button>
          </div>
          <button class="option" type="button" @click.prevent="addOption">
            <span class="icon icon-plus"></span> Add
          </button>
        </section>
      </section>
      <div class="buttons">
        <button
          class="confirm"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="icon icon-pencil"></span>
          <span class="text" v-text="isNew ? 'Add' : 'Edit'"></span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
