import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "timesheet-info" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]

import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Actions } from '@/store';
import { TimesheetActions } from '@/stores/timesheet.store';
import { Invoice } from '@/models/invoice';
import { Company } from '@/models/company';
import InvoiceList from '../balance/InvoiceList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimesheetInvoice',
  props: {
  month: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const store = useStore();
const route = useRoute();
const timesheet_invoices = computed<Invoice[]>(
  () => store.state.ts.timesheet_invoices,
);
const companies = computed<Company[]>(() => store.state.companies);



const date = computed(() => {
  return route.params.date ? route.params.date : `${__props.month}-01`;
});

const invoice = computed(() => {
  return `/balance/invoice/new/date/${date.value}/project/${route.params.id}`;
});

const update = () => {
  store.dispatch(Actions.GetCompanies);
  store.dispatch(TimesheetActions.GetTimesheetInvoices, {
    id: route.params.id,
    date: date.value,
  });
};

onMounted(() => {
  update();
});

watch(route, update);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Invoices", -1)),
    (timesheet_invoices.value.length)
      ? (_openBlock(), _createBlock(InvoiceList, {
          key: 0,
          invoices: timesheet_invoices.value,
          companies: companies.value,
          "show-header": false
        }, null, 8, ["invoices", "companies"]))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, "No invoices could be found")),
    _createVNode(_component_RouterLink, {
      to: invoice.value,
      custom: ""
    }, {
      default: _withCtx(({ navigate }) => [
        _createElementVNode("button", {
          type: "button",
          class: "option wide",
          onClick: navigate
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "icon icon-file" }, null, -1),
          _createTextVNode(" Create invoice ")
        ]), 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})