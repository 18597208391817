import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "box test-form" }

import { computed, reactive } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import AddressFields from '@/components/common/AddressFields.vue';
import TestItemFields from '@/components/TestItemFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestForm',
  setup(__props) {

const data = reactive({
  firstName: '',
  lastName: '',
  address: {
    name: '',
    line_one: '',
    line_two: '',
    zipcode: '',
    city: '',
    country: 'Netherlands',
  },
  items: [
    {
      id: 1,
      price: 1,
      vat: 0.21,
    },
    {
      id: 2,
      price: 15,
      vat: 0.21,
    },
    {
      id: 3,
      price: 20,
      vat: 0.21,
    },
  ],
});

const rules: ValidationArgs = {
  firstName: {
    required,
  },
  address: {
    line_one: {
      required,
    },
    city: {
      required,
    },
  },
};

const $v = useVuelidate(rules, data);

const submit = () => {
  console.log(data);
};

const total = computed(() =>
  data.items.reduce(
    (acc, line) => (acc += (line.price || 0) * (line.vat + 1)),
    0,
  ),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(submit, ["prevent"])
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Test form", -1)),
      _createVNode(Field, {
        modelValue: data.firstName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.firstName) = $event)),
        label: "First name",
        placeholder: "Your first name",
        validation: _unref($v).firstName
      }, null, 8, ["modelValue", "validation"]),
      _createVNode(Field, {
        modelValue: data.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((data.lastName) = $event)),
        label: "Last name",
        placeholder: "Your last name",
        validation: _unref($v).lastName
      }, null, 8, ["modelValue", "validation"]),
      _createVNode(AddressFields, {
        modelValue: data.address,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((data.address) = $event)),
        validation: _unref($v).address,
        loading: false
      }, null, 8, ["modelValue", "validation"]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.id
          }, [
            _createVNode(TestItemFields, {
              modelValue: data.items[index],
              "onUpdate:modelValue": ($event: any) => ((data.items[index]) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]))
        }), 128))
      ]),
      _createVNode(FieldNumber, {
        modelValue: total.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((total).value = $event)),
        label: "Total",
        step: "1.00",
        readonly: ""
      }, null, 8, ["modelValue"]),
      _createElementVNode("pre", null, _toDisplayString(data), 1),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "buttons" }, [
        _createElementVNode("button", {
          class: "confirm",
          type: "submit"
        }, [
          _createElementVNode("span", { class: "text" }, "Test")
        ])
      ], -1))
    ], 32)
  ]))
}
}

})