<script lang="ts" setup>
import { computed, PropType, reactive } from 'vue';
import { useStore } from 'vuex';
import { useLoading } from '../common/loading.composable';
import { Actions } from '@/store';
import { getNewTaxData, TaxInfo } from '@/models/taxes';
import TaxDataFields from './TaxDataFields.vue';
import { useEvents, Event } from '@/core/services/events.composable';

const store = useStore();
const events = useEvents();

const props = defineProps({
  data: {
    type: Object as PropType<TaxInfo>,
    required: true
  },
});

const emit = defineEmits(['close']);

const { loading, setLoading } = useLoading();
let taxes = reactive<TaxInfo>({ ...props.data });

const action = computed<string>(() => taxes.id === 0 ? 'Create': 'Edit')

const submit = async () => {
  setLoading(true);
  if (taxes.id === 0) {
    await store.dispatch(Actions.CreateTaxes, taxes);
  } else {
    await store.dispatch(Actions.UpdateTaxes, taxes);
  }
  setLoading(false);
  events.emit(Event.TaxesUpdated, taxes);
  cancel();
};

const cancel = () => {
  emit('close');
};

const addData = () => {
  Object.assign(taxes, {data: [...taxes.data, getNewTaxData()]})
}

</script>
<template>
  <section class="drawer-content user-form">
    <form
      @submit.prevent="submit"
      :class="{ submitting: loading }"
      v-if="taxes"
    >
      <h2>{{action}} tax information for {{taxes.year}}</h2>
      <div class="cards">
        <TaxDataFields v-for="data in taxes.data" :key="data.id" :data="data"/>
      </div>
      <p v-if="!taxes.data.length">No information has been saved for {{data.year}}. <a href="#" @click.prevent="addData">Add (preliminary) tax information</a>.</p>
      <p v-if="taxes.data.length"><a href="#" @click.prevent="addData">Add more (preliminary) tax information</a>.</p>
      <div class="buttons">
        <button class="confirm" type="submit" :disabled="loading || !taxes.data.length">
          <span class="icon icon-pencil"></span>
          <span class="text">Edit</span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
      </div>
    </form>
  </section>
</template>
