import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box login-form" }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = ["disabled"]

import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import Loading from '@/components/common/Loading.vue';
import Message from '@/components/common/Message.vue';
import { Auth } from '../models/auth';
import { useLoading } from './common/loading.composable';
import { Actions } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  setup(__props) {

const store = useStore();
const router = useRouter();

const data = reactive({
  user_email: '',
  user_password: '',
});
const rules = {
  user_email: {
    required,
    email,
  },
  user_password: {
    required,
    minLength: minLength(7),
  },
};
const $v = useVuelidate(rules, data);
const { loading, setLoading } = useLoading();
const showError = ref<boolean>(false);

const submit = async () => {
  $v.value.$touch();

  if (!$v.value.$invalid) {
    showError.value = false;
    setLoading(true);

    const auth: Auth = await store.dispatch(Actions.Login, {
      user_email: data.user_email,
      user_password: data.user_password,
    });

    if (auth) {
      router.push('/');
    } else {
      showError.value = true;
    }
    setLoading(false);
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_focus = _resolveDirective("auto-focus")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(submit, ["prevent"]),
      class: _normalizeClass({ submitting: _unref(loading) })
    }, [
      (_unref(loading))
        ? (_openBlock(), _createBlock(Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Login", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Enter your email and password to login.", -1)),
      _withDirectives(_createVNode(Field, {
        label: "Email",
        autocomplete: "on",
        validation: _unref($v).user_email,
        modelValue: data.user_email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.user_email) = $event)),
        disabled: _unref(loading)
      }, null, 8, ["validation", "modelValue", "disabled"]), [
        [_directive_auto_focus, 'focus']
      ]),
      _createVNode(Field, {
        label: "Password",
        type: "password",
        validation: _unref($v).user_password,
        modelValue: data.user_password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((data.user_password) = $event)),
        disabled: _unref(loading),
        "message-styling": "bottom"
      }, null, 8, ["validation", "modelValue", "disabled"]),
      _createVNode(Message, {
        show: showError.value,
        type: "alert",
        message: "Login failed",
        styling: "inline"
      }, null, 8, ["show"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "confirm",
          type: "submit",
          disabled: _unref(loading) || _unref($v).$invalid
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "icon icon-key" }, null, -1),
          _createElementVNode("span", { class: "text" }, "Login", -1)
        ]), 8, _hoisted_3)
      ])
    ], 34)
  ]))
}
}

})