import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["name", "disabled", "readonly"]

import { FieldComposable, fieldProps } from './field.composable';
import Message from '@/components/common/Message.vue';
import { getMessage } from '@/core/validation';
import { PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldFile',
  props: {
  ...fieldProps,
  modelValue: {
    type: Object as PropType<File | null>,
    default: null,
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
  `key.ctrl.${String}`,
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { name, fieldValue } = FieldComposable<File | null>(
  props.modelValue,
  emit,
  props.label,
  props.prop,
);

const input = ref<HTMLInputElement | null>(null);

const update = () => {
  let file = null;
  if (input.value && input.value.files) {
    file = input.value.files[0];
  }
  fieldValue.value = file;

  emit('update:modelValue', file);

  props.validation.$touch();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", [
      { invalid: props.validation.$invalid && props.validation.$dirty },
      _ctx.styling,
    ]])
  }, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (
          props.validation &&
          props.validation.$invalid &&
          !props.validation.$dirty
        )
            ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref_key: "input",
      ref: input,
      name: _unref(name),
      type: "file",
      disabled: props.disabled,
      readonly: props.readonly,
      onChange: update,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (props.validation.$touch()))
    }, null, 40, _hoisted_3),
    _createVNode(Message, {
      type: "alert",
      styling: props.messageStyling,
      message: _unref(getMessage)(props.validation),
      show: props.validation.$invalid && props.validation.$dirty,
      onDismiss: _cache[1] || (_cache[1] = ($event: any) => (props.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})