import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page expense"
}
const _hoisted_2 = {
  class: "expense-info expense-details",
  "data-expense-id": "163",
  "data-name": "expense-info"
}
const _hoisted_3 = { class: "created-date" }
const _hoisted_4 = {
  class: "expense-info",
  "data-name": "references"
}
const _hoisted_5 = { class: "compound expense-reference-list" }
const _hoisted_6 = { class: "primary-label" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = ["href", "textContent"]
const _hoisted_9 = { class: "tags" }
const _hoisted_10 = { class: "tag" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "expense-totals" }
const _hoisted_16 = ["textContent"]
const _hoisted_17 = { class: "expense-total-amount" }
const _hoisted_18 = { class: "expense-currency" }
const _hoisted_19 = ["textContent"]
const _hoisted_20 = { class: "expense-total-amount" }
const _hoisted_21 = { class: "expense-currency" }
const _hoisted_22 = ["textContent"]
const _hoisted_23 = { class: "expense-total-amount" }
const _hoisted_24 = { class: "expense-currency" }

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from '@/core/i18n/i18n.composable';
import ExpenseLine from '@/components/pages/ExpenseLine.vue';
import Loading from '@/components/common/Loading.vue';
import { ExpenseInfo } from '@/services/balance.service';
import { useRoute } from 'vue-router';
import { formatCurrency, upper } from '@/filters/filters';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpensePage',
  setup(__props) {

const store = useStore();
const route = useRoute();
const expenseInfo = computed<ExpenseInfo | null>(
  () => store.state.bs.expenseInfo,
);

const { i18nData } = useI18n();
const { loading, setLoading } = useLoading();

const load = async () => {
  const number = route.params.number;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetExpense, { number, date });
  setLoading(false);
};

const fileType = computed(() => {
  const f = expenseInfo.value ? expenseInfo.value.reference.filename : '';
  return f.substring(f.lastIndexOf('.') + 1);
});

const filePath = computed(() => {
  return expenseInfo.value
    ? `/${expenseInfo.value.reference.path}${expenseInfo.value.reference.filename}`
    : '';
});

onMounted(() => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _directive_raise_ordinal = _resolveDirective("raise-ordinal")!

  return (expenseInfo.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_unref(loading))
            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("h2", null, _toDisplayString(_unref(i18nData).expense) + " - " + _toDisplayString(expenseInfo.value.expense.number), 1),
          _createElementVNode("p", _hoisted_3, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(expenseInfo.value.expense.date_created_f), 1)
            ])), [
              [_directive_raise_ordinal]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", null, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives((_openBlock(), _createElementBlock("span", null, [
                    _createTextVNode(_toDisplayString(expenseInfo.value.reference.date_received_f), 1)
                  ])), [
                    [_directive_raise_ordinal]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("a", {
                    href: filePath.value,
                    target: "_new",
                    textContent: _toDisplayString(expenseInfo.value.reference.summary)
                  }, null, 8, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", {
                      class: _normalizeClass(`icon icon-file-${fileType.value}`)
                    }, null, 2),
                    _createTextVNode(" " + _toDisplayString(_unref(upper)(fileType.value)), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  textContent: _toDisplayString(_unref(i18nData).description)
                }, null, 8, _hoisted_11),
                _createElementVNode("th", {
                  class: "expense-currency",
                  width: "17%",
                  textContent: _toDisplayString(_unref(i18nData).amount)
                }, null, 8, _hoisted_12),
                _createElementVNode("th", {
                  class: "expense-currency",
                  width: "17%",
                  textContent: _toDisplayString(_unref(i18nData).VAT)
                }, null, 8, _hoisted_13),
                _createElementVNode("th", {
                  class: "expense-currency",
                  width: "17%",
                  textContent: _toDisplayString(_unref(i18nData).total)
                }, null, 8, _hoisted_14)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expenseInfo.value.expense.lines, (line) => {
                return (_openBlock(), _createBlock(ExpenseLine, {
                  key: line.name,
                  line: line
                }, null, 8, ["line"]))
              }), 128))
            ]),
            _createElementVNode("tfoot", _hoisted_15, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  class: "total-description",
                  textContent: _toDisplayString(_unref(i18nData).subTotal)
                }, null, 8, _hoisted_16),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(expenseInfo.value.expense.sub_total, true)), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", {
                  class: "total-description",
                  textContent: _toDisplayString(_unref(i18nData).VATTotal)
                }, null, 8, _hoisted_19),
                _createElementVNode("td", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(expenseInfo.value.expense.vat_total)), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", { colspan: "2" }, null, -1)),
                _createElementVNode("td", {
                  class: "total-description",
                  textContent: _toDisplayString(_unref(i18nData).total)
                }, null, 8, _hoisted_22),
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(expenseInfo.value.expense.total)), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})