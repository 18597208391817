import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "results"
}

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import Field from '@/components/common/Field.vue';
import { Result } from '@/models/results.model';
import UserSearchResult from './UserSearchResult.vue';
import CompanySearchResult from './CompanySearchResult.vue';
import ProjectSearchResult from './ProjectSearchResult.vue';
import ExpenseSearchResult from './ExpenseSearchResult.vue';
import InvoiceSearchResult from './InvoiceSearchResult.vue';
import HoursSearchResult from './HoursSearchResult.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  setup(__props) {

const store = useStore();

const results = computed<Result<any>[]>(() => store.state.results);

const value = ref<string>('');
const active = ref<boolean>(false);
const searchInput = ref<HTMLInputElement | null>(null);

let to: number = -1;
const search = () => {
  window.clearTimeout(to);
  to = window.setTimeout(() => {
    if (value.value) {
      store.dispatch(Actions.Search, value.value);
    } else {
      store.commit(Mutations.ResetSearchResults);
    }
  }, 200);
};

const clear = () => {
  value.value = '';
  search();

  if (searchInput.value && active.value) {
    console.log(searchInput.value);
    // searchInput.value.focus();
  }
};

const getResultType = (result: Result<any>) => {
  switch (result.type) {
    case 'user':
      return UserSearchResult;
    case 'company':
      return CompanySearchResult;
    case 'project':
      return ProjectSearchResult;
    case 'expense':
      return ExpenseSearchResult;
    case 'invoice':
      return InvoiceSearchResult;
    case 'hours':
      return HoursSearchResult;
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_focus = _resolveDirective("auto-focus")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`search ${active.value ? 'search-active' : ''}`)
  }, [
    (active.value)
      ? _withDirectives((_openBlock(), _createBlock(Field, {
          key: 0,
          label: "Search",
          "hide-label": "",
          styling: "inline",
          modelValue: value.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
            search
          ]
        }, null, 8, ["modelValue"])), [
          [_directive_auto_focus, 'focus']
        ])
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: "icon icon-search",
      onClick: _cache[1] || (_cache[1] = ($event: any) => {
        active.value = !active.value;
        clear();
      })
    }),
    (results.value.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results.value, (result) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(getResultType(result)), {
              key: `${result.item.id}-${result.type}`,
              result: result
            }, null, 8, ["result"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, clear]
  ])
}
}

})