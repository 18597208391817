import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "balance-total-line columns" }
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "columns" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["disabled"]

import { reactive, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { Expense, ExpenseLine, expenseValidations } from '@/models/expense';
import {
  ExpenseReferenceInput,
  expenseReferenceValidations,
} from '@/models/reference';
import Field from '@/components/common/Field.vue';
import FieldFile from '@/components/common/FieldFile.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import ExpenseFormLine from './ExpenseFormLine.vue';
import Loading from '@/components/common/Loading.vue';
import { DateUtil } from '@/core/util/date.util';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseForm',
  setup(__props) {

const store = useStore();
const router = useRouter();
const { loading, setLoading } = useLoading();
const expense = reactive<Expense>(
  new Expense({
    id: -1,
    number: '',
    user_id: -1,
    date_created: DateUtil.format(new Date()),
    date_created_f: '',
    lines: [],
  }),
);

expense.addLine({
  vat: 0.21,
});

const reference = reactive<ExpenseReferenceInput>({
  summary: '',
  file: null,
});

const $v = useVuelidate(expenseValidations, { expense });
const $vr = useVuelidate(expenseReferenceValidations, {
  reference,
});

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(BalanceActions.AddExpense, getData());
    router.push('/balance');
    setLoading(false);
  }
};

const cancel = () => {
  router.push('/balance');
};

const removeLine = (line: ExpenseLine) => {
  expense.removeLine(line);
};

const addLine = () => {
  expense.addLine({
    vat: 0.21,
  });
};

const getData = () => {
  const data = new FormData();
  data.append(
    'info',
    JSON.stringify({
      expense: expense,
      reference: {
        summary: reference.summary,
      },
    }),
  );
  data.append('file', reference.file!);
  return data;
};

return (_ctx: any,_cache: any) => {
  return expense
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: _normalizeClass(["form add-expense-form", { submitting: _unref(loading) }]),
        onSubmit: _withModifiers(submit, ["prevent"])
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Add Expense", -1)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Field, {
            label: "Date",
            styling: "col block quarter",
            modelValue: expense.date_created,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((expense.date_created) = $event)),
            type: "date",
            validation: _unref($v).expense.date_created
          }, null, 8, ["modelValue", "validation"])
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, " Enter the VAT and the amount of the expense. Add more lines as needed. ", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expense.lines, (line) => {
          return (_openBlock(), _createBlock(ExpenseFormLine, {
            line: line,
            key: line.name,
            isOnly: expense.lines.length === 1,
            onRemove: ($event: any) => (removeLine(line)),
            onAdd: _cache[1] || (_cache[1] = ($event: any) => (addLine()))
          }, null, 8, ["line", "isOnly", "onRemove"]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col half" }, null, -1)),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col onetenth" }, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col onetenth field" }, [
            _createElementVNode("label", null, "Total")
          ], -1)),
          _createVNode(FieldNumber, {
            label: "Total",
            "hide-label": "",
            styling: "col oneeight block",
            readonly: "",
            modelValue: expense.total,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((expense.total) = $event)),
            step: "0.01"
          }, null, 8, ["modelValue"]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col onetenth" }, null, -1))
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("p", null, "You can optionally add a reference document below.", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(FieldFile, {
            label: "Reference",
            styling: "col threefifths block",
            modelValue: reference.file,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((reference.file) = $event)),
            validation: _unref($vr).reference.file,
            accept: ".pdf"
          }, null, 8, ["modelValue", "validation"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(FieldLong, {
            label: "Description",
            placeholder: "Description",
            styling: "col threefifths block",
            modelValue: reference.summary,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((reference.summary) = $event)),
            validation: _unref($vr).reference.summary
          }, null, 8, ["modelValue", "validation"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "confirm",
            type: "submit",
            disabled: _unref(loading)
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("span", { class: "icon icon-ok" }, null, -1),
            _createElementVNode("span", { class: "text" }, "Add", -1)
          ]), 8, _hoisted_6),
          _createElementVNode("button", {
            class: "cancel",
            type: "button",
            onClick: cancel
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "icon icon-remove" }, null, -1),
            _createElementVNode("span", { class: "text" }, "Clear", -1)
          ])),
          (_unref(loading))
            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ], 34))
    : _createCommentVNode("", true)
}
}

})