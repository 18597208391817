import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "option-line columns" }
const _hoisted_2 = { class: "col onetenth balance-line-options" }

import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { InvoiceOption, invoiceOptionsValidations } from '@/models/invoice';
import Field from '@/components/common/Field.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceFormOption',
  props: {
  option: {
    type: Object as PropType<InvoiceOption>,
    required: true,
  },
  isOnly: {
    type: Boolean,
    required: true,
  },
},
  emits: ['add', 'remove'],
  setup(__props, { emit: __emit }) {



const $v = useVuelidate(invoiceOptionsValidations, __props.option as {});

const emit = __emit;

const add = () => {
  emit('add', __props.option);
};
const remove = () => {
  emit('remove', __props.option);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Field, {
      label: "Label",
      modelValue: __props.option.label,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.option.label) = $event)),
      validation: _unref($v).label,
      styling: "col twofifths block"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(Field, {
      label: "Value",
      modelValue: __props.option.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.option.value) = $event)),
      validation: _unref($v).value,
      styling: "col twofifths block"
    }, null, 8, ["modelValue", "validation"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: add,
        class: "option add-balance-line-option icononly"
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("span", { class: "icon icon-plus" }, null, -1)
      ])),
      (!__props.isOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            onClick: remove,
            class: "option remove-balance-line-option icononly"
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "icon icon-minus" }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})