import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["textContent"]

import { computed, ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Message',
  props: {
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  type: {
    type: String,
    default: 'alert',
  },
  message: {
    type: String,
    required: true,
  },
  styling: {
    type: String,
    default: '',
  },
},
  emits: ['dismiss'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const done = ref<boolean>(false);
const showing = ref<boolean>(false);

const visible = computed((): boolean => {
  return !done.value && (props.show || showing.value);
});

const icon = computed((): string => {
  switch (props.type) {
    case 'alert':
      return 'fire';
    case 'warning':
      return 'warning';
    case 'confirm':
      return 'ok';
    case 'info':
      return 'bubble';
  }
  return 'bubble';
});

watch(
  () => props.show,
  (val: boolean) => {
    showing.value = !val;
    // quickly when hiding
    if (!val) {
      done.value = !val;
    }
    setTimeout(
      () => {
        done.value = !val;
        showing.value = val;
      },
      val ? 0 : 500,
    );
  },
);

const state = computed((): string => {
  return `${props.type} ${props.styling} ${done.value ? 'done' : ''}`;
});

const dismiss = (evt: MouseEvent) => {
  done.value = true;
  emit('dismiss');
  evt.stopPropagation();
  evt.preventDefault();
};

return (_ctx: any,_cache: any) => {
  return (visible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["message", state.value])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(`icon icon-${icon.value}`)
        }, null, 2),
        _createElementVNode("span", {
          class: "text",
          textContent: _toDisplayString(props.message)
        }, null, 8, _hoisted_1),
        _createElementVNode("button", {
          type: "button",
          class: "dismiss icononly",
          onClick: dismiss,
          tabindex: "-1"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "icon icon-remove" }, null, -1)
        ]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})