import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "times" }

import { computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { TimeUtil } from '@/core/util/time.util';
import Field from '@/components/common/Field.vue';

interface HoursHelpTime {
  start: string;
  end: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HoursHelp',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const rules = {
  start: {
    required,
  },
  end: {
    required,
  },
};

const times = reactive<HoursHelpTime>({
  start: '',
  end: '',
});

const $v = useVuelidate(rules, times);

const value = computed(() => {
  return TimeUtil.diff(times.start, times.end);
});

const emit = __emit;

const submit = (evt: Event) => {
  evt.preventDefault();
  emit('close', 'help', value.value);
};

const close = () => {
  emit('close', 'help');
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_focus = _resolveDirective("auto-focus")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createVNode(Field, {
      label: "Start",
      onEnter: submit,
      onEscape: close,
      placeholder: "00:00",
      validation: _unref($v).start,
      modelValue: times.start,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((times.start) = $event))
    }, null, 8, ["validation", "modelValue"]), [
      [_directive_auto_focus, 'focus']
    ]),
    _createVNode(Field, {
      label: "End",
      onEnter: submit,
      onEscape: close,
      placeholder: "00:00",
      validation: _unref($v).end,
      modelValue: times.end,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((times.end) = $event))
    }, null, 8, ["validation", "modelValue"])
  ]))
}
}

})