<script lang="ts" setup>
import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { InvoiceOption, invoiceOptionsValidations } from '@/models/invoice';
import Field from '@/components/common/Field.vue';

const { option } = defineProps({
  option: {
    type: Object as PropType<InvoiceOption>,
    required: true,
  },
  isOnly: {
    type: Boolean,
    required: true,
  },
});

const $v = useVuelidate(invoiceOptionsValidations, option as {});

const emit = defineEmits(['add', 'remove']);

const add = () => {
  emit('add', option);
};
const remove = () => {
  emit('remove', option);
};
</script>
<template>
  <div class="option-line columns">
    <Field
      label="Label"
      v-model="option.label"
      :validation="$v.label"
      styling="col twofifths block"
    ></Field>
    <Field
      label="Value"
      v-model="option.value"
      :validation="$v.value"
      styling="col twofifths block"
    ></Field>
    <div class="col onetenth balance-line-options">
      <button
        type="button"
        @click="add"
        class="option add-balance-line-option icononly"
      >
        <span class="icon icon-plus"></span>
      </button>
      <button
        type="button"
        v-if="!isOnly"
        @click="remove"
        class="option remove-balance-line-option icononly"
      >
        <span class="icon icon-minus"></span>
      </button>
    </div>
  </div>
</template>
<style scoped>
.option-line .field {
  width: 150px;
}
</style>
