import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, onMounted, PropType } from 'vue';
import { useStore } from 'vuex';
import { BalanceActions } from '@/stores/balance.store';
import { Actions } from '@/store';
import { Invoice } from '@/models/invoice';
import { Project } from '@/models/project';
import { Company } from '@/models/company';
import InvoiceList from '../balance/InvoiceList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectInvoices',
  props: {
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();
const props = __props;

const invoices = computed<Invoice[]>(() => store.state.bs.invoices);
const companies = computed<Company[]>(() => store.state.companies);

onMounted(async () => {
  await store.dispatch(Actions.GetCompanies);
  await store.dispatch(BalanceActions.GetProjectInvoices, {
    projectId: props.project.id,
  });
});

return (_ctx: any,_cache: any) => {
  return (invoices.value.length)
    ? (_openBlock(), _createBlock(InvoiceList, {
        key: 0,
        invoices: invoices.value,
        companies: companies.value
      }, null, 8, ["invoices", "companies"]))
    : _createCommentVNode("", true)
}
}

})