import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  width: "25%",
  class: "balance-detail invoice-currency"
}
const _hoisted_5 = { class: "currency" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "balance-detail invoice-currency" }
const _hoisted_8 = { class: "currency" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "balance-detail invoice-currency" }
const _hoisted_12 = { class: "currency" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "balance-detail invoice-currency" }
const _hoisted_16 = { class: "currency" }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = ["textContent"]
const _hoisted_19 = { class: "balance-detail invoice-currency" }
const _hoisted_20 = { class: "currency" }
const _hoisted_21 = { key: 3 }
const _hoisted_22 = ["textContent"]
const _hoisted_23 = { class: "balance-detail invoice-currency" }
const _hoisted_24 = { class: "currency" }
const _hoisted_25 = ["textContent"]
const _hoisted_26 = { class: "balance-detail invoice-currency" }
const _hoisted_27 = { class: "currency" }
const _hoisted_28 = { key: 4 }
const _hoisted_29 = ["textContent"]
const _hoisted_30 = { class: "balance-detail invoice-currency" }
const _hoisted_31 = { class: "currency" }
const _hoisted_32 = {
  class: "invoice-currency",
  colspan: "3"
}
const _hoisted_33 = { class: "balance-detail invoice-currency" }
const _hoisted_34 = { class: "currency" }
const _hoisted_35 = ["textContent"]

import { I18NData } from '@/core/i18n';
import { TaxData, TaxInfo } from '@/models/taxes';
import { computed, PropType } from 'vue';
import { formatCurrency } from '@/filters/filters';
import { useStore } from 'vuex';
import { Balance } from '@/models/balance';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxInformation',
  props: {
  i18n: {
    type: Object as PropType<I18NData>,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
},
  setup(__props) {



const store = useStore();

const balance = computed<Balance>(() => store.state.bs.balance);

const taxInfo = computed<TaxInfo>(() => balance.value.taxes)

const data = computed<TaxData>(() => {
  const definitive = taxInfo.value.data.find(d => !d.preliminary);

  return definitive || taxInfo.value.data[taxInfo.value.data.length -1]
})

const total = computed<number>(() => {
  return data.value.box_1_taxes + data.value.box_2_taxes + data.value.box_3_taxes + data.value.national_insurance_premium - data.value.tax_credits
})

const percentage = computed<string>(() => {
  return `${Math.round(total.value / balance.value.overview.balance.without_vat * 100)}%`
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.type === 'year')
      ? (_openBlock(), _createElementBlock("h3", {
          key: 0,
          textContent: _toDisplayString(__props.i18n.taxes)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (__props.type === 'year' && taxInfo.value.data && taxInfo.value.data.length)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("td", { width: "25%" }, null, -1)),
              _createElementVNode("th", {
                width: "25%",
                class: "invoice-currency",
                textContent: _toDisplayString(__props.i18n.taxes_box_1_income)
              }, null, 8, _hoisted_3),
              _createElementVNode("td", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(formatCurrency)(data.value.box_1_income)), 1)
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("td", { width: "25%" }, null, -1))
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                class: "invoice-currency",
                colspan: "3",
                textContent: _toDisplayString(__props.i18n.taxes_box_1_taxes)
              }, null, 8, _hoisted_6),
              _createElementVNode("td", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(formatCurrency)(data.value.box_1_taxes)), 1)
              ])
            ]),
            (data.value.box_2_taxes)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
                  _createElementVNode("th", {
                    class: "invoice-currency",
                    textContent: _toDisplayString(__props.i18n.taxes_box_2_income)
                  }, null, 8, _hoisted_10),
                  _createElementVNode("td", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(formatCurrency)(data.value.box_2_income)), 1)
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (data.value.box_2_taxes)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                  _createElementVNode("th", {
                    class: "invoice-currency",
                    colspan: "3",
                    textContent: _toDisplayString(__props.i18n.taxes_box_2_taxes)
                  }, null, 8, _hoisted_14),
                  _createElementVNode("td", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(formatCurrency)(data.value.box_2_taxes)), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (data.value.box_3_taxes)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [
                  _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1)),
                  _createElementVNode("th", {
                    class: "invoice-currency",
                    textContent: _toDisplayString(__props.i18n.taxes_box_3_income)
                  }, null, 8, _hoisted_18),
                  _createElementVNode("td", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(formatCurrency)(data.value.box_3_income)), 1)
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (data.value.box_3_taxes)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                  _createElementVNode("th", {
                    class: "invoice-currency",
                    colspan: "3",
                    textContent: _toDisplayString(__props.i18n.taxes_box_3_taxes)
                  }, null, 8, _hoisted_22),
                  _createElementVNode("td", _hoisted_23, [
                    _createElementVNode("span", _hoisted_24, _toDisplayString(_unref(formatCurrency)(data.value.box_3_taxes)), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                class: "invoice-currency",
                colspan: "3",
                textContent: _toDisplayString(__props.i18n.taxes_national_insurance_premium)
              }, null, 8, _hoisted_25),
              _createElementVNode("td", _hoisted_26, [
                _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(formatCurrency)(data.value.national_insurance_premium)), 1)
              ])
            ]),
            (data.value.tax_credits)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_28, [
                  _createElementVNode("th", {
                    class: "invoice-currency",
                    colspan: "3",
                    textContent: _toDisplayString(__props.i18n.taxes_taxcredits)
                  }, null, 8, _hoisted_29),
                  _createElementVNode("td", _hoisted_30, [
                    _createElementVNode("span", _hoisted_31, "-" + _toDisplayString(_unref(formatCurrency)(data.value.tax_credits)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("tfoot", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_32, _toDisplayString(__props.i18n.taxes_total) + " (" + _toDisplayString(percentage.value) + ")", 1),
              _createElementVNode("td", _hoisted_33, [
                _createElementVNode("span", _hoisted_34, _toDisplayString(_unref(formatCurrency)(total.value)), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.type === 'year' && taxInfo.value.data && !taxInfo.value.data.length)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: "",
          textContent: _toDisplayString(__props.i18n.taxesNoInfo)
        }, null, 8, _hoisted_35))
      : _createCommentVNode("", true)
  ], 64))
}
}

})