import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "details" }

import { onMounted, PropType, ref } from 'vue';
import { TimesheetDay } from '@/models/timesheet-day';
import useVuelidate from '@vuelidate/core';
import FieldLong from '@/components/common/FieldLong.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HoursDetails',
  props: {
  data: {
    type: Object as PropType<TimesheetDay>,
    required: true,
  },
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const description = ref<string>('');
const $v = useVuelidate({ description: {} }, { description });

onMounted(() => {
  description.value = __props.data.description || '';
});

const close = (evt: Event) => {
  evt.preventDefault();
  emit('close', 'details', description.value);
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_focus = _resolveDirective("auto-focus")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createVNode(FieldLong, {
      label: "Description",
      onEnter: close,
      onEscape: close,
      validation: _unref($v).description,
      modelValue: description.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((description).value = $event))
    }, null, 8, ["validation", "modelValue"]), [
      [_directive_auto_focus, 'select']
    ])
  ]))
}
}

})