import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "times" }

import { computed, reactive, ref } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import { TimeUtil } from '@/core/util/time.util';

interface Hours {
  time: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HoursCalculator',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

let hours = reactive<Array<Hours>>([{ time: '' }]);

const rules = ref<ValidationArgs>({
  hours: {
    time: {
      required,
    },
  },
});

const value = computed((): string => {
  return TimeUtil.minutesToTime(
    hours.reduce((acc: number, v: { time: string }) => {
      return acc + (TimeUtil.timeToMinutes(v.time) || 0);
    }, 0),
  );
});

const $v = useVuelidate<ValidationArgs, { hours: Hours[] }>(rules, { hours });

const emit = __emit;

const addHours = () => {
  hours = [...hours, { time: '' }];
};

const removeHours = (index: number) => {
  hours.splice(index, 1);
};

const submit = (evt: Event) => {
  if (!$v.value.$invalid) {
    evt.preventDefault();
    emit('close', 'calculator', value.value);
  }
};

const close = () => {
  emit('close', 'calculator');
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_focus = _resolveDirective("auto-focus")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hours), (t, index) => {
      return _withDirectives((_openBlock(), _createBlock(Field, {
        key: index,
        label: `Hours ${index}`,
        onKey: [
          _withModifiers(addHours, ["ctrl","exact"]),
          _withModifiers(($event: any) => (removeHours(index)), ["ctrl","exact"])
        ],
        onEnter: submit,
        onEscape: close,
        placeholder: "00:00",
        modelValue: t.time,
        "onUpdate:modelValue": ($event: any) => ((t.time) = $event)
      }, null, 8, ["label", "onKey", "modelValue", "onUpdate:modelValue"])), [
        [_directive_auto_focus, 'focus']
      ])
    }), 128))
  ]))
}
}

})