import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "currency" }

import { PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Invoice } from '@/models/invoice';
import { formatCurrency } from '@/filters/filters';
import { Actions } from '@/store';
import { BalanceActions } from '@/stores/balance.store';
import { companyInfo } from '@/core/util/info';
import Field from '../common/Field.vue';
import { format } from 'date-fns';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceStatusModal',
  props: {
  data: {
    type: Object as PropType<{ invoice: Invoice }>,
    required: true,
  },
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const store = useStore();
const route = useRoute();



const date = ref<string>(format(new Date(), 'yyyy-MM-dd'));

const confirm = async () => {
  await store.dispatch(BalanceActions.UpdateInvoiceStatus, {
    number: __props.data.invoice.number,
    date: date.value,
  });
  store.dispatch(Actions.ShowNotification, {
    message: 'The invoice status has been updated.',
    type: 'info',
  });
  const type = route.params.type;
  store.dispatch(BalanceActions.GetBalance, { type, date: route.params.date });
  cancel();
};

const emit = __emit;
const cancel = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", null, "Update Invoice " + _toDisplayString(__props.data.invoice.number), 1),
    _createElementVNode("p", null, [
      _cache[1] || (_cache[1] = _createTextVNode(" Mark the invoice as ")),
      _cache[2] || (_cache[2] = _createElementVNode("i", null, "paid", -1)),
      _cache[3] || (_cache[3] = _createTextVNode(" when ")),
      _createElementVNode("strong", _hoisted_2, _toDisplayString(_unref(formatCurrency)(__props.data.invoice.total)), 1),
      _createTextVNode(" has been transferred to the " + _toDisplayString(_unref(companyInfo).name) + " account. ", 1)
    ]),
    _createVNode(Field, {
      modelValue: date.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
      type: "date",
      label: "Date"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", { class: "buttons" }, [
      _createElementVNode("button", {
        type: "button",
        class: "danger",
        onClick: confirm
      }, "Paid"),
      _createElementVNode("button", {
        type: "button",
        class: "option",
        onClick: cancel
      }, "Cancel")
    ])
  ]))
}
}

})