import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "primary-label" }
const _hoisted_2 = { class: "tags" }
const _hoisted_3 = { class: "tag" }
const _hoisted_4 = { class: "currency" }

import { computed, PropType } from 'vue';
import { Expense } from '@/models/expense';
import { formatCurrency } from '@/filters/filters';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseItem',
  props: {
  expense: {
    type: Object as PropType<Expense>,
    required: true,
  },
},
  setup(__props) {



const expenseUrl = computed(() => `/balance/expense/${__props.expense.number}`);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_raise_ordinal = _resolveDirective("raise-ordinal")!

  return (_openBlock(), _createElementBlock("li", null, [
    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
      _createTextVNode(_toDisplayString(__props.expense.date_created_f), 1)
    ])), [
      [_directive_raise_ordinal]
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_RouterLink, { to: expenseUrl.value }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.expense.number), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" - " + _toDisplayString(__props.expense.description), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(formatCurrency)(__props.expense.total)), 1)
      ])
    ])
  ]))
}
}

})