<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { TimesheetActions } from '@/stores/timesheet.store';
import Loading from '@/components/common/Loading.vue';
import ProjectOverview from '@/components/timesheet/overview/ProjectOverview.vue';
import {
  TimesheetOverview,
  ProjectOverview as ProjectOverviewData,
} from '../../../models/timesheet';
import { useLoading } from '@/components/common/loading.composable';

const store = useStore();
const overview = computed<TimesheetOverview>(() => store.state.ts.overview);

const previous = computed(() => {
  return `/timesheet/overview/${overview.value.info.previous}`;
});

const next = computed(() => {
  return `/timesheet/overview/${overview.value.info.next}`;
});

const day_by_day = computed(() => {
  return `/timesheet/overview/day-by-day/${overview.value.info.current}`;
});

const monthly = computed(() => {
  return overview.value.month_data
    .slice(0)
    .sort((a: ProjectOverviewData, b: ProjectOverviewData) =>
      a.name > b.name ? 1 : -1,
    );
});

const yearly = computed(() => {
  return overview.value.year_data
    .slice(0)
    .sort((a: ProjectOverviewData, b: ProjectOverviewData) =>
      a.name > b.name ? 1 : -1,
    );
});

const { loading, setLoading } = useLoading();
const route = useRoute();

const load = async () => {
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheetOverview, { date });
  setLoading(false);
};

onMounted(async () => {
  await load();
});

watch(route, async () => await load());
</script>
<template>
  <article class="screen timesheet-overview" v-if="overview && overview.info">
    <h2>Monthly Overview</h2>
    <Loading v-if="!overview"></Loading>
    <section v-else>
      <ul class="options">
        <li>
          <RouterLink :to="previous">
            <span class="icon icon-caret-left"></span>
            <span v-text="overview.info.previous_name"></span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="next">
            <span class="icon icon-caret-right"></span>
            <span v-text="overview.info.next_name"></span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="day_by_day">
            <span class="icon icon-archive"></span>
            <span>See day by day</span>
          </RouterLink>
        </li>
      </ul>
      <div class="columns">
        <div class="col half">
          <h3 class="small-loader-header">
            <span v-text="overview.info.month_name"></span>
            <Loading v-if="loading"></Loading>
          </h3>
          <p v-if="overview.month_data.length === 0">
            There are no hours for this month.
          </p>
          <table v-else class="project-totals">
            <thead>
              <tr>
                <th>Project Name</th>
                <th class="total">Total</th>
              </tr>
            </thead>
            <tbody>
              <ProjectOverview
                v-for="project in monthly"
                :key="project.id"
                :project="project"
              ></ProjectOverview>

              <tr class="monthtotal">
                <th>
                  <strong>Total</strong>
                </th>
                <th class="hours" v-text="overview.info.month_total"></th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col half">
          <h3>{{ overview.info.year }} Totals</h3>
          <p v-if="overview.year_data.length === 0">
            There are no hours for this year.
          </p>
          <table v-else class="project-totals">
            <thead>
              <tr>
                <th>Project Name</th>
                <th class="total">Total</th>
              </tr>
            </thead>
            <tbody>
              <ProjectOverview
                v-for="project in yearly"
                :key="project.id"
                :project="project"
              ></ProjectOverview>

              <tr class="monthtotal">
                <th>
                  <strong>Direct total</strong>
                </th>
                <th class="hours" v-text="overview.info.year_direct_total"></th>
              </tr>
              <tr>
                <th>
                  <strong>Total</strong>
                </th>
                <th class="hours" v-text="overview.info.year_total"></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </article>
</template>
