<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TimesheetActions } from '@/stores/timesheet.store';
import { DayHours } from '../../../models/timesheet';
import { useLoading } from '@/components/common/loading.composable';
import Loading from '@/components/common/Loading.vue';

interface DayByDay {
  [index: string]: DayByDayHours;
}

interface DayByDayHours {
  date: string;
  format_date: string;
  hours: DayHours[];
}

const store = useStore();
const route = useRoute();
const { loading, setLoading } = useLoading();

const overview_by_day = computed(() => store.state.ts.overview_by_day);

const previous = computed(
  () => `/timesheet/overview/day-by-day/${overview_by_day.value.info.previous}`,
);

const next = computed(
  () => `/timesheet/overview/day-by-day/${overview_by_day.value.info.next}`,
);

const regular = computed(
  () => `/timesheet/overview/${overview_by_day.value.info.current}`,
);

const totalHours = computed((): string => {
  return overview_by_day.value.hours
    .reduce((s: number, h: DayHours) => s + parseFloat(h.hours), 0)
    .toFixed(2);
});

const day_by_day = computed((): DayByDayHours[] => {
  const days = overview_by_day.value.hours.reduce(
    (acc: DayByDay, h: DayHours) => {
      if (!acc[h.date]) {
        acc[h.date] = {
          date: h.date,
          format_date: h.format_date,
          hours: [h],
        };
      } else {
        acc[h.date].hours.push(h);
      }
      return acc;
    },
    {},
  );
  return Object.values(days);
});

const load = async () => {
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetDayByDayOverview, { date });
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);
</script>

<template>
  <article class="timesheet-overview" v-if="overview_by_day">
    <h2 class="small-loader-header">
      Day by day overview {{ overview_by_day.info.month_name }}
      <Loading v-if="loading"></Loading>
    </h2>
    <loading v-if="!overview_by_day"></loading>
    <section v-else>
      <ul class="options">
        <li>
          <RouterLink :to="previous">
            <span class="icon icon-caret-left"></span>
            <span v-text="overview_by_day.info.previous_name"></span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="next">
            <span class="icon icon-caret-right"></span>
            <span v-text="overview_by_day.info.next_name"></span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="regular">
            <span class="icon icon-archive"></span>
            <span>See month and totals</span>
          </RouterLink>
        </li>
      </ul>
      <table class="month-hours">
        <thead>
          <tr>
            <th width="120px">Date</th>
            <th width="200px">Project</th>
            <th>Description</th>
            <th width="80px" class="align-right">Hours</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!day_by_day.length">
            <td colspan="4">There are no hours yet</td></template
          >
          <template v-for="day in day_by_day" :key="`${day.date}-day`">
            <tr>
              <td :rowspan="day.hours.length">
                <span v-raise-ordinal v-text="day.format_date"></span>
              </td>
              <td v-text="day.hours[0].project_name"></td>
              <td v-text="day.hours[0].description"></td>
              <td v-text="day.hours[0].hours" class="align-right"></td>
            </tr>
            <tr
              v-for="hour in day.hours.slice(1)"
              :key="`${hour.date}-${hour.project_id}`"
            >
              <td v-text="hour.project_name"></td>
              <td v-text="hour.description"></td>
              <td class="align-right" v-text="hour.hours"></td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td class="empty" colspan="3"></td>
            <td class="align-right" v-text="totalHours"></td>
          </tr>
        </tfoot>
      </table>
    </section>
  </article>
</template>
