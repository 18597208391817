import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timesheet" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "options" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "weekdays" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "timesheet-loader small-loader" }
const _hoisted_11 = ["textContent"]

import { computed, onBeforeUnmount, onMounted, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { INVOICE_TYPE, Project } from '@/models/project';
import { Timesheet, Days, isWeekTotal } from '@/models/timesheet';
import { TimeUtil } from '@/core/util/time.util';
import { TimesheetDay } from '@/models/timesheet-day';
import HoursField from './HoursField.vue';
import WeekTotals from './WeekTotals.vue';
import TimesheetProjectInfo from './TimesheetProjectInfo.vue';
import TimesheetInvoice from './TimesheetInvoice.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import Loading from '@/components/common/Loading.vue';
import ProjectForm from '@/components/project/ProjectForm.vue';
import { useLoading } from '../common/loading.composable';
import { Actions } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import { TimesheetActions, TimesheetMutations } from '@/stores/timesheet.store';
import { useEvents, Event } from '@/core/services/events.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'Timesheet',
  setup(__props) {

const store = useStore();
const route = useRoute();
const events = useEvents();
const { loading, setLoading } = useLoading();

const project = computed<Project | null>(() => store.state.project);
const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const timesheet_days = computed<Days[]>(() => store.state.ts.timesheet_days);
const changedDays = computed(() => store.state.ts.changedDays);
const removedDays = computed(() => store.state.ts.removedDays);
const timesheet_hours = computed<TimesheetDay[]>(
  () => store.getters['ts/timesheet_hours'],
);

const project_id = computed(() => {
  return parseInt(route.params.id as string, 10);
});

const previous = computed(() => {
  return `/timesheet/project/${timesheet.value.project.id}/${timesheet.value.info.previous}`;
});

const next = computed(() => {
  return `/timesheet/project/${timesheet.value.project.id}/${timesheet.value.info.next}`;
});

const isInvoiceable = computed(() => {
  return timesheet.value.project.type === INVOICE_TYPE;
});

const lastDayClass = computed(() => {
  const nr =
    7 -
    timesheet.value.days.filter((d: Days) => (d as TimesheetDay).next).length;
  return `last-day-${nr}`;
});

const month_total = computed((): string => {
  return TimeUtil.minutesToTime(
    timesheet_hours.value
      .filter(
        (day: Days) =>
          day.date.indexOf(timesheet.value.info.month) !== -1 &&
          !isWeekTotal(day),
      )
      .reduce((minutes: number, day: Days) => {
        minutes += (day as TimesheetDay).minutes || 0;
        return minutes;
      }, 0),
  );
});

const load = async () => {
  const id = route.params.id;
  const date = route.params.date;
  if (id) {
    setLoading(true);
    await store.dispatch(TimesheetActions.GetTimesheet, { id, date });
    await store.dispatch(Actions.GetProject, id);
    setLoading(false);
  }
};

watch(route, load);

const save = async () => {
  if (
    !loading.value &&
    (changedDays.value.length || removedDays.value.length)
  ) {
    setLoading(true);
    await store.dispatch(TimesheetActions.UpdateTimesheet);
    await store.dispatch(Actions.ShowNotification, {
      message: 'Hours saved',
      type: 'confirm',
    });
    setLoading(false);
    events.emit(Event.HoursStored);
  }
};

onMounted(() => {
  load();
  events.on(Event.HoursChanged, stage);
  events.on(Event.HoursResetChanged, resetStaged);
  events.on(Event.HoursStore, save);
});

onBeforeUnmount(() => {
  store.commit(TimesheetMutations.ResetTimesheetDays);
  events.off(Event.HoursChanged, stage);
  events.off(Event.HoursResetChanged, resetStaged);
  events.off(Event.HoursStore, save);
});

const stage = (day: TimesheetDay) => {
  store.commit(TimesheetMutations.ResetStagedDay, day);
  if (day.minutes !== 0) {
    // only save hours with time
    store.commit(TimesheetMutations.UpdateChangedDays, day);
  } else if (day.id) {
    // only remove previously stored hours
    store.commit(TimesheetMutations.UpdateRemovedDays, day);
  }
};

const resetStaged = (day: TimesheetDay) => {
  return;
};

const getFieldType = (day: Days) => {
  return day.hasOwnProperty('isWeek') ? WeekTotals : HoursField;
};

const editProject = async (id: number) => {
  await store.dispatch(Actions.GetProject, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!timesheet.value || !timesheet.value.project)
      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
      : (_openBlock(), _createElementBlock("section", _hoisted_2, [
          (project.value)
            ? (_openBlock(), _createBlock(TimesheetProjectInfo, {
                key: 0,
                project: project.value,
                "onEdit:project": editProject
              }, null, 8, ["project"]))
            : _createCommentVNode("", true),
          _createElementVNode("h3", null, [
            _createElementVNode("span", {
              textContent: _toDisplayString(timesheet.value.info.title)
            }, null, 8, _hoisted_3),
            _createVNode(Tooltip, {
              text: "Print timesheet",
              inline: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouterLink, {
                  to: `/timesheet/project/${timesheet.value.project.id}/${timesheet.value.info.month}-01/print`,
                  custom: ""
                }, {
                  default: _withCtx(({ navigate }) => [
                    _createElementVNode("button", {
                      type: "button",
                      class: "option icononly",
                      onClick: navigate
                    }, _cache[0] || (_cache[0] = [
                      _createElementVNode("span", { class: "icon icon-print" }, null, -1)
                    ]), 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", null, [
              _createVNode(_component_RouterLink, { to: previous.value }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon icon-caret-left" }, null, -1)),
                  _createElementVNode("span", {
                    textContent: _toDisplayString(timesheet.value.info.previous_name)
                  }, null, 8, _hoisted_6)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_RouterLink, { to: next.value }, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "icon icon-caret-right" }, null, -1)),
                  _createElementVNode("span", {
                    textContent: _toDisplayString(timesheet.value.info.next_name)
                  }, null, 8, _hoisted_7)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]),
          _createElementVNode("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timesheet.value.info.weekdays, (day) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "header",
                textContent: _toDisplayString(day),
                key: day
              }, null, 8, _hoisted_9))
            }), 128)),
            _createElementVNode("li", _hoisted_10, [
              (_unref(loading))
                ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timesheet_days.value, (day) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(getFieldType(day)), {
                data: day,
                key: day.date,
                project_id: project_id.value
              }, null, 8, ["data", "project_id"]))
            }), 128)),
            (timesheet_days.value.length)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass(["monthtotals", lastDayClass.value])
                }, [
                  _createVNode(Tooltip, {
                    text: `Total hours for this project in ${timesheet.value.info.title}`
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "total month-project-total",
                        textContent: _toDisplayString(month_total.value)
                      }, null, 8, _hoisted_11)
                    ]),
                    _: 1
                  }, 8, ["text"])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          (isInvoiceable.value)
            ? (_openBlock(), _createBlock(TimesheetInvoice, {
                key: 1,
                month: timesheet.value.info.month
              }, null, 8, ["month"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})