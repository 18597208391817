<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { HolidayName, Holidays } from '@/models/holidays';
import { useLoading } from '@/components/common/loading.composable';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import { Actions } from '@/store';

interface HolidayOption {
  name: HolidayName;
  label: string;
}

const store = useStore();
const route = useRoute();
const holidays = computed<Holidays>(() => store.state.holidays);
const { loading, setLoading } = useLoading();

const year: number = new Date().getFullYear();
const fields: HolidayOption[] = [
  { name: HolidayName.newyearsday, label: 'Nieuwjaarsdag' },
  { name: HolidayName.goodfriday, label: 'Goede vrijdag' },
  { name: HolidayName.firsteasterday, label: 'Eerste Paasdag' },
  { name: HolidayName.secondeasterday, label: 'Tweede Paasdag' },
  { name: HolidayName.kingsday, label: 'Koningsdag' },
  { name: HolidayName.liberationday, label: 'Bevrijdingsdag' },
  { name: HolidayName.ascensionday, label: 'Hemelvaart' },
  { name: HolidayName.firstpentacostday, label: 'Eerste Pinksterdag' },
  { name: HolidayName.secondpentacostday, label: 'Tweede Pinksterdag' },
  { name: HolidayName.firstchristmasday, label: 'Eerste Kerstdag' },
  { name: HolidayName.secondchristmasday, label: 'Tweede Kerstdag' },
];

const title = computed(() =>
  holidays.value ? `Holidays ${holidays.value.year}` : 'Holidays',
);

const previous_year = computed(() => holidays.value.year - 1);
const previous = computed(() => `/system/holidays/${previous_year.value}`);

const next_year = computed(() => holidays.value.year + 1);
const next = computed(() => `/system/holidays/${next_year.value}`);

const load = async () => {
  const y = route.params.year || year;
  setLoading(true);
  await store.dispatch(Actions.GetHolidays, y);
  holidays.value.update(fields);
  setLoading(false);
};

const submit = async () => {
  setLoading(true);
  await store.dispatch(Actions.StoreHolidays, holidays.value.data());
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);
</script>
<template>
  <section class="admin-page">
    <h2 class="small-loader-header">{{ title }} <Loading v-if="loading" /></h2>
    <ul class="options" v-if="holidays">
      <li>
        <RouterLink :to="previous">
          <span class="icon icon-caret-left"></span>
          <span v-text="previous_year"></span>
        </RouterLink>
      </li>
      <li>
        <RouterLink :to="next">
          <span class="icon icon-caret-right"></span>
          <span v-text="next_year"></span>
        </RouterLink>
      </li>
    </ul>
    <p>Add, edit and remove holidays to be visible on the timesheet.</p>
    <form v-if="holidays" @submit.prevent="submit">
      <div class v-for="item in fields" :key="item.name">
        <Field
          :label="item.label"
          styling="halved"
          v-model="holidays[item.name].date"
          type="date"
        />
      </div>
      <div class="buttons">
        <button class="confirm" type="submit" :disabled="loading">
          <span class="icon icon-ok"></span>
          <span class="text">Save</span>
        </button>
        <Loading v-if="loading"></Loading>
      </div>
    </form>
  </section>
</template>
