import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "day-overview tooltip-container"
}
const _hoisted_2 = { class: "tooltip tooltip-top" }
const _hoisted_3 = { class: "tooltip-text" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "day-total" }
const _hoisted_7 = ["textContent"]

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { TimesheetActions } from '@/stores/timesheet.store';
import { DayOverview } from '@/models/timesheet';


export default /*@__PURE__*/_defineComponent({
  __name: 'DayOverview',
  props: {
  data: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const store = useStore();
const day_overview = computed<DayOverview>(() => store.state.ts.day_overview);



const load = () => {
  store.dispatch(TimesheetActions.GetDayOverview, __props.data.date);
};

onMounted(() => {
  load();
});

return (_ctx: any,_cache: any) => {
  return (day_overview.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day_overview.value.hours, (hours) => {
              return (_openBlock(), _createElementBlock("li", {
                key: hours.project_id
              }, [
                _createElementVNode("label", {
                  textContent: _toDisplayString(hours.project_name)
                }, null, 8, _hoisted_4),
                _createElementVNode("span", {
                  textContent: _toDisplayString(hours.hours)
                }, null, 8, _hoisted_5)
              ]))
            }), 128)),
            _createElementVNode("li", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("label", null, "Total", -1)),
              _createElementVNode("span", {
                textContent: _toDisplayString(day_overview.value.total)
              }, null, 8, _hoisted_7)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})