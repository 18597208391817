<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { TimesheetActions } from '@/stores/timesheet.store';
import { DayOverview } from '@/models/timesheet';

const store = useStore();
const day_overview = computed<DayOverview>(() => store.state.ts.day_overview);

const { data } = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const load = () => {
  store.dispatch(TimesheetActions.GetDayOverview, data.date);
};

onMounted(() => {
  load();
});
</script>
<template>
  <section v-if="day_overview" class="day-overview tooltip-container">
    <div class="tooltip tooltip-top">
      <ul class="tooltip-text">
        <li v-for="hours in day_overview.hours" :key="hours.project_id">
          <label v-text="hours.project_name"></label>
          <span v-text="hours.hours"></span>
        </li>
        <li class="day-total">
          <label>Total</label>
          <span v-text="day_overview.total"></span>
        </li>
      </ul>
    </div>
  </section>
</template>
