class PutRequest extends Request {
  constructor(url: string, body: object) {
    super(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
}

class PostRequest extends Request {
  constructor(url: string, body: object | FormData, formData: boolean = false) {
    super(url, {
      method: 'POST',
      body: formData ? (body as FormData) : JSON.stringify(body),
    });
  }
}

class DeleteRequest extends Request {
  constructor(url: string) {
    super(url, {
      method: 'DELETE',
    });
  }
}

export interface AjaxResponse<T> {
  status: number;
  data: T;
}

const http = async (request: Request): Promise<Response> => {
  // request interceptors
  const response = await fetch(request);
  // response interceptors
  if (response.status === 401 || response.status === 500) {
    document.location.href = '/login';
    return new Response(null, { status: 200 });
  }
  return response;
};

export class Ajax {
  async get<T>(url: string): Promise<AjaxResponse<T>> {
    const request = new Request(url);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data,
    };
  }

  async put<T>(url: string, body: object): Promise<AjaxResponse<T>> {
    const request = new PutRequest(url, body);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data,
    };
  }

  async post<T>(
    url: string,
    body: object,
    formData: boolean = false,
  ): Promise<AjaxResponse<T>> {
    const request = new PostRequest(url, body, formData);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data,
    };
  }

  async delete<T>(url: string): Promise<AjaxResponse<T>> {
    const request = new DeleteRequest(url);
    const response = await http(request);

    const data = response.status === 200 ? await response.json() : undefined;
    return {
      status: response.status,
      data,
    };
  }
}
