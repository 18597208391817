import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import versionInfo from '@/version.json';
import { Version } from '@/models/version';


export default /*@__PURE__*/_defineComponent({
  __name: 'Version',
  setup(__props) {

const version = new Version(versionInfo);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "version",
    textContent: _toDisplayString(_unref(version).version)
  }, null, 8, _hoisted_1))
}
}

})