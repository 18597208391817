<script lang="ts" setup>
import { PropType } from 'vue';
import { I18NData } from '@/core/i18n';
import { Expense } from '@/models/expense';
import { formatCurrency, formatDate, formatVAT } from '@/filters/filters';

const { expenses, i18n } = defineProps({
  expenses: {
    type: Array as PropType<Expense[]>,
    required: true,
  },
  i18n: {
    type: Object as PropType<I18NData>,
    default: true,
  },
});

const total = () => {
  return expenses.reduce((total, expense) => {
    total += expense.total;
    return total;
  }, 0);
};
</script>
<template>
  <section class="section tax-table">
    <h3 v-text="i18n.expenseSpecification"></h3>
    <table>
      <thead>
        <tr>
          <th width="12%" v-text="i18n.number"></th>
          <th class="align-right" width="15%" v-text="i18n.date"></th>
          <th v-text="i18n.description"></th>
          <th class="align-right" width="12%" v-text="i18n.amountCost"></th>
          <th class="align-right" width="10%" v-text="i18n.VATPercentage"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="expense in expenses" :key="expense.number">
          <tr>
            <td v-text="expense.number"></td>
            <td class="align-right">{{ formatDate(expense.date_created) }}</td>
            <td v-text="expense.description"></td>
            <td class="align-right">
              <span class="currency">{{ formatCurrency(expense.total) }}</span>
            </td>
            <td class="align-right">{{ formatVAT(expense.vat) }}</td>
          </tr>
          <tr v-for="line in expense.lines.slice(1)" :key="line.description">
            <td></td>
            <td class="align-right">{{ formatDate(expense.date_created) }}</td>
            <td v-text="line.description"></td>
            <td class="align-right">
              <span class="currency">{{ formatCurrency(line.total) }}</span>
            </td>
            <td class="align-right">{{ formatVAT(line.vat) }}</td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="empty"></td>
          <td class="align-right">
            <span class="currency">{{ formatCurrency(total(), true) }}</span>
          </td>
          <td class="empty"></td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>
