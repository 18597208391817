import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 2,
  class: "description"
}
const _hoisted_4 = ["textContent"]

import { PropType } from 'vue';

export interface CompoundListItem {
  label?: string;
  target?: string;
  targetDescription?: string;
  name: string;
  description?: string;
  styling?: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CompoundList',
  props: {
  list: { type: Array as PropType<CompoundListItem[]>, required: true },
  simple: { type: Boolean, default: false },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["compound", { 'compound-simple': __props.simple }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass(item.styling)
      }, [
        (item.label)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "primary-label",
              textContent: _toDisplayString(item.label)
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("section", null, [
          (item.target)
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                to: item.target,
                textContent: _toDisplayString(item.name),
                class: "name"
              }, null, 8, ["to", "textContent"]))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "name",
                textContent: _toDisplayString(item.name)
              }, null, 8, _hoisted_2)),
          (item.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                (item.targetDescription)
                  ? (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 0,
                      to: item.targetDescription,
                      textContent: _toDisplayString(item.description)
                    }, null, 8, ["to", "textContent"]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      textContent: _toDisplayString(item.description)
                    }, null, 8, _hoisted_4))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ], 2))
}
}

})