import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["name", "placeholder", "type", "disabled", "readonly", "autocomplete"]

import { FieldComposable, fieldProps } from './field.composable';
import Message from '@/components/common/Message.vue';
import { getMessage } from '@/core/validation';
import { watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Field',
  props: {
  ...fieldProps,
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
  `key.ctrl.${String}`,
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { fieldValue, update, enter, escape, key, name } = FieldComposable<
  string | number | null
>(props.modelValue, emit, props.label, props.prop);

watch(
  () => props.modelValue,
  (v) => {
    fieldValue.value = `${v}`;
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", [
      { invalid: props.validation.$invalid && props.validation.$dirty },
      props.styling,
    ]])
  }, [
    (!props.hideLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (
          props.validation &&
          props.validation.$invalid &&
          !props.validation.$dirty
        )
            ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      name: _unref(name),
      placeholder: props.placeholder || props.label,
      type: props.type,
      disabled: props.disabled,
      readonly: props.readonly,
      autocomplete: props.autocomplete,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldValue) ? (fieldValue).value = $event : null)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args))),
      onKeyup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(key) && _unref(key)(...args))),
      onKeydown: [
        _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_unref(enter) && _unref(enter)(...args)), ["exact"]), ["enter"])),
        _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_unref(escape) && _unref(escape)(...args)), ["exact"]), ["escape"]))
      ],
      onBlur: _cache[6] || (_cache[6] = ($event: any) => (props.validation.$touch()))
    }, null, 40, _hoisted_3), [
      [_vModelDynamic, _unref(fieldValue)]
    ]),
    _createVNode(Message, {
      type: "alert",
      styling: props.messageStyling,
      message: _unref(getMessage)(props.validation),
      show: props.validation.$invalid && props.validation.$dirty,
      onDismiss: _cache[7] || (_cache[7] = ($event: any) => (props.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})