import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "project"
}
const _hoisted_2 = ["textContent"]

import { computed, markRaw, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Project } from '@/models/project';
import Tooltip from '@/components/common/Tooltip.vue';
import { capitalize, formatCurrency, formatDate } from '@/filters/filters';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import ProjectForm from './ProjectForm.vue';
import ProjectInvoices from './ProjectInvoices.vue';
import { Company } from '@/models/company';
import { Actions, Mutations } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import { TimesheetActions } from '@/stores/timesheet.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Project',
  setup(__props) {

const store = useStore();
const route = useRoute();

const project = computed<Project>(() => store.state.project);
const project_hours = computed<string>(() => store.state.ts.project_hours);
const company = computed<Company>(() => store.state.company);

onMounted(async () => {
  const id = route.params.id;
  await store.dispatch(Actions.GetProject, id);
  await store.dispatch(TimesheetActions.GetProjectHours, id);
  await store.dispatch(Actions.GetCompany, project.value.company_id);
});

onUnmounted(() => {
  store.commit(Mutations.SetProject, null);
});

const info = (): CompoundListItem[] => {
  const list = [
    {
      name: 'Company',
      description: company.value.name,
      targetDescription: `/company/${company.value.id}`,
    },
    {
      name: 'Description',
      description: project.value.description,
    },
    { name: 'Start date', description: formatDate(project.value.date_start) },
    project.value.type
      ? { name: 'Type', description: capitalize(project.value.type) }
      : null,
    project.value.date_end
      ? { name: 'End date', description: formatDate(project.value.date_end) }
      : null,
    project.value.rate
      ? {
          name: 'Hourly rate',
          description: `€ ${formatCurrency(project.value.rate)}`,
        }
      : null,
    { name: 'Total hours', description: project_hours.value },
  ].filter((v) => v !== null);

  return list as CompoundListItem[];
};

const editProject = async (id: number) => {
  await store.dispatch(Actions.GetProject, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};

return (_ctx: any,_cache: any) => {
  return (project.value && company.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createElementVNode("span", {
            textContent: _toDisplayString(project.value.name)
          }, null, 8, _hoisted_2),
          _createVNode(Tooltip, {
            text: "Edit project",
            inline: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "option icononly",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (editProject(project.value.id)))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)
              ]))
            ]),
            _: 1
          })
        ]),
        _createVNode(CompoundList, {
          list: info(),
          simple: ""
        }, null, 8, ["list"]),
        _createVNode(ProjectInvoices, { project: project.value }, null, 8, ["project"])
      ]))
    : _createCommentVNode("", true)
}
}

})