import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, isRef as _isRef, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "readonly", "value", "disabled", "name"]

import { PropType } from 'vue';
import { Option } from '../../models/option';
import { FieldComposable, fieldProps } from './field.composable';
import { getMessage } from '@/core/validation';
import Message from '@/components/common/Message.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldOptions',
  props: {
  ...fieldProps,
  modelValue: {
    type: String,
    default: 0,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
  },
  options: {
    type: Array as PropType<Option<string>[]>,
    required: true,
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
  `key.ctrl.${String}`,
],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { fieldValue, update } = FieldComposable<String>(
  __props.modelValue,
  emit,
  __props.label,
  __props.prop,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field field-options", [{ invalid: _ctx.validation.$invalid && _ctx.validation.$dirty }, _ctx.styling]])
  }, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.validation && _ctx.validation.$invalid && !_ctx.validation.$dirty)
            ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
      return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass(__props.type),
        key: option.name,
        for: `${option.name}-opt`
      }, [
        _withDirectives(_createElementVNode("input", {
          id: `${option.name}-opt`,
          type: "radio",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(fieldValue) ? (fieldValue).value = $event : null)),
          readonly: _ctx.readonly,
          value: option.value,
          disabled: _ctx.disabled,
          name: _ctx.label
        }, null, 40, _hoisted_4), [
          [_vModelRadio, _unref(fieldValue)]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass(["radio-option", { 'radio-option-selected': _unref(fieldValue) === option.value }])
        }, null, 2),
        _createElementVNode("span", null, _toDisplayString(option.name), 1)
      ], 10, _hoisted_3))
    }), 128)),
    _createVNode(Message, {
      type: "alert",
      styling: _ctx.messageStyling,
      message: _unref(getMessage)(_ctx.validation),
      show: _ctx.validation.$invalid && _ctx.validation.$dirty,
      onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})