import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-day", "title"]
const _hoisted_2 = ["name", "autocomplete", "onKeydown", "onKeyup"]

import {
  computed,
  markRaw,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';
import { TimesheetDay } from '@/models/timesheet-day';
import { KeyUtil } from '@/core/util/key.util';
import { TimeUtil } from '@/core/util/time.util';
import { format } from 'date-fns';
import DayOverview from './overview/DayOverview.vue';
import HoursCalculator from './HoursCalculator.vue';
import HoursDetails from './HoursDetails.vue';
import HoursHelp from './HoursHelp.vue';
import { useEvents, Event } from '@/core/services/events.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'HoursField',
  props: {
  data: {
    type: Object as PropType<TimesheetDay>,
    required: true,
  },
  project_id: {
    type: Number,
    required: true,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
},
  setup(__props) {

const events = useEvents();

const props = __props;

const { data, project_id, autocomplete } = props;

const hoursInput = ref<HTMLInputElement | null>(null);

const day = ref<TimesheetDay>(
  new TimesheetDay({
    day: 1,
    week_number: 1,
    date: format(new Date(), 'yyyy-MM-dd'),
  }),
);
const children = ref<ChildComponent[]>([]);
const focussed = ref<boolean>(false);
const name = computed(() => `day_${props.data.date}`);

watch(props.data, () => {
  day.value.setData({
    ...props.data,
    project_id,
  });
});

onMounted(() => {
  day.value.setData({
    ...data,
    project_id: props.project_id,
  });
  events.on(Event.HoursFocus, checkFocus);
  events.on(Event.HoursStored, stored);
});

onBeforeUnmount(() => {
  events.off(Event.HoursFocus, checkFocus);
  events.off(Event.HoursStored, stored);
});

const change = () => {
  if (day.value.isChanged()) {
    day.value.stage();
    events.emit(Event.HoursChanged, day.value as TimesheetDay);
  } else if (day.value.staged) {
    events.emit(Event.HoursResetChanged, day.value as TimesheetDay);
  }
};

const formatValue = () => {
  const f = parseFloat(day.value.hours!);
  if (TimeUtil.isValidTime(day.value.hours!)) {
    day.value.hours = `${TimeUtil.timeToDecimal(day.value.hours!).toFixed(2)}`;
  } else if (!isNaN(f)) {
    day.value.hours = f.toFixed(2);
  }
  change();
};

const focus = () => {
  events.emit(Event.HoursFocus, data);
  hoursInput.value && hoursInput.value.select();
};

const checkFocus = (d: TimesheetDay | null) => {
  focussed.value = d ? d.date === data.date : false;

  if (!focussed.value) {
    children.value.pop();
  }
};

const blur = () => {
  formatValue();
  if (!children.value.length) {
    events.emit(Event.HoursFocus, null);
  }
};

const save = () => {
  formatValue();
  events.emit(Event.HoursStore, day.value);
};

const stored = () => {
  day.value.setData({
    ...props.data,
    project_id,
  });
  day.value.unstage();
  day.value.setState();
};

const keydown = (evt: KeyboardEvent) => {
  // disallow letters and such
  if (!KeyUtil.isAllowedHoursKey(evt.keyCode)) {
    evt.preventDefault();
  }
};

const addHour = () => {
  day.value.hours = (parseFloat(day.value.hours || '0') + 1).toFixed(2);
  change();
};

const subtractHour = () => {
  day.value.hours = (parseFloat(day.value.hours || '0') - 1).toFixed(2);
  change();
};

// called when a child component closes, or when escape is pressed
const closeChild = (type?: string, value?: string) => {
  if (type === 'details' && value) {
    day.value.description = value;
    change();
  } else if (type === 'help' && value && typeof value === 'string') {
    day.value.hours = value;
    change();
  } else if (type === 'calculator' && value && typeof value === 'string') {
    day.value.hours = value;
    change();
  }
  focus();
  children.value.pop();
};

class ChildComponent {
  component: any;
  data: any;

  constructor({ component, data }: { component: any; data?: any }) {
    this.component = markRaw(component);
    this.data = data;
  }

  get key() {
    return this.component;
  }
}

const showDetails = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursDetails,
      data: {
        description: day.value.description,
      },
    }),
  );
};

const showHoursHelp = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursHelp,
    }),
  );
};

const showHoursCalculator = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: HoursCalculator,
    }),
  );
};

const showDayOverview = () => {
  children.value.pop();
  children.value.push(
    new ChildComponent({
      component: DayOverview,
      data: {
        date: day.value.date,
      },
    }),
  );
};

return (_ctx: any,_cache: any) => {
  return (_unref(data))
    ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        "data-day": props.data.day,
        class: _normalizeClass(["form-item form-item-hours", {
      othermonth: props.data.next || props.data.previous,
      today: props.data.today,
      holiday: props.data.holiday,
      changed: day.value.isChanged(),
      focussed: focussed.value,
    }]),
        title: props.data.holiday ? props.data.holiday.name : ''
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "date",
          ref_key: "hoursInput",
          ref: hoursInput,
          step: "1.00",
          name: name.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((day.value.hours) = $event)),
          onFocus: focus,
          onBlur: blur,
          autocomplete: _unref(autocomplete),
          onKeydown: [
            _withModifiers(keydown, ["exact"]),
            _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => closeChild(), ["exact","prevent"]), ["escape"])),
            _withKeys(_withModifiers(save, ["exact","prevent"]), ["enter"])
          ],
          onKeyup: [
            _withKeys(_withModifiers(showDayOverview, ["ctrl","exact","prevent"]), ["84"]),
            _withKeys(_withModifiers(showHoursHelp, ["ctrl","exact","prevent"]), ["up"]),
            _withKeys(_withModifiers(showHoursCalculator, ["alt","exact","prevent"]), ["up"]),
            _withKeys(_withModifiers(showDetails, ["ctrl","exact","prevent"]), ["down"]),
            _withKeys(_withModifiers(addHour, ["exact","prevent"]), ["up"]),
            _withKeys(_withModifiers(subtractHour, ["exact","prevent"]), ["down"])
          ]
        }, null, 40, _hoisted_2), [
          [_vModelText, day.value.hours]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(children.value, (child) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(child.component), {
            key: child.key,
            onClose: closeChild,
            data: child.data
          }, null, 40, ["data"]))
        }), 128))
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})