import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "system-page" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import SubMenu from '@/components/common/SubMenu.vue';
import CompanyProjectList from './CompanyProjectList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SystemPage',
  setup(__props) {

const store = useStore();
const auth = computed(() => store.state.auth);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "System", -1)),
    _createVNode(SubMenu, null, {
      default: _withCtx(() => [
        (auth.value && auth.value.isAdmin)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: "/system/holidays"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Holidays")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(CompanyProjectList)
  ]))
}
}

})