import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page timesheet" }
const _hoisted_2 = {
  key: 1,
  class: "invoice"
}
const _hoisted_3 = { class: "invoicing-company" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = {
  key: 0,
  class: "invoice-details"
}
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "invoice-hours" }
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = { class: "signature" }
const _hoisted_19 = ["textContent"]
const _hoisted_20 = { class: "columns" }
const _hoisted_21 = { class: "col half" }
const _hoisted_22 = ["textContent"]
const _hoisted_23 = { class: "col half" }
const _hoisted_24 = ["textContent"]

import { useI18n } from '@/core/i18n/i18n.composable';
import { useStore } from 'vuex';
import { companyInfo } from '@/core/util/info';
import { TimeUtil } from '@/core/util/time.util';
import { DateUtil } from '@/core/util/date.util';
import LanguageSwitch from '@/components/common/LanguageSwitch.vue';
import Loading from '@/components/common/Loading.vue';
import { Timesheet } from '@/models/timesheet';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useLoading } from '../common/loading.composable';
import { TimesheetActions } from '@/stores/timesheet.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimesheetPrint',
  setup(__props) {

const store = useStore();
const route = useRoute();

const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const timesheet_weeks = computed<any[]>(
  () => store.getters[`ts/timesheet_weeks`],
);
const { i18nData, language, setLanguage } = useI18n();
const { loading, setLoading } = useLoading();

const totalHours = computed(() => {
  return TimeUtil.minutesToTime(
    timesheet_weeks.value.reduce((total: number, week: any) => {
      total += week.minutes;
      return total;
    }, 0),
  );
});

const load = async () => {
  const id = route.params.id;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheet, { id, date });
  setLoading(false);
};

onMounted(() => {
  load();
});

const minutesToTime = (minutes: number) => {
  return TimeUtil.minutesToTime(minutes);
};

const weekDescription = (week: any) => {
  const start = DateUtil.humanDay(language.value, week.start);
  const end = DateUtil.humanDay(language.value, week.end);

  return `${i18nData.value.workedHours} ${i18nData.value.from} ${start} ${i18nData.value.to} ${end}`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!timesheet.value)
      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
      : (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(LanguageSwitch, {
            language: _unref(language),
            onSetLanguage: _cache[0] || (_cache[0] = ($event: any) => (_unref(setLanguage)($event)))
          }, null, 8, ["language"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).name)
            }, null, 8, _hoisted_4),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).address.line)
            }, null, 8, _hoisted_5),
            _createElementVNode("div", {
              textContent: _toDisplayString(`${_unref(companyInfo).address.zipcode} ${_unref(companyInfo).address.city}`)
            }, null, 8, _hoisted_6),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).website)
            }, null, 8, _hoisted_7),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).email)
            }, null, 8, _hoisted_8)
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
          _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
          _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
          (timesheet.value.info)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h2", null, [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(_unref(i18nData).timesheet)
                  }, null, 8, _hoisted_10),
                  _cache[1] || (_cache[1] = _createTextVNode("  ")),
                  _createElementVNode("span", {
                    textContent: _toDisplayString(timesheet.value.info.title)
                  }, null, 8, _hoisted_11)
                ]),
                _createElementVNode("h3", null, [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(timesheet.value.project.name)
                  }, null, 8, _hoisted_12),
                  _cache[2] || (_cache[2] = _createTextVNode(" - ")),
                  _cache[3] || (_cache[3] = _createElementVNode("span", null, "Carl Giesberts", -1))
                ]),
                _createElementVNode("table", null, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        class: "invoice-table-header",
                        width: "85%",
                        textContent: _toDisplayString(_unref(i18nData).description)
                      }, null, 8, _hoisted_13),
                      _createElementVNode("th", {
                        class: "invoice-table-header",
                        textContent: _toDisplayString(_unref(i18nData).hours)
                      }, null, 8, _hoisted_14)
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timesheet_weeks.value, (week) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        class: "invoice-detail",
                        key: week.week_number
                      }, [
                        _createElementVNode("td", null, _toDisplayString(weekDescription(week)), 1),
                        _createElementVNode("td", _hoisted_15, _toDisplayString(minutesToTime(week.minutes)), 1)
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("tfoot", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        class: "total-description invoice-table-header",
                        textContent: _toDisplayString(_unref(i18nData).total)
                      }, null, 8, _hoisted_16),
                      _createElementVNode("td", {
                        class: "invoice-hours",
                        textContent: _toDisplayString(totalHours.value)
                      }, null, 8, _hoisted_17)
                    ])
                  ])
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("section", _hoisted_18, [
                  _createElementVNode("h3", {
                    textContent: _toDisplayString(_unref(i18nData).agreed)
                  }, null, 8, _hoisted_19),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", {
                        textContent: _toDisplayString(_unref(i18nData).nameAndDate)
                      }, null, 8, _hoisted_22),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "line" }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", {
                        textContent: _toDisplayString(_unref(i18nData).signature)
                      }, null, 8, _hoisted_24),
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "line" }, null, -1))
                    ])
                  ])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})