<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import { Project } from '@/models/project';
import { ProjectInfo } from '@/models/project-info';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import ProjectForm from '@/components/project/ProjectForm.vue';
import CompoundList, {
  CompoundListItem,
} from '@/components/common/CompoundList.vue';
import { DrawerMutations } from '@/stores/drawer.store';

const store = useStore();

const project = computed<Project | null>(() => store.state.project);
const filter = computed<string>(() => store.state.filter);
const filteredProjects = computed<ProjectInfo[]>(
  () => store.getters.filteredProjects,
);

const showArchived = ref<boolean>(false);

watch(filter, (v: string) => {
  store.commit(Mutations.UpdateFilter, v);
});

const toggleArchived = () => {
  showArchived.value = !showArchived.value;
};

const list = computed((): CompoundListItem[] => {
  return (
    showArchived.value
      ? filteredProjects.value
      : filteredProjects.value.filter((p: ProjectInfo) => !p.archived)
  ).map((p: ProjectInfo): CompoundListItem => {
    return {
      label: p.company_name,
      target: `/timesheet/project/${p.id}`,
      name: p.name,
      description: p.description,
      styling: p.ended ? 'project-finished' : null,
    };
  });
});

onMounted(() => {
  store.dispatch(Actions.GetProjects);
});

onUnmounted(() => {
  store.commit(Mutations.UpdateFilter, '');
});

const input = (v: string) => {
  store.commit(Mutations.UpdateFilter, v);
};

const newProject = async () => {
  await store.dispatch(Actions.NewProject);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};
</script>
<template>
  <section class="project-list">
    <h2>Projects</h2>
    <Loading v-if="!list.length && !filter"></Loading>
    <p v-else-if="!list.length && filter">No results, adjust the filter</p>
    <CompoundList v-else :list="list" />
    <div class="buttons" v-if="list.length || filter">
      <Field label="Filter" hide-label styling="inline" @input="input"></Field>
      <button type="button" class="option" @click="toggleArchived">
        <span class="icon icon-archive"></span>
        Show {{ showArchived ? 'Active' : 'All' }}
      </button>
      <button type="button" class="option" @click="newProject">
        <span class="icon icon-pencil"></span> Add project
      </button>
    </div>
  </section>
</template>
