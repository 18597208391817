import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card white spaced" }
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = { class: "columns tax-data-fields" }
const _hoisted_4 = { class: "columns" }
const _hoisted_5 = { class: "columns" }
const _hoisted_6 = { class: "columns" }
const _hoisted_7 = { class: "columns" }

import { PropType } from 'vue';
import { TaxData, taxDataValidations } from '@/models/taxes';
import useVuelidate from '@vuelidate/core';
import FieldNumber from '../common/FieldNumber.vue';
import FieldSwitch from '../common/FieldSwitch.vue';
import Field from '../common/Field.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxDataFields',
  props: {
  data: {
    type: Object as PropType<TaxData>,
    required: true
  },
},
  setup(__props) {



const $v = useVuelidate(taxDataValidations, __props.data);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col half" }, "Date received", -1)),
      _createVNode(Field, {
        type: "date",
        styling: "col half block",
        modelValue: __props.data.date_received,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.data.date_received) = $event)),
        validation: _unref($v).date_received
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col two-quarters reposition" }, "Box 1", -1)),
      _createVNode(FieldNumber, {
        label: "Income",
        styling: "col quarter block",
        modelValue: __props.data.box_1_income,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.data.box_1_income) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_1_income,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"]),
      _createVNode(FieldNumber, {
        label: "Taxes",
        styling: "col quarter block",
        modelValue: __props.data.box_1_taxes,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.data.box_1_taxes) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_1_taxes,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col two-quarters" }, "Box 2", -1)),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.box_2_income,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((__props.data.box_2_income) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_2_income,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"]),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.box_2_taxes,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((__props.data.box_2_taxes) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_2_taxes,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[13] || (_cache[13] = _createElementVNode("label", { class: "col two-quarters" }, "Box 3", -1)),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.box_3_income,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((__props.data.box_3_income) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_3_income,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"]),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.box_3_taxes,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((__props.data.box_3_taxes) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).box_3_taxes,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[14] || (_cache[14] = _createElementVNode("label", { class: "col three-quarters" }, "National Insurance Premium", -1)),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.national_insurance_premium,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((__props.data.national_insurance_premium) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).national_insurance_premium,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[15] || (_cache[15] = _createElementVNode("label", { class: "col three-quarters" }, "Tax Credits", -1)),
      _createVNode(FieldNumber, {
        styling: "col quarter block",
        modelValue: __props.data.tax_credits,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((__props.data.tax_credits) = $event)),
        modelModifiers: { number: true },
        validation: _unref($v).tax_credits,
        step: "1.00"
      }, null, 8, ["modelValue", "validation"])
    ]),
    _createVNode(FieldSwitch, {
      label: "Preliminary",
      styling: "block",
      modelValue: __props.data.preliminary,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((__props.data.preliminary) = $event))
    }, null, 8, ["modelValue"])
  ]))
}
}

})