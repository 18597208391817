<script lang="ts" setup>
import { PropType } from 'vue';
import { I18NData } from '@/core/i18n';
import { Company } from '@/models/company';
import { Invoice } from '@/models/invoice';
import { formatCurrency, formatDate } from '@/filters/filters';

const props = defineProps({
  invoices: {
    type: Array as PropType<Invoice[]>,
    required: true,
  },
  companies: {
    type: Array as PropType<Company[]>,
    required: true,
  },
  i18n: {
    type: Object as PropType<I18NData>,
    default: true,
  },
});

const total = () => {
  return props.invoices.reduce((total, invoice) => {
    total += invoice.total;
    return total;
  }, 0);
};

const sub_total = () => {
  return props.invoices.reduce((total, invoice) => {
    total += invoice.sub_total;
    return total;
  }, 0);
};
</script>
<template>
  <section class="section tax-table">
    <h3 v-text="i18n.incomeSpecification"></h3>

    <table>
      <thead>
        <tr>
          <th width="10%" v-text="i18n.number"></th>
          <th class="align-right" width="13%" v-text="i18n.date"></th>
          <th v-text="i18n.description"></th>
          <th class="align-right" width="15%" v-text="i18n.amountExcl"></th>
          <th class="align-right" width="15%" v-text="i18n.amountIncl"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in invoices" :key="invoice.number">
          <td v-text="invoice.number"></td>
          <td class="align-right">{{ formatDate(invoice.date_created) }}</td>
          <td v-text="invoice.description"></td>
          <td class="align-right">
            <span class="currency">{{
              formatCurrency(invoice.sub_total)
            }}</span>
          </td>
          <td class="align-right">
            <span class="currency">{{ formatCurrency(invoice.total) }}</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="empty"></td>
          <td class="align-right">
            <span class="currency">{{ formatCurrency(sub_total()) }}</span>
          </td>
          <td class="align-right">
            <span class="currency">{{ formatCurrency(total()) }}</span>
          </td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>
