import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, PropType, ref, watch } from 'vue';
import { User } from '@/models/user';
import Field from '@/components/common/Field.vue';
import AddressFields from '@/components/common/AddressFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFields',
  props: {
  modelValue: { type: Object as PropType<User>, required: true },
  validation: { type: Object, required: true },
  loading: { type: Boolean, required: true },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const user = ref<User>(__props.modelValue);

onMounted(() => {
  user.value = __props.modelValue;
});

watch(user, (v) => emit('update:modelValue', v));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(Field, {
      label: "First name",
      validation: __props.validation.first_name,
      modelValue: user.value.first_name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user.value.first_name) = $event)),
      disabled: __props.loading
    }, null, 8, ["validation", "modelValue", "disabled"]),
    _createVNode(Field, {
      label: "Last name",
      validation: __props.validation.last_name,
      modelValue: user.value.last_name,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.value.last_name) = $event)),
      disabled: __props.loading
    }, null, 8, ["validation", "modelValue", "disabled"]),
    _createVNode(Field, {
      label: "Account",
      validation: __props.validation.account,
      modelValue: user.value.account,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((user.value.account) = $event)),
      disabled: __props.loading
    }, null, 8, ["validation", "modelValue", "disabled"]),
    _createVNode(Field, {
      label: "Phone",
      validation: __props.validation.phone,
      modelValue: user.value.phone,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((user.value.phone) = $event)),
      disabled: __props.loading,
      styling: "half"
    }, null, 8, ["validation", "modelValue", "disabled"]),
    _createVNode(AddressFields, {
      modelValue: user.value.address,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((user.value.address) = $event)),
      validation: __props.validation.address,
      loading: __props.loading
    }, null, 8, ["modelValue", "validation", "loading"])
  ]))
}
}

})