<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { INVOICE_TYPE, Project } from '@/models/project';
import { Timesheet, Days, isWeekTotal } from '@/models/timesheet';
import { TimeUtil } from '@/core/util/time.util';
import { TimesheetDay } from '@/models/timesheet-day';
import HoursField from './HoursField.vue';
import WeekTotals from './WeekTotals.vue';
import TimesheetProjectInfo from './TimesheetProjectInfo.vue';
import TimesheetInvoice from './TimesheetInvoice.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import Loading from '@/components/common/Loading.vue';
import ProjectForm from '@/components/project/ProjectForm.vue';
import { useLoading } from '../common/loading.composable';
import { Actions } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import { TimesheetActions, TimesheetMutations } from '@/stores/timesheet.store';
import { useEvents, Event } from '@/core/services/events.composable';

const store = useStore();
const route = useRoute();
const events = useEvents();
const { loading, setLoading } = useLoading();

const project = computed<Project | null>(() => store.state.project);
const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const timesheet_days = computed<Days[]>(() => store.state.ts.timesheet_days);
const changedDays = computed(() => store.state.ts.changedDays);
const removedDays = computed(() => store.state.ts.removedDays);
const timesheet_hours = computed<TimesheetDay[]>(
  () => store.getters['ts/timesheet_hours'],
);

const project_id = computed(() => {
  return parseInt(route.params.id as string, 10);
});

const previous = computed(() => {
  return `/timesheet/project/${timesheet.value.project.id}/${timesheet.value.info.previous}`;
});

const next = computed(() => {
  return `/timesheet/project/${timesheet.value.project.id}/${timesheet.value.info.next}`;
});

const isInvoiceable = computed(() => {
  return timesheet.value.project.type === INVOICE_TYPE;
});

const lastDayClass = computed(() => {
  const nr =
    7 -
    timesheet.value.days.filter((d: Days) => (d as TimesheetDay).next).length;
  return `last-day-${nr}`;
});

const month_total = computed((): string => {
  return TimeUtil.minutesToTime(
    timesheet_hours.value
      .filter(
        (day: Days) =>
          day.date.indexOf(timesheet.value.info.month) !== -1 &&
          !isWeekTotal(day),
      )
      .reduce((minutes: number, day: Days) => {
        minutes += (day as TimesheetDay).minutes || 0;
        return minutes;
      }, 0),
  );
});

const load = async () => {
  const id = route.params.id;
  const date = route.params.date;
  if (id) {
    setLoading(true);
    await store.dispatch(TimesheetActions.GetTimesheet, { id, date });
    await store.dispatch(Actions.GetProject, id);
    setLoading(false);
  }
};

watch(route, load);

const save = async () => {
  if (
    !loading.value &&
    (changedDays.value.length || removedDays.value.length)
  ) {
    setLoading(true);
    await store.dispatch(TimesheetActions.UpdateTimesheet);
    await store.dispatch(Actions.ShowNotification, {
      message: 'Hours saved',
      type: 'confirm',
    });
    setLoading(false);
    events.emit(Event.HoursStored);
  }
};

onMounted(() => {
  load();
  events.on(Event.HoursChanged, stage);
  events.on(Event.HoursResetChanged, resetStaged);
  events.on(Event.HoursStore, save);
});

onBeforeUnmount(() => {
  store.commit(TimesheetMutations.ResetTimesheetDays);
  events.off(Event.HoursChanged, stage);
  events.off(Event.HoursResetChanged, resetStaged);
  events.off(Event.HoursStore, save);
});

const stage = (day: TimesheetDay) => {
  store.commit(TimesheetMutations.ResetStagedDay, day);
  if (day.minutes !== 0) {
    // only save hours with time
    store.commit(TimesheetMutations.UpdateChangedDays, day);
  } else if (day.id) {
    // only remove previously stored hours
    store.commit(TimesheetMutations.UpdateRemovedDays, day);
  }
};

const resetStaged = (day: TimesheetDay) => {
  return;
};

const getFieldType = (day: Days) => {
  return day.hasOwnProperty('isWeek') ? WeekTotals : HoursField;
};

const editProject = async (id: number) => {
  await store.dispatch(Actions.GetProject, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};
</script>
<template>
  <section class="timesheet">
    <Loading v-if="!timesheet || !timesheet.project"></Loading>
    <section v-else>
      <TimesheetProjectInfo
        v-if="project"
        :project="project"
        @edit:project="editProject"
      />
      <h3>
        <span v-text="timesheet.info.title"></span>
        <Tooltip text="Print timesheet" inline>
          <RouterLink
            :to="`/timesheet/project/${timesheet.project.id}/${timesheet.info.month}-01/print`"
            v-slot="{ navigate }"
            custom
          >
            <button type="button" class="option icononly" @click="navigate">
              <span class="icon icon-print"></span>
            </button>
          </RouterLink>
        </Tooltip>
      </h3>
      <ul class="options">
        <li>
          <RouterLink :to="previous">
            <span class="icon icon-caret-left"></span>
            <span v-text="timesheet.info.previous_name"></span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="next">
            <span class="icon icon-caret-right"></span>
            <span v-text="timesheet.info.next_name"></span>
          </RouterLink>
        </li>
      </ul>
      <ul class="weekdays">
        <li
          v-for="day in timesheet.info.weekdays"
          class="header"
          v-text="day"
          :key="day"
        ></li>
        <li class="timesheet-loader small-loader">
          <Loading v-if="loading"></Loading>
        </li>
        <component
          :is="getFieldType(day)"
          v-for="day in timesheet_days"
          :data="day"
          :key="day.date"
          :project_id="project_id"
        ></component>
        <li
          class="monthtotals"
          :class="lastDayClass"
          v-if="timesheet_days.length"
        >
          <Tooltip
            :text="`Total hours for this project in ${timesheet.info.title}`"
          >
            <div class="total month-project-total" v-text="month_total"></div>
          </Tooltip>
        </li>
      </ul>
      <TimesheetInvoice
        v-if="isInvoiceable"
        :month="timesheet.info.month"
      ></TimesheetInvoice>
    </section>
  </section>
</template>
