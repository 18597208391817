import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "admin-companies" }
const _hoisted_2 = { class: "simple" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "cards" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["onClick"]

import { Company } from '@/models/company';
import { Project } from '@/models/project';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { ModalMutations } from '@/stores/modal.store';
import { Actions } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyProjectList',
  setup(__props) {

const store = useStore();
const companies = computed<Company[]>(() => store.state.companies);
const projects = computed<Project[]>(() => store.state.projects);

const companyList = computed((): Array<Company> => {
  const list = projects.value
    ? projects.value.reduce((li: Array<Company>, project) => {
        const company = li.find((c) => c.id === project.company_id);
        if (!company) {
          li.push({
            id: project.company_id,
            name: project.company_name,
            projects: [project],
          });
        } else {
          company.projects!.push(project);
        }
        return li;
      }, [])
    : [];
  const other: Array<Company> = companies.value
    .filter((c) => !list.find((co) => co.id === c.id))
    .map((company: Company) => {
      return {
        ...company,
        projects: [],
      };
    });
  return list.concat(other);
});

onMounted(() => {
  store.dispatch(Actions.GetCompanies);
  store.dispatch(Actions.GetProjects);
});

const onConfirmDeleteProject = (data: any) => {
  store.dispatch(Actions.RemoveProject, data.project.id);
};

const deleteProject = (project: Project) => {
  store.commit(ModalMutations.OpenModal, {
    modal: 'confirm-delete-modal',
    data: {
      name: project.name,
      project,
      confirm: onConfirmDeleteProject,
    },
  });
};

const onConfirmDeleteCompany = (data: any) => {
  store.dispatch(Actions.RemoveCompany, data.company.id);
};

const deleteCompany = (company: Company) => {
  store.commit(ModalMutations.OpenModal, {
    modal: 'confirm-delete-modal',
    data: {
      name: company.name,
      company,
      confirm: onConfirmDeleteCompany,
    },
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Companies", -1)),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companyList.value, (company) => {
        return (_openBlock(), _createElementBlock("li", {
          key: company.id,
          class: "card"
        }, [
          _createElementVNode("h4", {
            textContent: _toDisplayString(company.name)
          }, null, 8, _hoisted_3),
          (company.projects && company.projects.length === 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "danger icononly",
                type: "button",
                onClick: _withModifiers(($event: any) => (deleteCompany(company)), ["prevent"])
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("i", { class: "icon icon-remove" }, null, -1)
              ]), 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(company.projects, (project) => {
              return (_openBlock(), _createElementBlock("li", {
                key: project.id,
                class: "card"
              }, [
                _createElementVNode("span", {
                  textContent: _toDisplayString(project.name)
                }, null, 8, _hoisted_6),
                _createElementVNode("button", {
                  class: "danger icononly",
                  type: "button",
                  onClick: _withModifiers(($event: any) => (deleteProject(project)), ["prevent"])
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "icon icon-remove" }, null, -1)
                ]), 8, _hoisted_7)
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})