import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drawer-content user-form" }
const _hoisted_2 = { class: "cards" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["disabled"]

import { computed, PropType, reactive } from 'vue';
import { useStore } from 'vuex';
import { useLoading } from '../common/loading.composable';
import { Actions } from '@/store';
import { getNewTaxData, TaxInfo } from '@/models/taxes';
import TaxDataFields from './TaxDataFields.vue';
import { useEvents, Event } from '@/core/services/events.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxForm',
  props: {
  data: {
    type: Object as PropType<TaxInfo>,
    required: true
  },
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const store = useStore();
const events = useEvents();

const props = __props;

const emit = __emit;

const { loading, setLoading } = useLoading();
let taxes = reactive<TaxInfo>({ ...props.data });

const action = computed<string>(() => taxes.id === 0 ? 'Create': 'Edit')

const submit = async () => {
  setLoading(true);
  if (taxes.id === 0) {
    await store.dispatch(Actions.CreateTaxes, taxes);
  } else {
    await store.dispatch(Actions.UpdateTaxes, taxes);
  }
  setLoading(false);
  events.emit(Event.TaxesUpdated, taxes);
  cancel();
};

const cancel = () => {
  emit('close');
};

const addData = () => {
  Object.assign(taxes, {data: [...taxes.data, getNewTaxData()]})
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_unref(taxes))
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _withModifiers(submit, ["prevent"]),
          class: _normalizeClass({ submitting: _unref(loading) })
        }, [
          _createElementVNode("h2", null, _toDisplayString(action.value) + " tax information for " + _toDisplayString(_unref(taxes).year), 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxes).data, (data) => {
              return (_openBlock(), _createBlock(TaxDataFields, {
                key: data.id,
                data: data
              }, null, 8, ["data"]))
            }), 128))
          ]),
          (!_unref(taxes).data.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                _createTextVNode("No information has been saved for " + _toDisplayString(__props.data.year) + ". ", 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(addData, ["prevent"])
                }, "Add (preliminary) tax information"),
                _cache[0] || (_cache[0] = _createTextVNode("."))
              ]))
            : _createCommentVNode("", true),
          (_unref(taxes).data.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(addData, ["prevent"])
                }, "Add more (preliminary) tax information"),
                _cache[1] || (_cache[1] = _createTextVNode("."))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "confirm",
              type: "submit",
              disabled: _unref(loading) || !_unref(taxes).data.length
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { class: "icon icon-pencil" }, null, -1),
              _createElementVNode("span", { class: "text" }, "Edit", -1)
            ]), 8, _hoisted_6),
            _createElementVNode("button", {
              class: "cancel",
              type: "button",
              onClick: cancel
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "text" }, "Cancel", -1)
            ]))
          ])
        ], 34))
      : _createCommentVNode("", true)
  ]))
}
}

})