import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "section balance-details" }
const _hoisted_2 = {
  key: 0,
  class: "small-loader-header"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "balance-detail invoice-currency" }
const _hoisted_8 = { class: "currency" }
const _hoisted_9 = { class: "balance-detail invoice-currency balance-tooltip" }
const _hoisted_10 = { class: "currency" }
const _hoisted_11 = { class: "balance-detail invoice-currency balance-tooltip" }
const _hoisted_12 = { class: "currency" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = { class: "balance-detail invoice-currency" }
const _hoisted_15 = { class: "currency" }
const _hoisted_16 = { class: "balance-detail invoice-currency balance-tooltip" }
const _hoisted_17 = { class: "currency" }
const _hoisted_18 = { class: "balance-detail invoice-currency" }
const _hoisted_19 = { class: "currency" }
const _hoisted_20 = ["textContent"]
const _hoisted_21 = { class: "balance-detail invoice-currency" }
const _hoisted_22 = { class: "currency" }
const _hoisted_23 = { class: "balance-detail invoice-currency" }
const _hoisted_24 = { class: "currency" }
const _hoisted_25 = { class: "balance-detail invoice-currency" }
const _hoisted_26 = { class: "currency" }

import { PropType } from 'vue';
import { BalanceOverview as BalanceOverviewModel } from '@/models/balance';
import TaxInformation from './TaxInformation.vue';
import { I18NData } from '@/core/i18n';
import Loading from '@/components/common/Loading.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import { formatCurrency } from '@/filters/filters';
import { TaxInfo } from '@/models/taxes';


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceOverview',
  props: {
  overview: {
    type: Object as PropType<BalanceOverviewModel>,
    required: true,
  },
  taxes: {
    type: Object as PropType<TaxInfo>,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
  },
  i18n: {
    type: Object as PropType<I18NData>,
    required: true,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!__props.hideTitle)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
          _cache[0] || (_cache[0] = _createTextVNode(" Overview ")),
          (__props.loading)
            ? (_openBlock(), _createBlock(Loading, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1)),
          _createElementVNode("th", {
            class: "balance-details-header invoice-currency",
            textContent: _toDisplayString(__props.i18n.withoutVAT)
          }, null, 8, _hoisted_3),
          _createElementVNode("th", {
            class: "balance-details-header invoice-currency",
            textContent: _toDisplayString(__props.i18n.VAT)
          }, null, 8, _hoisted_4),
          _createElementVNode("th", {
            class: "balance-details-header invoice-currency",
            textContent: _toDisplayString(__props.i18n.total)
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            class: "balance-details-header invoice-currency",
            textContent: _toDisplayString(__props.i18n.incoming)
          }, null, 8, _hoisted_6),
          _createElementVNode("td", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(formatCurrency)(__props.overview.incoming.without_vat)), 1)
          ]),
          _createElementVNode("td", _hoisted_9, [
            _createVNode(Tooltip, {
              highlight: "",
              text: "This is the 'Omzetbelasting' on the VAT declaration."
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(formatCurrency)(__props.overview.incoming.vat)), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("td", _hoisted_11, [
            _createVNode(Tooltip, {
              highlight: "",
              text: "This is the 'bedrag waarover omzetbelasting wordt berekend' on the VAT declaration."
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(formatCurrency)(__props.overview.incoming.total)), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            class: "invoice-currency",
            textContent: _toDisplayString(__props.i18n.outgoing)
          }, null, 8, _hoisted_13),
          _createElementVNode("td", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(formatCurrency)(__props.overview.outgoing.without_vat)), 1)
          ]),
          _createElementVNode("td", _hoisted_16, [
            _createVNode(Tooltip, {
              highlight: "",
              text: "This is the 'Voorbelasting' on the VAT declaration."
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(formatCurrency)(__props.overview.outgoing.vat)), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("td", _hoisted_18, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(formatCurrency)(__props.overview.outgoing.total)), 1)
          ])
        ])
      ]),
      _createElementVNode("tfoot", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            class: "invoice-currency",
            textContent: _toDisplayString(__props.i18n.balance)
          }, null, 8, _hoisted_20),
          _createElementVNode("td", _hoisted_21, [
            _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(formatCurrency)(__props.overview.balance.without_vat)), 1)
          ]),
          _createElementVNode("td", _hoisted_23, [
            _createElementVNode("span", _hoisted_24, _toDisplayString(_unref(formatCurrency)(__props.overview.balance.vat)), 1)
          ]),
          _createElementVNode("td", _hoisted_25, [
            _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(formatCurrency)(__props.overview.balance.total)), 1)
          ])
        ])
      ])
    ]),
    _createVNode(TaxInformation, {
      taxes: __props.taxes,
      i18n: __props.i18n,
      type: __props.type
    }, null, 8, ["taxes", "i18n", "type"])
  ]))
}
}

})