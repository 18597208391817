import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "drawer-container" }

import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { DrawerActions, DrawerInfo } from '@/stores/drawer.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Drawer',
  setup(__props) {

const store = useStore();
const drawer = computed<DrawerInfo | null>(() => store.state.ds.drawer);
const open = ref<boolean>(false); // needed to keep showing content while drawer closes

watch(drawer, (drawer: DrawerInfo | null) => {
  if (drawer) {
    open.value = true;
  }
});

const close = () => {
  open.value = false;
  store.dispatch(DrawerActions.CloseDrawer);
};

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(["drawer", { open: open.value }])
    }, [
      (drawer.value)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(drawer.value.drawer), {
            key: 0,
            onClose: close,
            data: drawer.value.data
          }, null, 40, ["data"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", { class: "drawer-close" }, [
        _createElementVNode("button", {
          class: "cancel icononly",
          onClick: close
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "icon icon-remove" }, null, -1)
        ]))
      ])
    ], 2)
  ])), [
    [_directive_click_outside, close]
  ])
}
}

})