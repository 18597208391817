import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "primary-label" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "tags" }
const _hoisted_4 = { class: "tag" }
const _hoisted_5 = { class: "currency" }

import { computed, PropType, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Company } from '@/models/company';
import { Invoice } from '@/models/invoice';
import { formatCurrency } from '@/filters/filters';
import InvoiceStatusModal from '@/components/balance/InvoiceStatusModal.vue';
import { ModalMutations } from '@/stores/modal.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceItem',
  props: {
  invoice: {
    type: Object as PropType<Invoice>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();
const companies = computed<Company[]>(() => store.state.companies);



const invoiceUrl = computed(() => {
  return `/balance/invoice/${__props.invoice.number}`;
});
const companyName = computed(() => {
  const company = companies.value.length
    ? companies.value.find((c) => c.id === __props.invoice.company_id)
    : null;

  return company ? company.name : '';
});

const showInvoiceStatusModal = () => {
  if (!__props.invoice.paid) {
    store.commit(ModalMutations.OpenModal, {
      modal: markRaw(InvoiceStatusModal),
      data: {
        invoice: __props.invoice,
      },
    });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_raise_ordinal = _resolveDirective("raise-ordinal")!

  return (_openBlock(), _createElementBlock("li", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(__props.invoice.date_created_f), 1)
    ])), [
      [_directive_raise_ordinal]
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, { to: invoiceUrl.value }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.invoice.number), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(" - " + _toDisplayString(__props.invoice.subject) + ", " + _toDisplayString(companyName.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!__props.invoice.paid)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tag tag-link", { 'tag-expired': __props.invoice.expired }]),
            onClick: showInvoiceStatusModal
          }, " Unpaid ", 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(formatCurrency)(__props.invoice.total)), 1)
      ])
    ])
  ]))
}
}

})