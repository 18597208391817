import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["name", "placeholder", "disabled", "readonly", "pattern", "autocomplete"]

import { computed, onMounted, ref, watch } from 'vue';
import { getMessage } from '@/core/validation';
import { FieldComposable, fieldProps } from './field.composable';
import Message from '@/components/common/Message.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldNumber',
  props: {
  ...fieldProps,
  modelValue: {
    type: Number,
    default: 0,
  },
  placeholder: {
    type: String,
  },
  step: {
    type: String,
    default: '1',
  },
  pattern: {
    type: String,
    default() {
      return '\-?[0-9]+([.][0-9]{1,2})?';
    },
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
  `key.ctrl.${String}`,
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { fieldValue, enter, escape, name } = FieldComposable<String>(
  `${props.modelValue}`,
  emit,
  props.label,
  props.prop,
);

const focussed = ref<boolean>(false);
const decimalPoint = computed(() => {
  return props.step.substring(props.step.indexOf('.')).length - 1;
});

watch(
  () => props.modelValue,
  (v) => {
    fieldValue.value = `${v}`;
    format();
  },
);

onMounted(() => {
  fieldValue.value = `${props.modelValue}`;
  format();
});

const update = () => {
  emit('update:modelValue', formatValue(fieldValue.value as string));
  emit('input', formatValue(fieldValue.value as string));
};

const formatValue = (v: string) => parseFloat(v);

const format = () => {
  const val = formatValue(fieldValue.value as string);

  if (isNaN(val)) {
    fieldValue.value = '';
  } else if (!focussed.value) {
    fieldValue.value = val.toFixed(decimalPoint.value);
  }
};

const focus = () => {
  focussed.value = true;
};

const blur = () => {
  focussed.value = false;
  format();
  props.validation.$touch();
};

const key = (evt: KeyboardEvent) => {
  emit('update:modelValue', formatValue(fieldValue.value as string));
  const modifier = evt.ctrlKey ? 'ctrl.' : '';
  emit(`key.${modifier}${evt.key}`, fieldValue.value, evt.key);
};

const upKey = (evt: Event) => {
  if (props.disabled || props.readonly) {
    return;
  }
  fieldValue.value = parseFloat(
    `${parseFloat(`${fieldValue.value}`) + parseFloat(props.step)}`,
  ).toFixed(decimalPoint.value);
  format();
  update();
  evt.stopPropagation();
};

const downKey = (evt: Event) => {
  if (props.disabled || props.readonly) {
    return;
  }
  fieldValue.value = parseFloat(
    `${parseFloat(`${fieldValue.value}`) - parseFloat(props.step)}`,
  ).toFixed(decimalPoint.value);
  format();
  update();
  evt.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field field-number", [
      { invalid: props.validation.$invalid && props.validation.$dirty },
      props.styling,
    ]])
  }, [
    (!props.hideLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(props.label) + " ", 1),
          (
          props.validation &&
          props.validation.$invalid &&
          !props.validation.$dirty
        )
            ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      name: _unref(name),
      placeholder: props.placeholder || props.label,
      disabled: props.disabled,
      readonly: props.readonly,
      pattern: props.pattern,
      autocomplete: props.autocomplete,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldValue) ? (fieldValue).value = $event : null)),
      onChange: update,
      onInput: update,
      onKeyup: key,
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(($event: any) => (upKey($event)), ["up"])),
        _cache[2] || (_cache[2] = _withKeys(($event: any) => (downKey($event)), ["down"])),
        _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_unref(enter) && _unref(enter)(...args)), ["exact"]), ["enter"])),
        _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_unref(escape) && _unref(escape)(...args)), ["exact"]), ["escape"]))
      ],
      onFocus: focus,
      onBlur: blur
    }, null, 40, _hoisted_3), [
      [_vModelText, _unref(fieldValue)]
    ]),
    _createVNode(Message, {
      type: "alert",
      styling: props.messageStyling,
      message: _unref(getMessage)(props.validation),
      show: props.validation.$invalid && props.validation.$dirty,
      onDismiss: _cache[5] || (_cache[5] = ($event: any) => (props.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})