import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "section tax-table" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "align-right" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "align-right" }
const _hoisted_12 = { class: "currency" }
const _hoisted_13 = { class: "align-right" }
const _hoisted_14 = { class: "currency" }
const _hoisted_15 = { class: "align-right" }
const _hoisted_16 = { class: "currency" }
const _hoisted_17 = { class: "align-right" }
const _hoisted_18 = { class: "currency" }

import { PropType } from 'vue';
import { I18NData } from '@/core/i18n';
import { Company } from '@/models/company';
import { Invoice } from '@/models/invoice';
import { formatCurrency, formatDate } from '@/filters/filters';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxInvoiceList',
  props: {
  invoices: {
    type: Array as PropType<Invoice[]>,
    required: true,
  },
  companies: {
    type: Array as PropType<Company[]>,
    required: true,
  },
  i18n: {
    type: Object as PropType<I18NData>,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const total = () => {
  return props.invoices.reduce((total, invoice) => {
    total += invoice.total;
    return total;
  }, 0);
};

const sub_total = () => {
  return props.invoices.reduce((total, invoice) => {
    total += invoice.sub_total;
    return total;
  }, 0);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", {
      textContent: _toDisplayString(__props.i18n.incomeSpecification)
    }, null, 8, _hoisted_2),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            width: "10%",
            textContent: _toDisplayString(__props.i18n.number)
          }, null, 8, _hoisted_3),
          _createElementVNode("th", {
            class: "align-right",
            width: "13%",
            textContent: _toDisplayString(__props.i18n.date)
          }, null, 8, _hoisted_4),
          _createElementVNode("th", {
            textContent: _toDisplayString(__props.i18n.description)
          }, null, 8, _hoisted_5),
          _createElementVNode("th", {
            class: "align-right",
            width: "15%",
            textContent: _toDisplayString(__props.i18n.amountExcl)
          }, null, 8, _hoisted_6),
          _createElementVNode("th", {
            class: "align-right",
            width: "15%",
            textContent: _toDisplayString(__props.i18n.amountIncl)
          }, null, 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.invoices, (invoice) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: invoice.number
          }, [
            _createElementVNode("td", {
              textContent: _toDisplayString(invoice.number)
            }, null, 8, _hoisted_8),
            _createElementVNode("td", _hoisted_9, _toDisplayString(_unref(formatDate)(invoice.date_created)), 1),
            _createElementVNode("td", {
              textContent: _toDisplayString(invoice.description)
            }, null, 8, _hoisted_10),
            _createElementVNode("td", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(formatCurrency)(invoice.sub_total)), 1)
            ]),
            _createElementVNode("td", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(formatCurrency)(invoice.total)), 1)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("tfoot", null, [
        _createElementVNode("tr", null, [
          _cache[0] || (_cache[0] = _createElementVNode("td", {
            colspan: "3",
            class: "empty"
          }, null, -1)),
          _createElementVNode("td", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(formatCurrency)(sub_total())), 1)
          ]),
          _createElementVNode("td", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(formatCurrency)(total())), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})