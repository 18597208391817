import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "balance-line columns" }
const _hoisted_2 = { class: "col onetenth balance-line-options" }

import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { ExpenseLine, expenseLineValidations } from '@/models/expense';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseFormLine',
  props: {
  line: {
    type: Object as PropType<ExpenseLine>,
    required: true,
  },
  isOnly: {
    type: Boolean,
  },
},
  emits: ['update:modelValue', 'add', 'remove'],
  setup(__props, { emit: __emit }) {



const $v = useVuelidate(expenseLineValidations, { line: __props.line });

const emit = __emit;

const update = () => {
  emit('update:modelValue', __props.line);
};

const add = () => {
  emit('add', __props.line);
};

const remove = () => {
  emit('remove', __props.line);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Field, {
      label: "Description",
      placeholder: "Description",
      styling: "col half block",
      onInput: update,
      modelValue: __props.line.description,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.line.description) = $event)),
      validation: _unref($v).line.description,
      type: "text"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "VAT",
      placeholder: "0.21",
      styling: "col onetenth block",
      onInput: update,
      modelValue: __props.line.vat,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.line.vat) = $event)),
      modelModifiers: { number: true },
      validation: _unref($v).line.vat,
      step: "0.01"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "Amount",
      placeholder: "0",
      styling: "col onetenth block",
      readonly: "",
      modelValue: __props.line.vat_amount,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.line.vat_amount) = $event)),
      modelModifiers: { number: true },
      step: "0.01"
    }, null, 8, ["modelValue"]),
    _createVNode(FieldNumber, {
      label: "Subtotal",
      placeholder: "0",
      styling: "col oneeight block",
      onInput: update,
      modelValue: __props.line.total,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((__props.line.total) = $event)),
      modelModifiers: { number: true },
      validation: _unref($v).line.total,
      step: "1.00"
    }, null, 8, ["modelValue", "validation"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: add,
        class: "option add-balance-line-option icononly"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", { class: "icon icon-plus" }, null, -1)
      ])),
      (!__props.isOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            onClick: remove,
            class: "option remove-balance-line-option icononly"
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("span", { class: "icon icon-minus" }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})