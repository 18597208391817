<script lang="ts" setup>
const { language } = defineProps({
  language: { type: String, default: 'nl' },
});
const emit = defineEmits(['set-language']);

const set = (l: string) => {
  emit('set-language', l);
};
</script>
<template>
  <ul class="language-switch">
    <li v-if="language === 'en'">
      <a href="#" @click.prevent="set('nl')">Nederlands</a>
    </li>
    <li v-if="language === 'nl'">
      <a href="#" @click.prevent="set('en')">English</a>
    </li>
  </ul>
</template>
