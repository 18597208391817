<script lang="ts" setup>
import { computed, markRaw, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Project } from '@/models/project';
import Tooltip from '@/components/common/Tooltip.vue';
import { capitalize, formatCurrency, formatDate } from '@/filters/filters';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import ProjectForm from './ProjectForm.vue';
import ProjectInvoices from './ProjectInvoices.vue';
import { Company } from '@/models/company';
import { Actions, Mutations } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import { TimesheetActions } from '@/stores/timesheet.store';

const store = useStore();
const route = useRoute();

const project = computed<Project>(() => store.state.project);
const project_hours = computed<string>(() => store.state.ts.project_hours);
const company = computed<Company>(() => store.state.company);

onMounted(async () => {
  const id = route.params.id;
  await store.dispatch(Actions.GetProject, id);
  await store.dispatch(TimesheetActions.GetProjectHours, id);
  await store.dispatch(Actions.GetCompany, project.value.company_id);
});

onUnmounted(() => {
  store.commit(Mutations.SetProject, null);
});

const info = (): CompoundListItem[] => {
  const list = [
    {
      name: 'Company',
      description: company.value.name,
      targetDescription: `/company/${company.value.id}`,
    },
    {
      name: 'Description',
      description: project.value.description,
    },
    { name: 'Start date', description: formatDate(project.value.date_start) },
    project.value.type
      ? { name: 'Type', description: capitalize(project.value.type) }
      : null,
    project.value.date_end
      ? { name: 'End date', description: formatDate(project.value.date_end) }
      : null,
    project.value.rate
      ? {
          name: 'Hourly rate',
          description: `€ ${formatCurrency(project.value.rate)}`,
        }
      : null,
    { name: 'Total hours', description: project_hours.value },
  ].filter((v) => v !== null);

  return list as CompoundListItem[];
};

const editProject = async (id: number) => {
  await store.dispatch(Actions.GetProject, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};
</script>
<template>
  <section class="project" v-if="project && company">
    <h2>
      <span v-text="project.name"></span>
      <Tooltip text="Edit project" inline>
        <button class="option icononly" @click="editProject(project.id)">
          <span class="icon icon-pencil"></span>
        </button>
      </Tooltip>
    </h2>
    <CompoundList :list="info()" simple />
    <ProjectInvoices :project="project" />
  </section>
</template>
