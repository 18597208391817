import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-result" }

import { PropType } from 'vue';
import { Result } from '@/models/results.model';
import { User } from '@/models/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSearchResult',
  props: {
  result: {
    type: Object as PropType<Result<User>>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `/user/${__props.result.item.id}`
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.result.item.first_name) + " " + _toDisplayString(__props.result.item.last_name), 1)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", null, _toDisplayString(__props.result.item.email), 1)
  ]))
}
}

})