import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "language-switch" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSwitch',
  props: {
  language: { type: String, default: 'nl' },
},
  emits: ['set-language'],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const set = (l: string) => {
  emit('set-language', l);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (__props.language === 'en')
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (set('nl')), ["prevent"]))
          }, "Nederlands")
        ]))
      : _createCommentVNode("", true),
    (__props.language === 'nl')
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (set('en')), ["prevent"]))
          }, "English")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})