import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["readonly", "value", "disabled", "name"]
const _hoisted_3 = {
  key: 0,
  class: "icon icon-checkbox-checked"
}
const _hoisted_4 = {
  key: 1,
  class: "icon icon-checkbox-unchecked"
}
const _hoisted_5 = ["textContent"]

import { getMessage } from '@/core/validation';
import { FieldComposable, fieldProps } from './field.composable';
import Message from '@/components/common/Message.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldSwitch',
  props: {
  ...fieldProps,
  modelValue: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
  `key.ctrl.${String}`,
],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { fieldValue, update, name } = FieldComposable<boolean>(
  __props.modelValue as any,
  emit,
  __props.label,
  __props.prop,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field field-switch", [{ invalid: _ctx.validation.$invalid && _ctx.validation.$dirty }, _ctx.styling]])
  }, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      class: _normalizeClass(__props.type)
    }, [
      _withDirectives(_createElementVNode("input", {
        class: "hidden",
        type: "checkbox",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(fieldValue) ? (fieldValue).value = $event : null)),
        readonly: _ctx.readonly,
        value: __props.modelValue,
        disabled: _ctx.disabled,
        name: _unref(name)
      }, null, 40, _hoisted_2), [
        [_vModelCheckbox, _unref(fieldValue)]
      ]),
      (_unref(fieldValue))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("", true),
      (!_unref(fieldValue))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: "label-text",
        textContent: _toDisplayString(_ctx.label)
      }, null, 8, _hoisted_5)
    ], 2),
    _createVNode(Message, {
      type: "alert",
      styling: _ctx.messageStyling,
      message: _unref(getMessage)(_ctx.validation),
      show: _ctx.validation.$invalid && _ctx.validation.$dirty,
      onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})