<script lang="ts" setup>
import { onMounted, PropType, ref } from 'vue';
import { TimesheetDay } from '@/models/timesheet-day';
import useVuelidate from '@vuelidate/core';
import FieldLong from '@/components/common/FieldLong.vue';

const { data } = defineProps({
  data: {
    type: Object as PropType<TimesheetDay>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const description = ref<string>('');
const $v = useVuelidate({ description: {} }, { description });

onMounted(() => {
  description.value = data.description || '';
});

const close = (evt: Event) => {
  evt.preventDefault();
  emit('close', 'details', description.value);
};
</script>
<template>
  <section class="details">
    <FieldLong
      label="Description"
      v-auto-focus="'select'"
      @enter="close"
      @escape="close"
      :validation="$v.description"
      v-model="description"
    ></FieldLong>
  </section>
</template>
