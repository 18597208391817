import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Menu from '@/components/Menu.vue';
import Logo from '@/components/Logo.vue';
import Footer from '@/components/Footer.vue';
import Version from '@/components/Version.vue';
import Modal from '@/components/common/Modal.vue';
import Drawer from '@/components/common/Drawer.vue';
import Notifications from '@/components/common/Notifications.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DocsApp',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(Logo),
      _createVNode(Menu)
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_RouterView)
    ]),
    _createElementVNode("footer", null, [
      _createVNode(Footer),
      _createVNode(Version)
    ]),
    _createVNode(Drawer),
    _createVNode(Modal),
    _createVNode(Notifications)
  ]))
}
}

})