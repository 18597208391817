<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Actions } from '@/store';
import { TimesheetActions } from '@/stores/timesheet.store';
import { Invoice } from '@/models/invoice';
import { Company } from '@/models/company';
import InvoiceList from '../balance/InvoiceList.vue';

const store = useStore();
const route = useRoute();
const timesheet_invoices = computed<Invoice[]>(
  () => store.state.ts.timesheet_invoices,
);
const companies = computed<Company[]>(() => store.state.companies);

const { month } = defineProps({
  month: {
    type: String,
    required: true,
  },
});

const date = computed(() => {
  return route.params.date ? route.params.date : `${month}-01`;
});

const invoice = computed(() => {
  return `/balance/invoice/new/date/${date.value}/project/${route.params.id}`;
});

const update = () => {
  store.dispatch(Actions.GetCompanies);
  store.dispatch(TimesheetActions.GetTimesheetInvoices, {
    id: route.params.id,
    date: date.value,
  });
};

onMounted(() => {
  update();
});

watch(route, update);
</script>
<template>
  <section class="timesheet-info">
    <h3>Invoices</h3>
    <InvoiceList
      v-if="timesheet_invoices.length"
      :invoices="timesheet_invoices"
      :companies="companies"
      :show-header="false"
    ></InvoiceList>
    <p v-else>No invoices could be found</p>
    <RouterLink :to="invoice" v-slot="{ navigate }" custom>
      <button type="button" class="option wide" @click="navigate">
        <span class="icon icon-file"></span> Create invoice
      </button>
    </RouterLink>
  </section>
</template>
