import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "balance-line columns" }
const _hoisted_2 = { class: "col onetenth balance-line-options" }

import { computed, PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { InvoiceLine, invoiceLineValidations } from '@/models/invoice';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceFormLine',
  props: {
  line: {
    type: Object as PropType<InvoiceLine>,
    required: true,
  },
  isOnly: {
    type: Boolean,
    required: true,
  },
},
  emits: ['update:modelValue', 'add', 'remove'],
  setup(__props, { emit: __emit }) {

const props = __props;

const $v = useVuelidate(invoiceLineValidations, props.line as {});

const total = computed<number>(() => {
  var t = parseFloat(`${props.line.amount}`) * props.line.price;
  props.line.total = t;
  return t;
});

const vatAmount = computed<number>(() => {
  return total.value * props.line.vat;
});

const emit = __emit;

const update = () => {
  emit('update:modelValue', props.line);
};
const add = () => {
  emit('add', props.line);
};
const remove = () => {
  emit('remove', props.line);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Field, {
      label: "Description",
      placeholder: "Description",
      styling: "col third block",
      onInput: update,
      modelValue: __props.line.description,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.line.description) = $event)),
      validation: _unref($v).description,
      type: "text"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "Amount",
      placeholder: "0",
      styling: "col onetenth block",
      onInput: update,
      modelValue: __props.line.amount,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.line.amount) = $event)),
      modelModifiers: { number: true },
      validation: _unref($v).amount,
      step: "1.00"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "Price",
      step: "1.00",
      placeholder: "0",
      styling: "col onetenth block",
      onInput: update,
      modelValue: __props.line.price,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.line.price) = $event)),
      modelModifiers: { number: true },
      validation: _unref($v).price
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "VAT",
      placeholder: "0.21",
      styling: "col onetenth block",
      onInput: update,
      modelValue: __props.line.vat,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((__props.line.vat) = $event)),
      modelModifiers: { number: true },
      validation: _unref($v).vat,
      step: "0.01"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "VAT Amount",
      placeholder: "0",
      readonly: "",
      styling: "col oneeight block",
      onInput: update,
      modelValue: vatAmount.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((vatAmount).value = $event)),
      step: "0.01"
    }, null, 8, ["modelValue"]),
    _createVNode(FieldNumber, {
      label: "Subtotal",
      placeholder: "0",
      readonly: "",
      styling: "col oneeight block",
      onInput: update,
      modelValue: total.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((total).value = $event)),
      step: "0.01"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: add,
        class: "option add-balance-line-option icononly"
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "icon icon-plus" }, null, -1)
      ])),
      (!__props.isOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            onClick: remove,
            class: "option remove-balance-line-option icononly"
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("span", { class: "icon icon-minus" }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})