import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drawer-content company-form" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["textContent"]

import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@/store';
import useVuelidate from '@vuelidate/core';
import { Company, companyValidations } from '@/models/company';
import Field from '@/components/common/Field.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import Loading from '@/components/common/Loading.vue';
import AddressFields from '@/components/common/AddressFields.vue';
import { useLoading } from '../common/loading.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyForm',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const store = useStore();
let company = reactive<Company>({ ...store.state.company });
const isNew = computed<boolean>(() => company.id === -1);
const { loading, setLoading } = useLoading();

const $v = useVuelidate(companyValidations, { company });
const emit = __emit;

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(Actions.StoreCompany, company);

    setLoading(false);
    cancel();
  }
};

const cancel = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_unref(company))
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _withModifiers(submit, ["prevent"]),
          class: _normalizeClass({ submitting: _unref(loading) })
        }, [
          _createElementVNode("h2", {
            textContent: _toDisplayString(isNew.value ? 'Add company' : 'Edit company')
          }, null, 8, _hoisted_2),
          (isNew.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Fill out the details of the company"))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, "Edit the details of the company")),
          _createVNode(Field, {
            label: "Name",
            validation: _unref($v).company.name,
            modelValue: _unref(company).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(company).name) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(Field, {
            label: "Label",
            validation: _unref($v).company.label,
            modelValue: _unref(company).label,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(company).label) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(FieldLong, {
            label: "Description",
            validation: _unref($v).company.description,
            modelValue: _unref(company).description,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(company).description) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(Field, {
            label: "Contact",
            validation: _unref($v).company.contact,
            modelValue: _unref(company).contact,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(company).contact) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(AddressFields, {
            modelValue: _unref(company).address,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(company).address) = $event)),
            validation: _unref($v).company.address,
            loading: _unref(loading)
          }, null, 8, ["modelValue", "validation", "loading"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "confirm",
              type: "submit",
              disabled: _unref(loading)
            }, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)),
              _createElementVNode("span", {
                class: "text",
                textContent: _toDisplayString(isNew.value ? 'Add' : 'Edit')
              }, null, 8, _hoisted_7)
            ], 8, _hoisted_6),
            _createElementVNode("button", {
              class: "cancel",
              type: "button",
              onClick: cancel
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("span", { class: "text" }, "Cancel", -1)
            ])),
            (_unref(loading))
              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ], 34))
      : _createCommentVNode("", true)
  ]))
}
}

})