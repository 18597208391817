import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user"
}

import { computed, onMounted, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Level, User } from '@/models/user';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import ProfileForm from '@/components/user/ProfileForm.vue';
import { Actions } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Profile',
  setup(__props) {

const store = useStore();
const profile = computed<User>(() => store.state.profile);

onMounted(() => {
  store.dispatch(Actions.GetProfile);
});

const showProfileForm = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProfileForm),
    profile: profile.value,
  });
};

const props = (): CompoundListItem[] => {
  return [
    {
      name: 'Name',
      description: `${profile.value.first_name} ${profile.value.last_name}`,
    },
    { name: 'Email', description: profile.value.email },
    { name: 'Phone', description: profile.value.phone },
    {
      name: 'Level',
      description: profile.value.level === Level.Admin ? 'Admin' : 'User',
    },
  ];
};

return (_ctx: any,_cache: any) => {
  return (profile.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h2", null, [
          _cache[1] || (_cache[1] = _createTextVNode(" Profile ")),
          _createVNode(Tooltip, {
            text: "Edit profile",
            inline: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                onClick: _withModifiers(showProfileForm, ["prevent"]),
                type: "button",
                class: "option icononly"
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)
              ]))
            ]),
            _: 1
          })
        ]),
        _createVNode(CompoundList, {
          list: props(),
          simple: ""
        }, null, 8, ["list"])
      ]))
    : _createCommentVNode("", true)
}
}

})