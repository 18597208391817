<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Invoice } from '@/models/invoice';
import { formatCurrency } from '@/filters/filters';
import { Actions } from '@/store';
import { BalanceActions } from '@/stores/balance.store';
import { companyInfo } from '@/core/util/info';
import Field from '../common/Field.vue';
import { format } from 'date-fns';

const store = useStore();
const route = useRoute();

const { data } = defineProps({
  data: {
    type: Object as PropType<{ invoice: Invoice }>,
    required: true,
  },
});

const date = ref<string>(format(new Date(), 'yyyy-MM-dd'));

const confirm = async () => {
  await store.dispatch(BalanceActions.UpdateInvoiceStatus, {
    number: data.invoice.number,
    date: date.value,
  });
  store.dispatch(Actions.ShowNotification, {
    message: 'The invoice status has been updated.',
    type: 'info',
  });
  const type = route.params.type;
  store.dispatch(BalanceActions.GetBalance, { type, date: route.params.date });
  cancel();
};

const emit = defineEmits(['close']);
const cancel = () => {
  emit('close');
};
</script>
<template>
  <section class="modal-content">
    <h2>Update Invoice {{ data.invoice.number }}</h2>
    <p>
      Mark the invoice as <i>paid</i> when
      <strong class="currency">{{ formatCurrency(data.invoice.total) }}</strong>
      has been transferred to the {{ companyInfo.name }} account.
    </p>
    <Field v-model="date" type="date" label="Date" />
    <div class="buttons">
      <button type="button" class="danger" @click="confirm">Paid</button>
      <button type="button" class="option" @click="cancel">Cancel</button>
    </div>
  </section>
</template>
