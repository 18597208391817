import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "company"
}
const _hoisted_2 = ["textContent"]

import { computed, onMounted, markRaw } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Actions } from '@/store';
import { Company } from '@/models/company';
import { Project } from '@/models/project';
import CompanyForm from '@/components/company/CompanyForm.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import { DrawerMutations } from '@/stores/drawer.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Company',
  setup(__props) {

const store = useStore();
const route = useRoute();
const company = computed<Company>(() => store.state.company);
const companyProjects = computed<Project[]>(() => store.state.companyProjects);

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetCompany, id);
  store.dispatch(Actions.GetCompanyProjects, id);
});

const props = (): CompoundListItem[] => {
  let list = [
    { name: 'Description', description: company.value.description },
    { name: 'Contact', description: company.value.contact },
  ];
  if (company.value.label) {
    list.splice(0, 0, { name: 'Name', description: company.value.label });
  }
  if (company.value.address) {
    list = [
      ...list,
      { name: 'Address', description: company.value.address.name },
      {
        name: '',
        description: company.value.address.line_one,
      },
      {
        name: '',
        description: `${company.value.address.zipcode} ${company.value.address.city}`,
      },
      {
        name: '',
        description: company.value.address.country,
      },
    ];
  }
  return list;
};

const editCompany = async (id: number) => {
  await store.dispatch(Actions.GetCompany, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(CompanyForm),
    data: {
      company: company.value,
    },
  });
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (company.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createElementVNode("span", {
            textContent: _toDisplayString(company.value.name)
          }, null, 8, _hoisted_2),
          _createVNode(Tooltip, {
            text: "Edit company",
            inline: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                class: "option icononly",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (editCompany(company.value.id)))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)
              ]))
            ]),
            _: 1
          })
        ]),
        _createVNode(CompoundList, {
          list: props(),
          simple: ""
        }, null, 8, ["list"]),
        _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Projects", -1)),
        _createElementVNode("ol", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companyProjects.value, (project) => {
            return (_openBlock(), _createElementBlock("li", {
              key: project.id,
              class: _normalizeClass({ 'project-finished': project.archived })
            }, [
              _createVNode(_component_RouterLink, {
                to: `/project/${project.id}`,
                textContent: _toDisplayString(project.name)
              }, null, 8, ["to", "textContent"])
            ], 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})