import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "expense-detail" }
const _hoisted_2 = { class: "expense-currency" }
const _hoisted_3 = { class: "expense-currency" }
const _hoisted_4 = { class: "vat-percentage" }
const _hoisted_5 = { class: "expense-currency" }

import { PropType } from 'vue';
import { ExpenseLine } from '@/models/expense';
import { formatCurrency } from '@/filters/filters';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseLine',
  props: {
  line: {
    type: Object as PropType<ExpenseLine>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, _toDisplayString(__props.line.description), 1),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(__props.line.amount, true)), 1)
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(__props.line.vat) + "%", 1),
        _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(__props.line.vat_amount)), 1)
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(__props.line.total)), 1)
      ])
    ])
  ]))
}
}

})