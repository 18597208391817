import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen timesheet-overview"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "options" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "columns" }
const _hoisted_7 = { class: "col half" }
const _hoisted_8 = { class: "small-loader-header" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "project-totals"
}
const _hoisted_12 = { class: "monthtotal" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = { class: "col half" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "project-totals"
}
const _hoisted_17 = { class: "monthtotal" }
const _hoisted_18 = ["textContent"]
const _hoisted_19 = ["textContent"]

import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { TimesheetActions } from '@/stores/timesheet.store';
import Loading from '@/components/common/Loading.vue';
import ProjectOverview from '@/components/timesheet/overview/ProjectOverview.vue';
import {
  TimesheetOverview,
  ProjectOverview as ProjectOverviewData,
} from '../../../models/timesheet';
import { useLoading } from '@/components/common/loading.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimesheetOverview',
  setup(__props) {

const store = useStore();
const overview = computed<TimesheetOverview>(() => store.state.ts.overview);

const previous = computed(() => {
  return `/timesheet/overview/${overview.value.info.previous}`;
});

const next = computed(() => {
  return `/timesheet/overview/${overview.value.info.next}`;
});

const day_by_day = computed(() => {
  return `/timesheet/overview/day-by-day/${overview.value.info.current}`;
});

const monthly = computed(() => {
  return overview.value.month_data
    .slice(0)
    .sort((a: ProjectOverviewData, b: ProjectOverviewData) =>
      a.name > b.name ? 1 : -1,
    );
});

const yearly = computed(() => {
  return overview.value.year_data
    .slice(0)
    .sort((a: ProjectOverviewData, b: ProjectOverviewData) =>
      a.name > b.name ? 1 : -1,
    );
});

const { loading, setLoading } = useLoading();
const route = useRoute();

const load = async () => {
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheetOverview, { date });
  setLoading(false);
};

onMounted(async () => {
  await load();
});

watch(route, async () => await load());

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (overview.value && overview.value.info)
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Monthly Overview", -1)),
        (!overview.value)
          ? (_openBlock(), _createBlock(Loading, { key: 0 }))
          : (_openBlock(), _createElementBlock("section", _hoisted_2, [
              _createElementVNode("ul", _hoisted_3, [
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: previous.value }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon icon-caret-left" }, null, -1)),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(overview.value.info.previous_name)
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: next.value }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon icon-caret-right" }, null, -1)),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(overview.value.info.next_name)
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_RouterLink, { to: day_by_day.value }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("span", { class: "icon icon-archive" }, null, -1),
                      _createElementVNode("span", null, "See day by day", -1)
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h3", _hoisted_8, [
                    _createElementVNode("span", {
                      textContent: _toDisplayString(overview.value.info.month_name)
                    }, null, 8, _hoisted_9),
                    (_unref(loading))
                      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  (overview.value.month_data.length === 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, " There are no hours for this month. "))
                    : (_openBlock(), _createElementBlock("table", _hoisted_11, [
                        _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Project Name"),
                            _createElementVNode("th", { class: "total" }, "Total")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthly.value, (project) => {
                            return (_openBlock(), _createBlock(ProjectOverview, {
                              key: project.id,
                              project: project
                            }, null, 8, ["project"]))
                          }), 128)),
                          _createElementVNode("tr", _hoisted_12, [
                            _cache[3] || (_cache[3] = _createElementVNode("th", null, [
                              _createElementVNode("strong", null, "Total")
                            ], -1)),
                            _createElementVNode("th", {
                              class: "hours",
                              textContent: _toDisplayString(overview.value.info.month_total)
                            }, null, 8, _hoisted_13)
                          ])
                        ])
                      ]))
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("h3", null, _toDisplayString(overview.value.info.year) + " Totals", 1),
                  (overview.value.year_data.length === 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, " There are no hours for this year. "))
                    : (_openBlock(), _createElementBlock("table", _hoisted_16, [
                        _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Project Name"),
                            _createElementVNode("th", { class: "total" }, "Total")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(yearly.value, (project) => {
                            return (_openBlock(), _createBlock(ProjectOverview, {
                              key: project.id,
                              project: project
                            }, null, 8, ["project"]))
                          }), 128)),
                          _createElementVNode("tr", _hoisted_17, [
                            _cache[5] || (_cache[5] = _createElementVNode("th", null, [
                              _createElementVNode("strong", null, "Direct total")
                            ], -1)),
                            _createElementVNode("th", {
                              class: "hours",
                              textContent: _toDisplayString(overview.value.info.year_direct_total)
                            }, null, 8, _hoisted_18)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[6] || (_cache[6] = _createElementVNode("th", null, [
                              _createElementVNode("strong", null, "Total")
                            ], -1)),
                            _createElementVNode("th", {
                              class: "hours",
                              textContent: _toDisplayString(overview.value.info.year_total)
                            }, null, 8, _hoisted_19)
                          ])
                        ])
                      ]))
                ])
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}
}

})