<script lang="ts" setup>
import { computed, PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { InvoiceLine, invoiceLineValidations } from '@/models/invoice';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';

const props = defineProps({
  line: {
    type: Object as PropType<InvoiceLine>,
    required: true,
  },
  isOnly: {
    type: Boolean,
    required: true,
  },
});

const $v = useVuelidate(invoiceLineValidations, props.line as {});

const total = computed<number>(() => {
  var t = parseFloat(`${props.line.amount}`) * props.line.price;
  props.line.total = t;
  return t;
});

const vatAmount = computed<number>(() => {
  return total.value * props.line.vat;
});

const emit = defineEmits(['update:modelValue', 'add', 'remove']);

const update = () => {
  emit('update:modelValue', props.line);
};
const add = () => {
  emit('add', props.line);
};
const remove = () => {
  emit('remove', props.line);
};
</script>

<template>
  <div class="balance-line columns">
    <Field
      label="Description"
      placeholder="Description"
      styling="col third block"
      @input="update"
      v-model="line.description"
      :validation="$v.description"
      type="text"
    ></Field>
    <FieldNumber
      label="Amount"
      placeholder="0"
      styling="col onetenth block"
      @input="update"
      v-model.number="line.amount"
      :validation="$v.amount"
      step="1.00"
    ></FieldNumber>
    <FieldNumber
      label="Price"
      step="1.00"
      placeholder="0"
      styling="col onetenth block"
      @input="update"
      v-model.number="line.price"
      :validation="$v.price"
    ></FieldNumber>
    <FieldNumber
      label="VAT"
      placeholder="0.21"
      styling="col onetenth block"
      @input="update"
      v-model.number="line.vat"
      :validation="$v.vat"
      step="0.01"
    ></FieldNumber>
    <FieldNumber
      label="VAT Amount"
      placeholder="0"
      readonly
      styling="col oneeight block"
      @input="update"
      v-model="vatAmount"
      step="0.01"
    ></FieldNumber>
    <FieldNumber
      label="Subtotal"
      placeholder="0"
      readonly
      styling="col oneeight block"
      @input="update"
      v-model="total"
      step="0.01"
    ></FieldNumber>
    <div class="col onetenth balance-line-options">
      <button
        type="button"
        @click="add"
        class="option add-balance-line-option icononly"
      >
        <span class="icon icon-plus"></span>
      </button>
      <button
        type="button"
        v-if="!isOnly"
        @click="remove"
        class="option remove-balance-line-option icononly"
      >
        <span class="icon icon-minus"></span>
      </button>
    </div>
  </div>
</template>
