<script lang="ts" setup>
import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { ExpenseLine, expenseLineValidations } from '@/models/expense';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';

const { line } = defineProps({
  line: {
    type: Object as PropType<ExpenseLine>,
    required: true,
  },
  isOnly: {
    type: Boolean,
  },
});

const $v = useVuelidate(expenseLineValidations, { line });

const emit = defineEmits(['update:modelValue', 'add', 'remove']);

const update = () => {
  emit('update:modelValue', line);
};

const add = () => {
  emit('add', line);
};

const remove = () => {
  emit('remove', line);
};
</script>
<template>
  <div class="balance-line columns">
    <Field
      label="Description"
      placeholder="Description"
      styling="col half block"
      @input="update"
      v-model="line.description"
      :validation="$v.line.description"
      type="text"
    ></Field>
    <FieldNumber
      label="VAT"
      placeholder="0.21"
      styling="col onetenth block"
      @input="update"
      v-model.number="line.vat"
      :validation="$v.line.vat"
      step="0.01"
    ></FieldNumber>
    <FieldNumber
      label="Amount"
      placeholder="0"
      styling="col onetenth block"
      readonly
      v-model.number="line.vat_amount"
      step="0.01"
    ></FieldNumber>
    <FieldNumber
      label="Subtotal"
      placeholder="0"
      styling="col oneeight block"
      @input="update"
      v-model.number="line.total"
      :validation="$v.line.total"
      step="1.00"
    ></FieldNumber>
    <div class="col onetenth balance-line-options">
      <button
        type="button"
        @click="add"
        class="option add-balance-line-option icononly"
      >
        <span class="icon icon-plus"></span>
      </button>
      <button
        type="button"
        v-if="!isOnly"
        @click="remove"
        class="option remove-balance-line-option icononly"
      >
        <span class="icon icon-minus"></span>
      </button>
    </div>
  </div>
</template>
