import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page invoice"
}
const _hoisted_2 = {
  class: "invoice",
  "data-name": "invoice"
}
const _hoisted_3 = { class: "invoiced-company" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "invoicing-company" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = { class: "invoice-information" }
const _hoisted_19 = ["textContent"]
const _hoisted_20 = ["textContent"]
const _hoisted_21 = ["textContent"]
const _hoisted_22 = { class: "invoice-information-data" }
const _hoisted_23 = ["textContent"]
const _hoisted_24 = { class: "invoice-information-data" }
const _hoisted_25 = { class: "invoice-details" }
const _hoisted_26 = ["textContent"]
const _hoisted_27 = ["textContent"]
const _hoisted_28 = ["textContent"]
const _hoisted_29 = ["textContent"]
const _hoisted_30 = { class: "invoice-totals" }
const _hoisted_31 = ["textContent"]
const _hoisted_32 = { class: "invoice-currency invoice-total-amount" }
const _hoisted_33 = ["textContent"]
const _hoisted_34 = { class: "invoice-currency invoice-total-amount" }
const _hoisted_35 = ["textContent"]
const _hoisted_36 = { class: "invoice-currency invoice-total-amount" }
const _hoisted_37 = { class: "payment-instructions" }
const _hoisted_38 = ["textContent"]
const _hoisted_39 = ["textContent"]
const _hoisted_40 = { class: "payment-information" }
const _hoisted_41 = ["textContent"]
const _hoisted_42 = ["textContent"]
const _hoisted_43 = ["textContent"]

import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { startOfYear, isAfter } from 'date-fns';
import { useI18n } from '@/core/i18n/i18n.composable';
import { BalanceActions } from '@/stores/balance.store';
import { companyInfo, bankInfo } from '@/core/util/info';
import { formatCurrency } from '@/filters/filters';
import { InvoiceInfo } from '@/services/balance.service';
import { useLoading } from '../common/loading.composable';
import InvoiceLine from '@/components/pages/InvoiceLine.vue';
import { I18NLanguage } from '@/core/i18n';

const term: string = '30 dagen';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicePage',
  setup(__props) {

const store = useStore();
const route = useRoute();
const invoiceInfo = computed<InvoiceInfo | null>(
  () => store.state.bs.invoiceInfo,
);

const { i18nData, language, setLanguage } = useI18n();
const { loading, setLoading } = useLoading();

const timesheetUrl = computed(() => {
  return invoiceInfo.value
    ? `/timesheet/project/${invoiceInfo.value.project.id}/${invoiceInfo.value.invoice.date_reference}`
    : '';
});

const before2020 = (): boolean => {
  if (!invoiceInfo.value) {
    return false;
  }
  const date = new Date(invoiceInfo.value.invoice.date_created);
  const startOf2020 = startOfYear(2020);
  return isAfter(startOf2020, date);
};

const load = async () => {
  const number = route.params.number;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetInvoice, { number, date });
  setLoading(false);
  setLanguage(invoiceInfo.value?.invoice.language as I18NLanguage);
};

onMounted(() => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_raise_ordinal = _resolveDirective("raise-ordinal")!

  return (invoiceInfo.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("div", {
              textContent: _toDisplayString(invoiceInfo.value.company.address.name)
            }, null, 8, _hoisted_4),
            _createElementVNode("div", {
              textContent: _toDisplayString(`${_unref(i18nData).ttao} ${invoiceInfo.value.company.contact}`)
            }, null, 8, _hoisted_5),
            _createElementVNode("div", {
              textContent: _toDisplayString(invoiceInfo.value.company.address.line_one)
            }, null, 8, _hoisted_6),
            (invoiceInfo.value.company.address.line_two)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  textContent: _toDisplayString(invoiceInfo.value.company.address.line_two)
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              textContent: _toDisplayString(
            `${invoiceInfo.value.company.address.zipcode} ${invoiceInfo.value.company.address.city}`
          )
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("section", _hoisted_9, [
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).name)
            }, null, 8, _hoisted_10),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).address.line)
            }, null, 8, _hoisted_11),
            _createElementVNode("div", {
              textContent: _toDisplayString(`${_unref(companyInfo).address.zipcode} ${_unref(companyInfo).address.city}`)
            }, null, 8, _hoisted_12),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).website)
            }, null, 8, _hoisted_13),
            _createElementVNode("div", {
              textContent: _toDisplayString(_unref(companyInfo).email)
            }, null, 8, _hoisted_14),
            _createElementVNode("div", {
              textContent: _toDisplayString(`${_unref(i18nData).COC} ${_unref(companyInfo).KvK}`)
            }, null, 8, _hoisted_15),
            (before2020())
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  textContent: _toDisplayString(`${_unref(i18nData).VAT} ${_unref(companyInfo).BTW}`)
                }, null, 8, _hoisted_16))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  textContent: _toDisplayString(`${_unref(i18nData).VATID} ${_unref(companyInfo).BTWID}`)
                }, null, 8, _hoisted_17))
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "invoice-information-label",
                textContent: _toDisplayString(_unref(i18nData).invoiceNumber)
              }, null, 8, _hoisted_19),
              _createElementVNode("div", {
                class: "invoice-information-data",
                textContent: _toDisplayString(invoiceInfo.value.invoice.number)
              }, null, 8, _hoisted_20)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "invoice-information-label",
                textContent: _toDisplayString(_unref(i18nData).invoiceDate)
              }, null, 8, _hoisted_21),
              _createElementVNode("div", _hoisted_22, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(invoiceInfo.value.invoice.date_created_f), 1)
                ])), [
                  [_directive_raise_ordinal]
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "invoice-information-label",
                textContent: _toDisplayString(_unref(i18nData).invoicePeriod)
              }, null, 8, _hoisted_23),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_RouterLink, { to: timesheetUrl.value }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(invoiceInfo.value.invoice.subject), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("h1", null, _toDisplayString(_unref(i18nData).invoice), 1),
            _createElementVNode("table", null, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", {
                    class: "invoice-table-header",
                    width: "60%",
                    textContent: _toDisplayString(_unref(i18nData).description)
                  }, null, 8, _hoisted_26),
                  _createElementVNode("th", {
                    class: "invoice-table-header",
                    textContent: _toDisplayString(
                  invoiceInfo.value.invoice.type === 'hours'
                    ? _unref(i18nData).hours
                    : _unref(i18nData).items
                )
                  }, null, 8, _hoisted_27),
                  _createElementVNode("th", {
                    class: "invoice-table-header invoice-currency",
                    textContent: _toDisplayString(
                  invoiceInfo.value.invoice.type === 'hours'
                    ? _unref(i18nData).rate
                    : _unref(i18nData).tarif
                )
                  }, null, 8, _hoisted_28),
                  _createElementVNode("th", {
                    class: "invoice-table-header invoice-currency",
                    textContent: _toDisplayString(_unref(i18nData).subTotal)
                  }, null, 8, _hoisted_29)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invoiceInfo.value.invoice.lines, (line) => {
                  return (_openBlock(), _createBlock(InvoiceLine, {
                    key: line.name,
                    line: line
                  }, null, 8, ["line"]))
                }), 128))
              ]),
              _createElementVNode("tfoot", _hoisted_30, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", {
                    class: "total-description invoice-table-header",
                    colspan: "3",
                    textContent: _toDisplayString(_unref(i18nData).totalExclVAT)
                  }, null, 8, _hoisted_31),
                  _createElementVNode("td", _hoisted_32, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(invoiceInfo.value.invoice.sub_total)), 1)
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", {
                    class: "total-description invoice-table-header",
                    colspan: "3",
                    textContent: _toDisplayString(_unref(i18nData).VAT)
                  }, null, 8, _hoisted_33),
                  _createElementVNode("td", _hoisted_34, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(invoiceInfo.value.invoice.vat_total)), 1)
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", {
                    class: "total-description invoice-table-header",
                    colspan: "3",
                    textContent: _toDisplayString(_unref(i18nData).TotalInclVAT)
                  }, null, 8, _hoisted_35),
                  _createElementVNode("td", _hoisted_36, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(invoiceInfo.value.invoice.total)), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("h2", {
              textContent: _toDisplayString(_unref(i18nData).paymentInstruction)
            }, null, 8, _hoisted_38),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_unref(i18nData).paymentInstruction_part1) + " " + _toDisplayString(term) + " " + _toDisplayString(_unref(i18nData).paymentInstruction_part2) + " ", 1),
              _createElementVNode("strong", {
                textContent: _toDisplayString(
              `${_unref(i18nData).invoiceNumber_lc} ${invoiceInfo.value.invoice.number}`
            )
              }, null, 8, _hoisted_39),
              _createTextVNode(" " + _toDisplayString(_unref(i18nData).paymentInstruction_part3), 1)
            ])
          ]),
          _createElementVNode("table", _hoisted_40, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  textContent: _toDisplayString(_unref(i18nData).bankAccount)
                }, null, 8, _hoisted_41),
                _createElementVNode("th", {
                  textContent: _toDisplayString(_unref(i18nData).inNameOf)
                }, null, 8, _hoisted_42)
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  textContent: _toDisplayString(`${_unref(bankInfo).name} ${_unref(i18nData).account} ${_unref(bankInfo).IBAN}`)
                }, null, 8, _hoisted_43),
                _cache[0] || (_cache[0] = _createElementVNode("td", null, "Front Crafter, Leiden", -1))
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})