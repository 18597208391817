<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import { TimeUtil } from '@/core/util/time.util';

interface Hours {
  time: string;
}

let hours = reactive<Array<Hours>>([{ time: '' }]);

const rules = ref<ValidationArgs>({
  hours: {
    time: {
      required,
    },
  },
});

const value = computed((): string => {
  return TimeUtil.minutesToTime(
    hours.reduce((acc: number, v: { time: string }) => {
      return acc + (TimeUtil.timeToMinutes(v.time) || 0);
    }, 0),
  );
});

const $v = useVuelidate<ValidationArgs, { hours: Hours[] }>(rules, { hours });

const emit = defineEmits(['close']);

const addHours = () => {
  hours = [...hours, { time: '' }];
};

const removeHours = (index: number) => {
  hours.splice(index, 1);
};

const submit = (evt: Event) => {
  if (!$v.value.$invalid) {
    evt.preventDefault();
    emit('close', 'calculator', value.value);
  }
};

const close = () => {
  emit('close', 'calculator');
};
</script>
<template>
  <section class="times">
    <Field
      v-for="(t, index) in hours"
      :key="index"
      :label="`Hours ${index}`"
      v-auto-focus="'focus'"
      @key.ctrl.68.exact="addHours"
      @key.ctrl.88.exact="removeHours(index)"
      @enter="submit"
      @escape="close"
      placeholder="00:00"
      v-model="t.time"
    ></Field>
  </section>
</template>
