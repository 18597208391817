import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "project-list" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 3,
  class: "buttons"
}

import { computed, onMounted, onUnmounted, ref, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import { Project } from '@/models/project';
import { ProjectInfo } from '@/models/project-info';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import ProjectForm from '@/components/project/ProjectForm.vue';
import CompoundList, {
  CompoundListItem,
} from '@/components/common/CompoundList.vue';
import { DrawerMutations } from '@/stores/drawer.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectList',
  setup(__props) {

const store = useStore();

const project = computed<Project | null>(() => store.state.project);
const filter = computed<string>(() => store.state.filter);
const filteredProjects = computed<ProjectInfo[]>(
  () => store.getters.filteredProjects,
);

const showArchived = ref<boolean>(false);

watch(filter, (v: string) => {
  store.commit(Mutations.UpdateFilter, v);
});

const toggleArchived = () => {
  showArchived.value = !showArchived.value;
};

const list = computed((): CompoundListItem[] => {
  return (
    showArchived.value
      ? filteredProjects.value
      : filteredProjects.value.filter((p: ProjectInfo) => !p.archived)
  ).map((p: ProjectInfo): CompoundListItem => {
    return {
      label: p.company_name,
      target: `/timesheet/project/${p.id}`,
      name: p.name,
      description: p.description,
      styling: p.ended ? 'project-finished' : null,
    };
  });
});

onMounted(() => {
  store.dispatch(Actions.GetProjects);
});

onUnmounted(() => {
  store.commit(Mutations.UpdateFilter, '');
});

const input = (v: string) => {
  store.commit(Mutations.UpdateFilter, v);
};

const newProject = async () => {
  await store.dispatch(Actions.NewProject);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(ProjectForm),
    data: {
      project,
    },
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Projects", -1)),
    (!list.value.length && !filter.value)
      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
      : (!list.value.length && filter.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "No results, adjust the filter"))
        : (_openBlock(), _createBlock(CompoundList, {
            key: 2,
            list: list.value
          }, null, 8, ["list"])),
    (list.value.length || filter.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Field, {
            label: "Filter",
            "hide-label": "",
            styling: "inline",
            onInput: input
          }),
          _createElementVNode("button", {
            type: "button",
            class: "option",
            onClick: toggleArchived
          }, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon icon-archive" }, null, -1)),
            _createTextVNode(" Show " + _toDisplayString(showArchived.value ? 'Active' : 'All'), 1)
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "option",
            onClick: newProject
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "icon icon-pencil" }, null, -1),
            _createTextVNode(" Add project ")
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})