import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "admin-page" }
const _hoisted_2 = { class: "small-loader-header" }
const _hoisted_3 = {
  key: 0,
  class: "options"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = ["disabled"]

import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { HolidayName, Holidays } from '@/models/holidays';
import { useLoading } from '@/components/common/loading.composable';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import { Actions } from '@/store';

interface HolidayOption {
  name: HolidayName;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Holidays',
  setup(__props) {

const store = useStore();
const route = useRoute();
const holidays = computed<Holidays>(() => store.state.holidays);
const { loading, setLoading } = useLoading();

const year: number = new Date().getFullYear();
const fields: HolidayOption[] = [
  { name: HolidayName.newyearsday, label: 'Nieuwjaarsdag' },
  { name: HolidayName.goodfriday, label: 'Goede vrijdag' },
  { name: HolidayName.firsteasterday, label: 'Eerste Paasdag' },
  { name: HolidayName.secondeasterday, label: 'Tweede Paasdag' },
  { name: HolidayName.kingsday, label: 'Koningsdag' },
  { name: HolidayName.liberationday, label: 'Bevrijdingsdag' },
  { name: HolidayName.ascensionday, label: 'Hemelvaart' },
  { name: HolidayName.firstpentacostday, label: 'Eerste Pinksterdag' },
  { name: HolidayName.secondpentacostday, label: 'Tweede Pinksterdag' },
  { name: HolidayName.firstchristmasday, label: 'Eerste Kerstdag' },
  { name: HolidayName.secondchristmasday, label: 'Tweede Kerstdag' },
];

const title = computed(() =>
  holidays.value ? `Holidays ${holidays.value.year}` : 'Holidays',
);

const previous_year = computed(() => holidays.value.year - 1);
const previous = computed(() => `/system/holidays/${previous_year.value}`);

const next_year = computed(() => holidays.value.year + 1);
const next = computed(() => `/system/holidays/${next_year.value}`);

const load = async () => {
  const y = route.params.year || year;
  setLoading(true);
  await store.dispatch(Actions.GetHolidays, y);
  holidays.value.update(fields);
  setLoading(false);
};

const submit = async () => {
  setLoading(true);
  await store.dispatch(Actions.StoreHolidays, holidays.value.data());
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createTextVNode(_toDisplayString(title.value) + " ", 1),
      (_unref(loading))
        ? (_openBlock(), _createBlock(Loading, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (holidays.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: previous.value }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon icon-caret-left" }, null, -1)),
                _createElementVNode("span", {
                  textContent: _toDisplayString(previous_year.value)
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: next.value }, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon icon-caret-right" }, null, -1)),
                _createElementVNode("span", {
                  textContent: _toDisplayString(next_year.value)
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "Add, edit and remove holidays to be visible on the timesheet.", -1)),
    (holidays.value)
      ? (_openBlock(), _createElementBlock("form", {
          key: 1,
          onSubmit: _withModifiers(submit, ["prevent"])
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(fields, (item) => {
            return _createElementVNode("div", {
              class: "",
              key: item.name
            }, [
              _createVNode(Field, {
                label: item.label,
                styling: "halved",
                modelValue: holidays.value[item.name].date,
                "onUpdate:modelValue": ($event: any) => ((holidays.value[item.name].date) = $event),
                type: "date"
              }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
            ])
          }), 64)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "confirm",
              type: "submit",
              disabled: _unref(loading)
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { class: "icon icon-ok" }, null, -1),
              _createElementVNode("span", { class: "text" }, "Save", -1)
            ]), 8, _hoisted_7),
            (_unref(loading))
              ? (_openBlock(), _createBlock(Loading, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ], 32))
      : _createCommentVNode("", true)
  ]))
}
}

})