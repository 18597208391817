<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { User } from '@/models/user';
import Search from '@/components/search/Search.vue';

import { Actions } from '@/store';

const store = useStore();
const router = useRouter();

const auth = computed(() => store.state.auth);
const profile = computed<User>(() => store.state.profile);

const name = computed<string>(
  () => `${profile.value.first_name} ${profile.value.last_name}`,
);

const formatDate = (date: string): string => {
  const d = new Date(date);
  return `${format(d, 'dd-MM-yyyy')} at ${format(d, 'HH:mm')}`;
};

onMounted(() => {
  store.dispatch(Actions.GetProfile);
});

const logout = async () => {
  await store.dispatch(Actions.Logout);
  router.push('/login');
};
</script>
<template>
  <div class="footer" v-if="profile && auth && auth.isUser">
    <Search />
    <RouterLink to="/profile">{{ name }}</RouterLink> - Last active:
    <span>{{ formatDate(profile.last_active) }}</span>
    -
    <RouterLink to="/system" exact>System</RouterLink>-
    <a href="#" @click.prevent="logout">Log out</a>
  </div>
</template>
