<script lang="ts" setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Actions } from '@/store';
import useVuelidate from '@vuelidate/core';
import { User, userValidations } from '@/models/user';
import { useLoading } from '@/components/common/loading.composable';
import Field from '@/components/common/Field.vue';
import UserFields from '@/components/user/UserFields.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const { loading, setLoading } = useLoading();

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetUser, id);
});
let user = reactive<User>(store.state.user);
const $v = useVuelidate(userValidations, user);

const submit = () => {
  setLoading(true);
  return false;
};

const cancel = () => {
  router.push(`/user/${route.params.id}`);
};
</script>
<template>
  <section class="user-form">
    <form @submit.prevent="submit" :class="{ submitting: loading }" v-if="user">
      <h2>Edit user</h2>
      <p>Edit the details of the user</p>
      <Field label="Email" :validation="$v.email" v-model="user.email"></Field>
      <UserFields
        v-model="user"
        :validation="$v"
        :loading="loading"
      ></UserFields>
      <div class="buttons">
        <button class="confirm" type="submit" :disabled="loading">
          <span class="icon icon-pencil"></span>
          <span class="text">Edit</span>
        </button>
        <button class="cancel" type="button" @click="cancel">
          <span class="text">Cancel</span>
        </button>
      </div>
    </form>
  </section>
</template>
