import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu"
}

import { computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  setup(__props) {

const store = useStore();
const auth = computed(() => store.state.auth);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", null, [
    (auth.value && auth.value.isUser)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: "/timesheet" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Timesheet ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: "/company" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Companies ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: "/timesheet/overview" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Overview ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_RouterLink, { to: "/balance" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Balance ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})