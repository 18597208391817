import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-detail" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "invoice-hours" }
const _hoisted_4 = { class: "invoice-currency" }
const _hoisted_5 = { class: "invoice-currency" }

import { PropType } from 'vue';
import { InvoiceLine } from '@/models/invoice';
import { formatCurrency, formatHours } from '@/filters/filters';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceLine',
  props: {
  line: {
    type: Object as PropType<InvoiceLine>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", {
      textContent: _toDisplayString(__props.line.description)
    }, null, 8, _hoisted_2),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_unref(formatHours)(__props.line.amount)), 1),
    _createElementVNode("td", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(__props.line.price)), 1)
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createElementVNode("span", null, _toDisplayString(_unref(formatCurrency)(__props.line.total)), 1)
    ])
  ]))
}
}

})