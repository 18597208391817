import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "section tax-table" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "align-right" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "align-right" }
const _hoisted_12 = { class: "currency" }
const _hoisted_13 = { class: "align-right" }
const _hoisted_14 = { class: "align-right" }
const _hoisted_15 = ["textContent"]
const _hoisted_16 = { class: "align-right" }
const _hoisted_17 = { class: "currency" }
const _hoisted_18 = { class: "align-right" }
const _hoisted_19 = { class: "align-right" }
const _hoisted_20 = { class: "currency" }

import { PropType } from 'vue';
import { I18NData } from '@/core/i18n';
import { Expense } from '@/models/expense';
import { formatCurrency, formatDate, formatVAT } from '@/filters/filters';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxExpenseList',
  props: {
  expenses: {
    type: Array as PropType<Expense[]>,
    required: true,
  },
  i18n: {
    type: Object as PropType<I18NData>,
    default: true,
  },
},
  setup(__props) {



const total = () => {
  return __props.expenses.reduce((total, expense) => {
    total += expense.total;
    return total;
  }, 0);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", {
      textContent: _toDisplayString(__props.i18n.expenseSpecification)
    }, null, 8, _hoisted_2),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            width: "12%",
            textContent: _toDisplayString(__props.i18n.number)
          }, null, 8, _hoisted_3),
          _createElementVNode("th", {
            class: "align-right",
            width: "15%",
            textContent: _toDisplayString(__props.i18n.date)
          }, null, 8, _hoisted_4),
          _createElementVNode("th", {
            textContent: _toDisplayString(__props.i18n.description)
          }, null, 8, _hoisted_5),
          _createElementVNode("th", {
            class: "align-right",
            width: "12%",
            textContent: _toDisplayString(__props.i18n.amountCost)
          }, null, 8, _hoisted_6),
          _createElementVNode("th", {
            class: "align-right",
            width: "10%",
            textContent: _toDisplayString(__props.i18n.VATPercentage)
          }, null, 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.expenses, (expense) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: expense.number
          }, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                textContent: _toDisplayString(expense.number)
              }, null, 8, _hoisted_8),
              _createElementVNode("td", _hoisted_9, _toDisplayString(_unref(formatDate)(expense.date_created)), 1),
              _createElementVNode("td", {
                textContent: _toDisplayString(expense.description)
              }, null, 8, _hoisted_10),
              _createElementVNode("td", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(formatCurrency)(expense.total)), 1)
              ]),
              _createElementVNode("td", _hoisted_13, _toDisplayString(_unref(formatVAT)(expense.vat)), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expense.lines.slice(1), (line) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: line.description
              }, [
                _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", _hoisted_14, _toDisplayString(_unref(formatDate)(expense.date_created)), 1),
                _createElementVNode("td", {
                  textContent: _toDisplayString(line.description)
                }, null, 8, _hoisted_15),
                _createElementVNode("td", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(formatCurrency)(line.total)), 1)
                ]),
                _createElementVNode("td", _hoisted_18, _toDisplayString(_unref(formatVAT)(line.vat)), 1)
              ]))
            }), 128))
          ], 64))
        }), 128))
      ]),
      _createElementVNode("tfoot", null, [
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("td", {
            colspan: "3",
            class: "empty"
          }, null, -1)),
          _createElementVNode("td", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(formatCurrency)(total(), true)), 1)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("td", { class: "empty" }, null, -1))
        ])
      ])
    ])
  ]))
}
}

})