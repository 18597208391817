import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, isRef as _isRef, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "user-form" }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = ["disabled"]

import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Actions } from '@/store';
import useVuelidate from '@vuelidate/core';
import { User, userValidations } from '@/models/user';
import { useLoading } from '@/components/common/loading.composable';
import Field from '@/components/common/Field.vue';
import UserFields from '@/components/user/UserFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserForm',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();

const { loading, setLoading } = useLoading();

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetUser, id);
});
let user = reactive<User>(store.state.user);
const $v = useVuelidate(userValidations, user);

const submit = () => {
  setLoading(true);
  return false;
};

const cancel = () => {
  router.push(`/user/${route.params.id}`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_unref(user))
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _withModifiers(submit, ["prevent"]),
          class: _normalizeClass({ submitting: _unref(loading) })
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Edit user", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "Edit the details of the user", -1)),
          _createVNode(Field, {
            label: "Email",
            validation: _unref($v).email,
            modelValue: _unref(user).email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(user).email) = $event))
          }, null, 8, ["validation", "modelValue"]),
          _createVNode(UserFields, {
            modelValue: _unref(user),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(user) ? (user).value = $event : user = $event)),
            validation: _unref($v),
            loading: _unref(loading)
          }, null, 8, ["modelValue", "validation", "loading"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "confirm",
              type: "submit",
              disabled: _unref(loading)
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { class: "icon icon-pencil" }, null, -1),
              _createElementVNode("span", { class: "text" }, "Edit", -1)
            ]), 8, _hoisted_3),
            _createElementVNode("button", {
              class: "cancel",
              type: "button",
              onClick: cancel
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "text" }, "Cancel", -1)
            ]))
          ])
        ], 34))
      : _createCommentVNode("", true)
  ]))
}
}

})