<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from '@/core/i18n/i18n.composable';
import ExpenseLine from '@/components/pages/ExpenseLine.vue';
import Loading from '@/components/common/Loading.vue';
import { ExpenseInfo } from '@/services/balance.service';
import { useRoute } from 'vue-router';
import { formatCurrency, upper } from '@/filters/filters';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';

const store = useStore();
const route = useRoute();
const expenseInfo = computed<ExpenseInfo | null>(
  () => store.state.bs.expenseInfo,
);

const { i18nData } = useI18n();
const { loading, setLoading } = useLoading();

const load = async () => {
  const number = route.params.number;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetExpense, { number, date });
  setLoading(false);
};

const fileType = computed(() => {
  const f = expenseInfo.value ? expenseInfo.value.reference.filename : '';
  return f.substring(f.lastIndexOf('.') + 1);
});

const filePath = computed(() => {
  return expenseInfo.value
    ? `/${expenseInfo.value.reference.path}${expenseInfo.value.reference.filename}`
    : '';
});

onMounted(() => {
  load();
});
</script>
<template>
  <section class="page expense" v-if="expenseInfo">
    <div
      class="expense-info expense-details"
      data-expense-id="163"
      data-name="expense-info"
    >
      <Loading v-if="loading" />
      <h2>{{ i18nData.expense }} - {{ expenseInfo.expense.number }}</h2>
      <p class="created-date">
        <span v-raise-ordinal>{{ expenseInfo.expense.date_created_f }}</span>
      </p>
      <div class="expense-info" data-name="references">
        <ul class="compound expense-reference-list">
          <li>
            <div class="primary-label">
              <span v-raise-ordinal>{{
                expenseInfo.reference.date_received_f
              }}</span>
            </div>
            <div class="title">
              <a
                :href="filePath"
                target="_new"
                v-text="expenseInfo.reference.summary"
              ></a>
            </div>
            <div class="tags">
              <div class="tag">
                <span :class="`icon icon-file-${fileType}`" />
                {{ upper(fileType) }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <table>
        <thead>
          <tr>
            <th v-text="i18nData.description"></th>
            <th
              class="expense-currency"
              width="17%"
              v-text="i18nData.amount"
            ></th>
            <th class="expense-currency" width="17%" v-text="i18nData.VAT"></th>
            <th
              class="expense-currency"
              width="17%"
              v-text="i18nData.total"
            ></th>
          </tr>
        </thead>
        <tbody>
          <ExpenseLine
            v-for="line in expenseInfo.expense.lines"
            :key="line.name"
            :line="line"
          ></ExpenseLine>
        </tbody>
        <tfoot class="expense-totals">
          <tr>
            <td class="total-description" v-text="i18nData.subTotal"></td>
            <td class="expense-total-amount">
              <div class="expense-currency">
                <span>{{
                  formatCurrency(expenseInfo.expense.sub_total, true)
                }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td />
            <td class="total-description" v-text="i18nData.VATTotal"></td>
            <td class="expense-total-amount">
              <div class="expense-currency">
                <span>{{ formatCurrency(expenseInfo.expense.vat_total) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" />
            <td class="total-description" v-text="i18nData.total"></td>
            <td class="expense-total-amount">
              <div class="expense-currency">
                <span>{{ formatCurrency(expenseInfo.expense.total) }}</span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
</template>
