import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "project-info" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "start-date" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["textContent"]

import { PropType } from 'vue';
import { ProjectInfo } from '@/models/project-info';
import Tooltip from '@/components/common/Tooltip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimesheetProjectInfo',
  props: {
  project: {
    type: Object as PropType<ProjectInfo>,
    required: true,
  },
},
  emits: ['edit:project'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const edit = (id: number) => {
  emit('edit:project', id);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createElementVNode("span", {
        textContent: _toDisplayString(props.project.name)
      }, null, 8, _hoisted_2),
      _createVNode(Tooltip, {
        text: "Edit project",
        inline: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "option icononly",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (edit(props.project.id)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "icon icon-pencil" }, null, -1)
          ]))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", _hoisted_3, [
      _createTextVNode(" Started " + _toDisplayString(props.project.started), 1),
      (props.project.ended)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " , ended " + _toDisplayString(props.project.ended), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", {
      textContent: _toDisplayString(props.project.description)
    }, null, 8, _hoisted_5)
  ]))
}
}

})