<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import CompanyForm from '@/components/company/CompanyForm.vue';
import CompoundList from '@/components/common/CompoundList.vue';
import { Company } from '../../models/company';

const store = useStore();

const company = computed<Company>(() => store.state.company);
const filteredCompanies = computed<Company[]>(
  () => store.getters.filteredCompanies,
);

const filter = ref<string>('');

const list = computed(() => {
  return filteredCompanies.value
    .slice()
    .sort((a: Company, b: Company) => (a.name > b.name ? 1 : -1))
    .map((c: Company) => {
      return {
        target: `/company/${c.id}`,
        name: c.name,
        description: c.description,
      };
    });
});

watch(
  () => filter.value,
  (v: string) => {
    store.commit(Mutations.UpdateFilter, v);
  },
);

onMounted(() => {
  store.dispatch(Actions.GetCompanies);
});

onUnmounted(() => {
  store.commit(Mutations.UpdateFilter, '');
});

const newCompany = () => {
  store.dispatch(Actions.NewCompany);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(CompanyForm),
    data: {
      company: company.value,
    },
  });
};
</script>
<template>
  <section class="company-list">
    <h2>Companies</h2>
    <Loading v-if="!list.length && !filter"></Loading>
    <p v-else-if="!list.length && filter">No results, adjust the filter</p>
    <CompoundList v-else :list="list" />
    <div class="buttons" v-if="filteredCompanies.length || filter">
      <Field
        label="Filter"
        hide-label
        styling="inline"
        v-model="filter"
      ></Field>
      <button type="button" class="option" @click="newCompany">
        <span class="icon icon-pencil"></span> Add company
      </button>
    </div>
  </section>
</template>
