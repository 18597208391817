import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer"
}

import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { User } from '@/models/user';
import Search from '@/components/search/Search.vue';

import { Actions } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const store = useStore();
const router = useRouter();

const auth = computed(() => store.state.auth);
const profile = computed<User>(() => store.state.profile);

const name = computed<string>(
  () => `${profile.value.first_name} ${profile.value.last_name}`,
);

const formatDate = (date: string): string => {
  const d = new Date(date);
  return `${format(d, 'dd-MM-yyyy')} at ${format(d, 'HH:mm')}`;
};

onMounted(() => {
  store.dispatch(Actions.GetProfile);
});

const logout = async () => {
  await store.dispatch(Actions.Logout);
  router.push('/login');
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (profile.value && auth.value && auth.value.isUser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Search),
        _createVNode(_component_RouterLink, { to: "/profile" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(name.value), 1)
          ]),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createTextVNode(" - Last active: ")),
        _createElementVNode("span", null, _toDisplayString(formatDate(profile.value.last_active)), 1),
        _cache[2] || (_cache[2] = _createTextVNode(" - ")),
        _createVNode(_component_RouterLink, {
          to: "/system",
          exact: ""
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("System")
          ])),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createTextVNode("- ")),
        _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(logout, ["prevent"])
        }, "Log out")
      ]))
    : _createCommentVNode("", true)
}
}

})