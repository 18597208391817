<script lang="ts" setup>
import { computed, markRaw, onBeforeUnmount, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BalanceActions } from '@/stores/balance.store';
import { Actions } from '@/store';
import { useLoading } from '../common/loading.composable';
import { Balance as BalanceModel } from '@/models/balance';
import { Company } from '@/models/company';
import { useI18n } from '@/core/i18n/i18n.composable';
import BalanceOverview from './BalanceOverview.vue';
import ExpenseList from './ExpenseList.vue';
import InvoiceList from './InvoiceList.vue';
import Loading from '@/components/common/Loading.vue';
import { DrawerMutations } from '@/stores/drawer.store';
import TaxForm from './TaxForm.vue';
import { Event, useEvents } from '@/core/services/events.composable';
import { addMonths, endOfMonth, format, parse, startOfMonth } from 'date-fns';

const store = useStore();
const route = useRoute();
const events = useEvents();

const balance = computed<BalanceModel>(() => store.state.bs.balance);
const companies = computed<Company[]>(() => store.state.companies);

const { loading, setLoading } = useLoading();
const { i18nData } = useI18n();

const load = async () => {
  const type = route.params.type;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetBalance, {
    type,
    date,
  });
  await store.dispatch(Actions.GetCompanies);
  setLoading(false);
};

onMounted(() => {
  load();
  events.on(Event.TaxesUpdated, load);
});

onBeforeUnmount(() => {
  events.off(Event.TaxesUpdated, load);
});

watch(route, load);

const type = computed(() => {
  const is_year = balance.value.info.is_year;
  const is_quarter = balance.value.info.is_quarter;
  const is_month = balance.value.info.is_month;
  return is_year
    ? 'year'
    : is_quarter
    ? 'quarter'
    : is_month
    ? 'month'
    : 'quarter';
});

const previous = computed(() => {
  return `/balance/${type.value}/${balance.value.info.previous}`;
});

const next = computed(() => {
  return `/balance/${type.value}/${balance.value.info.next}`;
});

const year = computed(() => {
  return `/balance/year/${balance.value.info.year}`;
});

const tax_year = computed(() => {
  return `/balance/tax/year/${balance.value.info.title}`;
});

const quarter = computed(() => {
  return `/balance/quarter/${balance.value.info.quarter}`;
});

const month = computed(() => {
  return `/balance/month/${balance.value.info.month}`;
});

const timePeriod = computed(() => {
  if (balance.value.info.is_quarter) {
    const startDate = addMonths(
      startOfMonth(parse(balance.value.info.next, 'yyyy-MM-dd', new Date())),
      -3,
    );
    const endDate = endOfMonth(addMonths(startDate, 2));

    return `${format(startDate, 'do MMMM yyyy')} - ${format(
      endDate,
      'do MMMM yyyy',
    )}`;
  }
  return '';
});

const editTaxInfo = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(TaxForm),
    data: balance.value.taxes,
  });
};
</script>
<template>
  <section v-if="balance && balance.info" class="screen balance">
    <Loading v-if="!balance"></Loading>

    <h2>
      {{ balance.info.title }}
      <span
        class="balance-time-period"
        v-if="timePeriod"
        v-text="timePeriod"
      ></span>
    </h2>

    <div>
      <RouterLink :to="previous">
        <span class="icon icon-caret-left"></span>
        {{ balance.info.previous_name }}
      </RouterLink>
    </div>
    <div>
      <RouterLink :to="next">
        <span class="icon icon-caret-right"></span>
        {{ balance.info.next_name }}
      </RouterLink>
    </div>
    <div v-if="!balance.info.is_year">
      <RouterLink :to="year">
        <span class="icon icon-calendar"></span>See year balance
      </RouterLink>
    </div>
    <div v-if="!balance.info.is_quarter">
      <RouterLink :to="quarter">
        <span class="icon icon-calendar"></span>See quarter balance
      </RouterLink>
    </div>
    <div v-if="!balance.info.is_month">
      <RouterLink :to="month">
        <span class="icon icon-calendar"></span>See month balance
      </RouterLink>
    </div>
    <div v-if="balance.info.is_year">
      <RouterLink :to="tax_year">
        <span class="icon icon-calendar"></span>
        See tax overview for {{ balance.info.title }}
      </RouterLink>
    </div>
    <BalanceOverview
      :i18n="i18nData"
      :overview="balance.overview"
      :taxes="balance.taxes"
      :type="type"
      :loading="loading"
    ></BalanceOverview>
    <ExpenseList :expenses="balance.expenses"></ExpenseList>
    <InvoiceList
      :invoices="balance.invoices"
      :companies="companies"
    ></InvoiceList>
    <div class="buttons" v-if="balance">
      <button
        type="button"
        class="option"
        @click="editTaxInfo"
        v-if="type === 'year'"
      >
        <span class="icon icon-euro"></span> Tax information
      </button>
      <RouterLink to="/balance/expense/new" v-slot="{ navigate }" custom>
        <button type="button" class="option" @click="navigate">
          <span class="icon icon-pencil"></span> Add expense
        </button>
      </RouterLink>
    </div>
  </section>
</template>
