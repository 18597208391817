import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "user" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]

import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Actions } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'User',
  setup(__props) {

const store = useStore();
const route = useRoute();

const user = computed(() => store.state.user);

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetUser, id);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "User", -1)),
    (user.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", {
            textContent: _toDisplayString(user.value.first_name)
          }, null, 8, _hoisted_3),
          _createElementVNode("li", {
            textContent: _toDisplayString(user.value.last_name)
          }, null, 8, _hoisted_4),
          _createElementVNode("li", {
            textContent: _toDisplayString(user.value.email)
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})