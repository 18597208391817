<script lang="ts" setup>
import { useI18n } from '@/core/i18n/i18n.composable';
import { useStore } from 'vuex';
import { companyInfo } from '@/core/util/info';
import { TimeUtil } from '@/core/util/time.util';
import { DateUtil } from '@/core/util/date.util';
import LanguageSwitch from '@/components/common/LanguageSwitch.vue';
import Loading from '@/components/common/Loading.vue';
import { Timesheet } from '@/models/timesheet';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useLoading } from '../common/loading.composable';
import { TimesheetActions } from '@/stores/timesheet.store';

const store = useStore();
const route = useRoute();

const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const timesheet_weeks = computed<any[]>(
  () => store.getters[`ts/timesheet_weeks`],
);
const { i18nData, language, setLanguage } = useI18n();
const { loading, setLoading } = useLoading();

const totalHours = computed(() => {
  return TimeUtil.minutesToTime(
    timesheet_weeks.value.reduce((total: number, week: any) => {
      total += week.minutes;
      return total;
    }, 0),
  );
});

const load = async () => {
  const id = route.params.id;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheet, { id, date });
  setLoading(false);
};

onMounted(() => {
  load();
});

const minutesToTime = (minutes: number) => {
  return TimeUtil.minutesToTime(minutes);
};

const weekDescription = (week: any) => {
  const start = DateUtil.humanDay(language.value, week.start);
  const end = DateUtil.humanDay(language.value, week.end);

  return `${i18nData.value.workedHours} ${i18nData.value.from} ${start} ${i18nData.value.to} ${end}`;
};
</script>
<template>
  <section class="page timesheet">
    <Loading v-if="!timesheet"></Loading>
    <section v-else class="invoice">
      <LanguageSwitch
        :language="language"
        @set-language="setLanguage($event)"
      ></LanguageSwitch>
      <div class="invoicing-company">
        <div v-text="companyInfo.name"></div>
        <div v-text="companyInfo.address.line"></div>
        <div
          v-text="`${companyInfo.address.zipcode} ${companyInfo.address.city}`"
        ></div>
        <div v-text="companyInfo.website"></div>
        <div v-text="companyInfo.email"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div class="invoice-details" v-if="timesheet.info">
        <h2>
          <span v-text="i18nData.timesheet"></span>&nbsp;
          <span v-text="timesheet.info.title"></span>
        </h2>
        <h3>
          <span v-text="timesheet.project.name"></span>
          -
          <span>Carl Giesberts</span>
        </h3>
        <table>
          <thead>
            <tr>
              <th
                class="invoice-table-header"
                width="85%"
                v-text="i18nData.description"
              ></th>
              <th class="invoice-table-header" v-text="i18nData.hours"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="invoice-detail"
              v-for="week in timesheet_weeks"
              :key="week.week_number"
            >
              <td>{{ weekDescription(week) }}</td>
              <td class="invoice-hours">{{ minutesToTime(week.minutes) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="total-description invoice-table-header"
                v-text="i18nData.total"
              ></td>
              <td class="invoice-hours" v-text="totalHours"></td>
            </tr>
          </tfoot>
        </table>
        <br />
        <br />
        <br />
        <br />
        <section class="signature">
          <h3 v-text="i18nData.agreed"></h3>
          <div class="columns">
            <div class="col half">
              <div v-text="i18nData.nameAndDate"></div>
              <div class="line"></div>
            </div>
            <div class="col half">
              <div v-text="i18nData.signature"></div>
              <div class="line"></div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
      </div>
    </section>
  </section>
</template>
