<script lang="ts" setup>
import { reactive, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { Expense, ExpenseLine, expenseValidations } from '@/models/expense';
import {
  ExpenseReferenceInput,
  expenseReferenceValidations,
} from '@/models/reference';
import Field from '@/components/common/Field.vue';
import FieldFile from '@/components/common/FieldFile.vue';
import FieldLong from '@/components/common/FieldLong.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import ExpenseFormLine from './ExpenseFormLine.vue';
import Loading from '@/components/common/Loading.vue';
import { DateUtil } from '@/core/util/date.util';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';

const store = useStore();
const router = useRouter();
const { loading, setLoading } = useLoading();
const expense = reactive<Expense>(
  new Expense({
    id: -1,
    number: '',
    user_id: -1,
    date_created: DateUtil.format(new Date()),
    date_created_f: '',
    lines: [],
  }),
);

expense.addLine({
  vat: 0.21,
});

const reference = reactive<ExpenseReferenceInput>({
  summary: '',
  file: null,
});

const $v = useVuelidate(expenseValidations, { expense });
const $vr = useVuelidate(expenseReferenceValidations, {
  reference,
});

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(BalanceActions.AddExpense, getData());
    router.push('/balance');
    setLoading(false);
  }
};

const cancel = () => {
  router.push('/balance');
};

const removeLine = (line: ExpenseLine) => {
  expense.removeLine(line);
};

const addLine = () => {
  expense.addLine({
    vat: 0.21,
  });
};

const getData = () => {
  const data = new FormData();
  data.append(
    'info',
    JSON.stringify({
      expense: expense,
      reference: {
        summary: reference.summary,
      },
    }),
  );
  data.append('file', reference.file!);
  return data;
};
</script>

<template>
  <form
    v-if="expense"
    class="form add-expense-form"
    @submit.prevent="submit"
    :class="{ submitting: loading }"
  >
    <h2>Add Expense</h2>
    <div class="columns">
      <Field
        label="Date"
        styling="col block quarter"
        v-model="expense.date_created"
        type="date"
        :validation="$v.expense.date_created"
      ></Field>
    </div>
    <p>
      Enter the VAT and the amount of the expense. Add more lines as needed.
    </p>
    <ExpenseFormLine
      v-for="line in expense.lines"
      :line="line"
      :key="line.name"
      :isOnly="expense.lines.length === 1"
      @remove="removeLine(line)"
      @add="addLine()"
    ></ExpenseFormLine>
    <div class="balance-total-line columns">
      <div class="col half" />
      <div class="col onetenth" />
      <div class="col onetenth field">
        <label>Total</label>
      </div>
      <FieldNumber
        label="Total"
        hide-label
        styling="col oneeight block"
        readonly
        v-model="expense.total"
        step="0.01"
      ></FieldNumber>
      <div class="col onetenth" />
    </div>
    <p>You can optionally add a reference document below.</p>
    <div class="columns">
      <FieldFile
        label="Reference"
        styling="col threefifths block"
        v-model="reference.file"
        :validation="$vr.reference.file"
        accept=".pdf"
      ></FieldFile>
    </div>
    <div class="columns">
      <FieldLong
        label="Description"
        placeholder="Description"
        styling="col threefifths block"
        v-model="reference.summary"
        :validation="$vr.reference.summary"
      ></FieldLong>
    </div>
    <div class="buttons">
      <button class="confirm" type="submit" :disabled="loading">
        <span class="icon icon-ok"></span>
        <span class="text">Add</span>
      </button>
      <button class="cancel" type="button" @click="cancel">
        <span class="icon icon-remove"></span>
        <span class="text">Clear</span>
      </button>
      <Loading v-if="loading"></Loading>
    </div>
  </form>
</template>
