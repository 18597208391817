import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen balance"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = {
  key: 5,
  class: "buttons"
}
const _hoisted_8 = ["onClick"]

import { computed, markRaw, onBeforeUnmount, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BalanceActions } from '@/stores/balance.store';
import { Actions } from '@/store';
import { useLoading } from '../common/loading.composable';
import { Balance as BalanceModel } from '@/models/balance';
import { Company } from '@/models/company';
import { useI18n } from '@/core/i18n/i18n.composable';
import BalanceOverview from './BalanceOverview.vue';
import ExpenseList from './ExpenseList.vue';
import InvoiceList from './InvoiceList.vue';
import Loading from '@/components/common/Loading.vue';
import { DrawerMutations } from '@/stores/drawer.store';
import TaxForm from './TaxForm.vue';
import { Event, useEvents } from '@/core/services/events.composable';
import { addMonths, endOfMonth, format, parse, startOfMonth } from 'date-fns';


export default /*@__PURE__*/_defineComponent({
  __name: 'Balance',
  setup(__props) {

const store = useStore();
const route = useRoute();
const events = useEvents();

const balance = computed<BalanceModel>(() => store.state.bs.balance);
const companies = computed<Company[]>(() => store.state.companies);

const { loading, setLoading } = useLoading();
const { i18nData } = useI18n();

const load = async () => {
  const type = route.params.type;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetBalance, {
    type,
    date,
  });
  await store.dispatch(Actions.GetCompanies);
  setLoading(false);
};

onMounted(() => {
  load();
  events.on(Event.TaxesUpdated, load);
});

onBeforeUnmount(() => {
  events.off(Event.TaxesUpdated, load);
});

watch(route, load);

const type = computed(() => {
  const is_year = balance.value.info.is_year;
  const is_quarter = balance.value.info.is_quarter;
  const is_month = balance.value.info.is_month;
  return is_year
    ? 'year'
    : is_quarter
    ? 'quarter'
    : is_month
    ? 'month'
    : 'quarter';
});

const previous = computed(() => {
  return `/balance/${type.value}/${balance.value.info.previous}`;
});

const next = computed(() => {
  return `/balance/${type.value}/${balance.value.info.next}`;
});

const year = computed(() => {
  return `/balance/year/${balance.value.info.year}`;
});

const tax_year = computed(() => {
  return `/balance/tax/year/${balance.value.info.title}`;
});

const quarter = computed(() => {
  return `/balance/quarter/${balance.value.info.quarter}`;
});

const month = computed(() => {
  return `/balance/month/${balance.value.info.month}`;
});

const timePeriod = computed(() => {
  if (balance.value.info.is_quarter) {
    const startDate = addMonths(
      startOfMonth(parse(balance.value.info.next, 'yyyy-MM-dd', new Date())),
      -3,
    );
    const endDate = endOfMonth(addMonths(startDate, 2));

    return `${format(startDate, 'do MMMM yyyy')} - ${format(
      endDate,
      'do MMMM yyyy',
    )}`;
  }
  return '';
});

const editTaxInfo = () => {
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(TaxForm),
    data: balance.value.taxes,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (balance.value && balance.value.info)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (!balance.value)
          ? (_openBlock(), _createBlock(Loading, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("h2", null, [
          _createTextVNode(_toDisplayString(balance.value.info.title) + " ", 1),
          (timePeriod.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "balance-time-period",
                textContent: _toDisplayString(timePeriod.value)
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_RouterLink, { to: previous.value }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon icon-caret-left" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(balance.value.info.previous_name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_RouterLink, { to: next.value }, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon icon-caret-right" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(balance.value.info.next_name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        (!balance.value.info.is_year)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_RouterLink, { to: year.value }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "icon icon-calendar" }, null, -1),
                  _createTextVNode("See year balance ")
                ])),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (!balance.value.info.is_quarter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_RouterLink, { to: quarter.value }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "icon icon-calendar" }, null, -1),
                  _createTextVNode("See quarter balance ")
                ])),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (!balance.value.info.is_month)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_RouterLink, { to: month.value }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "icon icon-calendar" }, null, -1),
                  _createTextVNode("See month balance ")
                ])),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (balance.value.info.is_year)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_RouterLink, { to: tax_year.value }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "icon icon-calendar" }, null, -1)),
                  _createTextVNode(" See tax overview for " + _toDisplayString(balance.value.info.title), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(BalanceOverview, {
          i18n: _unref(i18nData),
          overview: balance.value.overview,
          taxes: balance.value.taxes,
          type: type.value,
          loading: _unref(loading)
        }, null, 8, ["i18n", "overview", "taxes", "type", "loading"]),
        _createVNode(ExpenseList, {
          expenses: balance.value.expenses
        }, null, 8, ["expenses"]),
        _createVNode(InvoiceList, {
          invoices: balance.value.invoices,
          companies: companies.value
        }, null, 8, ["invoices", "companies"]),
        (balance.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (type.value === 'year')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "option",
                    onClick: editTaxInfo
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("span", { class: "icon icon-euro" }, null, -1),
                    _createTextVNode(" Tax information ")
                  ])))
                : _createCommentVNode("", true),
              _createVNode(_component_RouterLink, {
                to: "/balance/expense/new",
                custom: ""
              }, {
                default: _withCtx(({ navigate }) => [
                  _createElementVNode("button", {
                    type: "button",
                    class: "option",
                    onClick: navigate
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("span", { class: "icon icon-pencil" }, null, -1),
                    _createTextVNode(" Add expense ")
                  ]), 8, _hoisted_8)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})