import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "company-list" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 3,
  class: "buttons"
}

import { computed, onMounted, onUnmounted, ref, watch, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import { DrawerMutations } from '@/stores/drawer.store';
import Loading from '@/components/common/Loading.vue';
import Field from '@/components/common/Field.vue';
import CompanyForm from '@/components/company/CompanyForm.vue';
import CompoundList from '@/components/common/CompoundList.vue';
import { Company } from '../../models/company';


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyList',
  setup(__props) {

const store = useStore();

const company = computed<Company>(() => store.state.company);
const filteredCompanies = computed<Company[]>(
  () => store.getters.filteredCompanies,
);

const filter = ref<string>('');

const list = computed(() => {
  return filteredCompanies.value
    .slice()
    .sort((a: Company, b: Company) => (a.name > b.name ? 1 : -1))
    .map((c: Company) => {
      return {
        target: `/company/${c.id}`,
        name: c.name,
        description: c.description,
      };
    });
});

watch(
  () => filter.value,
  (v: string) => {
    store.commit(Mutations.UpdateFilter, v);
  },
);

onMounted(() => {
  store.dispatch(Actions.GetCompanies);
});

onUnmounted(() => {
  store.commit(Mutations.UpdateFilter, '');
});

const newCompany = () => {
  store.dispatch(Actions.NewCompany);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(CompanyForm),
    data: {
      company: company.value,
    },
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Companies", -1)),
    (!list.value.length && !filter.value)
      ? (_openBlock(), _createBlock(Loading, { key: 0 }))
      : (!list.value.length && filter.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "No results, adjust the filter"))
        : (_openBlock(), _createBlock(CompoundList, {
            key: 2,
            list: list.value
          }, null, 8, ["list"])),
    (filteredCompanies.value.length || filter.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Field, {
            label: "Filter",
            "hide-label": "",
            styling: "inline",
            modelValue: filter.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("button", {
            type: "button",
            class: "option",
            onClick: newCompany
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "icon icon-pencil" }, null, -1),
            _createTextVNode(" Add company ")
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})