import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "invoice"
}
const _hoisted_2 = { class: "items invoice-info" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { style: {"flex":"1"} }
const _hoisted_5 = { class: "columns" }
const _hoisted_6 = { class: "columns" }
const _hoisted_7 = { class: "balance-total-line columns" }
const _hoisted_8 = { class: "balance-total-line columns" }
const _hoisted_9 = { class: "balance-total-line columns" }
const _hoisted_10 = { class: "buttons" }
const _hoisted_11 = ["disabled"]

import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import {
  Invoice,
  InvoiceLine,
  InvoiceOption,
  InvoiceType,
  invoiceValidations,
} from '@/models/invoice';
import { Timesheet } from '../../models/timesheet';
import { Option } from '../../models/option';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import FieldOptions from '@/components/common/FieldOptions.vue';
import InvoiceCompanyInfo from './InvoiceCompanyInfo.vue';
import InvoiceFormLine from './InvoiceFormLine.vue';
import InvoiceFormOption from './InvoiceFormOption.vue';
import Loading from '@/components/common/Loading.vue';
import { useLoading } from '../common/loading.composable';
import { BalanceActions } from '@/stores/balance.store';
import { getMonthName } from '@/core/util/date.util';
import { TimesheetActions } from '@/stores/timesheet.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceForm',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();
const timesheet = computed<Timesheet>(() => store.state.ts.timesheet);
const { loading, setLoading } = useLoading();

const subject = (): string => {
  return `${timesheet.value.info.title}`;
};

const getFirstLineDescription = (l: 'en' | 'nl', date: Date): string => {
  const hours = l === 'en' ? 'hours' : 'uren';
  const rounded = l === 'en' ? 'rounded' : 'afgerond';
  return `${timesheet.value.project.name} ${hours} ${getMonthName(
    date.getMonth(),
    l,
  )} (${rounded})`;
};

const date = new Date(route.params.date as string);
const invoice = reactive<Invoice>(
  new Invoice({
    id: -1,
    project_id: timesheet.value.project.id,
    company_id: timesheet.value.company.id!,
    number: '',
    date_created: date.toISOString(),
    date_reference: `${timesheet.value.info.month}-01`,
    subject: subject(),
    lines: [
      {
        name: 'one',
        description: getFirstLineDescription('en', date),
        amount: timesheet.value.monthTotal,
        price: timesheet.value.project.rate,
        vat: 0.21,
        total: timesheet.value.monthTotal * timesheet.value.project.rate,
      },
    ],
    options: [
      ...(timesheet.value.project.options || []).map((i) => ({
        label: i.label,
        value: i.value,
      })),
    ],
    paid: false,
    type: InvoiceType.Hours,
    language: 'en',
  }),
);
const $v = useVuelidate(invoiceValidations, invoice);
const language = ref<string>('en');
const languages = ref<Option<string>[]>([
  {
    name: 'Nederlands',
    value: 'nl',
  },
  {
    name: 'English',
    value: 'en',
  },
]);
const types = ref<Option<string>[]>([
  {
    name: 'Hours',
    value: 'hours',
  },
  {
    name: 'Other',
    value: 'items',
  },
]);

const load = async () => {
  const id = route.params.id;
  setLoading(true);
  await store.dispatch(TimesheetActions.GetTimesheet, {
    id,
    date: route.params.date,
  });
  setLoading(false);
};

onMounted(load);

const submit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    setLoading(true);
    await store.dispatch(BalanceActions.AddInvoice, invoice);
    router.push('/balance');
    setLoading(false);
  }
};

const cancel = () => {
  router.push(`/timesheet/project/${route.params.id}/${route.params.month}-01`);
};

const removeLine = (line: InvoiceLine) => {
  invoice.removeLine(line);
};

const addLine = () => {
  invoice.addLine();
};

const removeOption = (option: InvoiceOption) => {
  invoice.removeOption(option);
};

const addOption = () => {
  invoice.addOption();
};

const updateLanguage = (l: 'en' | 'nl') => {
  language.value = l;
  invoice.language = l;
  invoice.subject = `${getMonthName(date.getMonth(), l)} ${date.getFullYear()}`;
  const line = invoice.lines[0];

  if (line) {
    invoice.lines[0] = {
      name: line.name,
      description: getFirstLineDescription(l, date),
      amount: line.amount,
      price: line.price,
      vat: line.vat,
      total: line.total,
    };
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("form", {
      class: _normalizeClass(["form invoice-form", { submitting: _unref(loading) }]),
      onSubmit: _withModifiers(submit, ["prevent"])
    }, [
      _cache[31] || (_cache[31] = _createElementVNode("h2", null, "Add Invoice", -1)),
      (timesheet.value && invoice)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(InvoiceCompanyInfo, {
              company: timesheet.value.company
            }, null, 8, ["company"]),
            _createElementVNode("ul", _hoisted_2, [
              _createElementVNode("li", null, [
                _cache[9] || (_cache[9] = _createElementVNode("label", null, "Invoice number", -1)),
                _createElementVNode("div", null, _toDisplayString(invoice.invoiceNumber), 1)
              ]),
              _createElementVNode("li", null, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Invoice date", -1)),
                _createElementVNode("div", null, _toDisplayString(timesheet.value.info.date_formatted), 1)
              ]),
              _createElementVNode("li", null, [
                _cache[11] || (_cache[11] = _createElementVNode("label", null, "Invoice period", -1)),
                _createElementVNode("div", null, _toDisplayString(invoice.subject), 1)
              ])
            ]),
            _createElementVNode("section", _hoisted_3, [
              _cache[12] || (_cache[12] = _createElementVNode("label", null, "References", -1)),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invoice.options, (option) => {
                  return (_openBlock(), _createBlock(InvoiceFormOption, {
                    modelValue: invoice.options,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((invoice.options) = $event)),
                    option: option,
                    key: option.value,
                    isOnly: invoice.options.length === 1,
                    onRemove: ($event: any) => (removeOption(option)),
                    onAdd: _cache[1] || (_cache[1] = ($event: any) => (addOption()))
                  }, null, 8, ["modelValue", "option", "isOnly", "onRemove"]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(Field, {
                label: "Invoice period",
                placeholder: "Invoice period",
                styling: "col two-quarters",
                modelValue: invoice.subject,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((invoice.subject) = $event)),
                validation: _unref($v).subject
              }, null, 8, ["modelValue", "validation"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(FieldOptions, {
                label: "Language",
                "onUpdate:modelValue": [
                  updateLanguage,
                  _cache[3] || (_cache[3] = ($event: any) => ((invoice.language) = $event))
                ],
                styling: "col two-quarters",
                type: "radio",
                options: languages.value,
                modelValue: invoice.language
              }, null, 8, ["options", "modelValue"]),
              _createVNode(FieldOptions, {
                label: "Type",
                styling: "col two-quarters",
                type: "radio",
                options: types.value,
                modelValue: invoice.type,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((invoice.type) = $event))
              }, null, 8, ["options", "modelValue"])
            ]),
            _cache[30] || (_cache[30] = _createElementVNode("p", null, " Enter the VAT and the amount of the invoice. Add more lines as needed. ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invoice.lines, (line) => {
              return (_openBlock(), _createBlock(InvoiceFormLine, {
                line: line,
                key: line.name,
                isOnly: invoice.lines.length === 1,
                onRemove: ($event: any) => (removeLine(line)),
                onAdd: _cache[5] || (_cache[5] = ($event: any) => (addLine()))
              }, null, 8, ["line", "isOnly", "onRemove"]))
            }), 128)),
            _createElementVNode("div", _hoisted_7, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col third" }, null, -1)),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col onetenth" }, null, -1)),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col onefifth" }, null, -1)),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col oneeight field" }, [
                _createElementVNode("label", null, "Subtotal")
              ], -1)),
              _createVNode(FieldNumber, {
                label: "Subtotal",
                "hide-label": "",
                readonly: "",
                styling: "col oneeight",
                modelValue: invoice.sub_total,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((invoice.sub_total) = $event)),
                step: "0.01"
              }, null, 8, ["modelValue"]),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "col onetenth" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "col third" }, null, -1)),
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "col onetenth" }, null, -1)),
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col onetenth" }, null, -1)),
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "col onetenth field" }, [
                _createElementVNode("label", null, "VAT")
              ], -1)),
              _createVNode(FieldNumber, {
                label: "VatTotal",
                "hide-label": "",
                readonly: "",
                styling: "col oneeight",
                modelValue: invoice.vat_total,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((invoice.vat_total) = $event)),
                step: "0.01"
              }, null, 8, ["modelValue"]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col onetenth" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "col third" }, null, -1)),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col onetenth" }, null, -1)),
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "col onefifth" }, null, -1)),
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "col oneeight field" }, [
                _createElementVNode("label", null, "Total")
              ], -1)),
              _createVNode(FieldNumber, {
                label: "total",
                "hide-label": "",
                readonly: "",
                styling: "col oneeight",
                modelValue: invoice.total,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((invoice.total) = $event)),
                step: "0.01"
              }, null, 8, ["modelValue"]),
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "col onetenth" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "confirm",
                type: "submit",
                disabled: _unref(loading)
              }, _cache[28] || (_cache[28] = [
                _createElementVNode("span", { class: "icon icon-ok" }, null, -1),
                _createElementVNode("span", { class: "text" }, "Add", -1)
              ]), 8, _hoisted_11),
              _createElementVNode("button", {
                class: "cancel",
                type: "button",
                onClick: cancel
              }, _cache[29] || (_cache[29] = [
                _createElementVNode("span", { class: "icon icon-remove" }, null, -1),
                _createElementVNode("span", { class: "text" }, "Clear", -1)
              ])),
              (_unref(loading))
                ? (_openBlock(), _createBlock(Loading, { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 34)
  ]))
}
}

})