<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { Expense } from '@/models/expense';
import { formatCurrency } from '@/filters/filters';

const { expense } = defineProps({
  expense: {
    type: Object as PropType<Expense>,
    required: true,
  },
});

const expenseUrl = computed(() => `/balance/expense/${expense.number}`);
</script>
<template>
  <li>
    <span class="primary-label" v-raise-ordinal>{{
      expense.date_created_f
    }}</span>
    <section>
      <RouterLink :to="expenseUrl">{{ expense.number }}</RouterLink>
      - {{ expense.description }}
    </section>
    <div class="tags">
      <div class="tag">
        <span class="currency">{{ formatCurrency(expense.total) }}</span>
      </div>
    </div>
  </li>
</template>
