<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { startOfYear, isAfter } from 'date-fns';
import { useI18n } from '@/core/i18n/i18n.composable';
import { BalanceActions } from '@/stores/balance.store';
import { companyInfo, bankInfo } from '@/core/util/info';
import { formatCurrency } from '@/filters/filters';
import { InvoiceInfo } from '@/services/balance.service';
import { useLoading } from '../common/loading.composable';
import InvoiceLine from '@/components/pages/InvoiceLine.vue';
import { I18NLanguage } from '@/core/i18n';

const store = useStore();
const route = useRoute();
const invoiceInfo = computed<InvoiceInfo | null>(
  () => store.state.bs.invoiceInfo,
);

const { i18nData, language, setLanguage } = useI18n();
const { loading, setLoading } = useLoading();

const term: string = '30 dagen';

const timesheetUrl = computed(() => {
  return invoiceInfo.value
    ? `/timesheet/project/${invoiceInfo.value.project.id}/${invoiceInfo.value.invoice.date_reference}`
    : '';
});

const before2020 = (): boolean => {
  if (!invoiceInfo.value) {
    return false;
  }
  const date = new Date(invoiceInfo.value.invoice.date_created);
  const startOf2020 = startOfYear(2020);
  return isAfter(startOf2020, date);
};

const load = async () => {
  const number = route.params.number;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetInvoice, { number, date });
  setLoading(false);
  setLanguage(invoiceInfo.value?.invoice.language as I18NLanguage);
};

onMounted(() => {
  load();
});
</script>

<template>
  <section class="page invoice" v-if="invoiceInfo">
    <div class="invoice" data-name="invoice">
      <section class="invoiced-company">
        <div v-text="invoiceInfo.company.address.name"></div>
        <div v-text="`${i18nData.ttao} ${invoiceInfo.company.contact}`"></div>
        <div v-text="invoiceInfo.company.address.line_one"></div>
        <div
          v-if="invoiceInfo.company.address.line_two"
          v-text="invoiceInfo.company.address.line_two"
        ></div>
        <div
          v-text="
            `${invoiceInfo.company.address.zipcode} ${invoiceInfo.company.address.city}`
          "
        ></div>
      </section>
      <section class="invoicing-company">
        <div v-text="companyInfo.name"></div>
        <div v-text="companyInfo.address.line"></div>
        <div
          v-text="`${companyInfo.address.zipcode} ${companyInfo.address.city}`"
        ></div>
        <div v-text="companyInfo.website"></div>
        <div v-text="companyInfo.email"></div>
        <div v-text="`${i18nData.COC} ${companyInfo.KvK}`"></div>
        <div
          v-if="before2020()"
          v-text="`${i18nData.VAT} ${companyInfo.BTW}`"
        ></div>
        <div v-else v-text="`${i18nData.VATID} ${companyInfo.BTWID}`"></div>
      </section>
      <div class="invoice-information">
        <div>
          <div
            class="invoice-information-label"
            v-text="i18nData.invoiceNumber"
          ></div>
          <div
            class="invoice-information-data"
            v-text="invoiceInfo.invoice.number"
          ></div>
        </div>
        <div>
          <div
            class="invoice-information-label"
            v-text="i18nData.invoiceDate"
          ></div>
          <div class="invoice-information-data">
            <span v-raise-ordinal>{{
              invoiceInfo.invoice.date_created_f
            }}</span>
          </div>
        </div>
        <div>
          <div
            class="invoice-information-label"
            v-text="i18nData.invoicePeriod"
          ></div>
          <div class="invoice-information-data">
            <RouterLink :to="timesheetUrl">{{
              invoiceInfo.invoice.subject
            }}</RouterLink>
          </div>
        </div>
      </div>
      <div class="invoice-details">
        <h1>{{ i18nData.invoice }}</h1>
        <table>
          <thead>
            <tr>
              <th
                class="invoice-table-header"
                width="60%"
                v-text="i18nData.description"
              ></th>
              <th
                class="invoice-table-header"
                v-text="
                  invoiceInfo.invoice.type === 'hours'
                    ? i18nData.hours
                    : i18nData.items
                "
              ></th>
              <th
                class="invoice-table-header invoice-currency"
                v-text="
                  invoiceInfo.invoice.type === 'hours'
                    ? i18nData.rate
                    : i18nData.tarif
                "
              ></th>
              <th
                class="invoice-table-header invoice-currency"
                v-text="i18nData.subTotal"
              ></th>
            </tr>
          </thead>
          <tbody>
            <InvoiceLine
              v-for="line in invoiceInfo.invoice.lines"
              :key="line.name"
              :line="line"
            ></InvoiceLine>
          </tbody>
          <tfoot class="invoice-totals">
            <tr>
              <td
                class="total-description invoice-table-header"
                colspan="3"
                v-text="i18nData.totalExclVAT"
              ></td>
              <td class="invoice-currency invoice-total-amount">
                <span>{{ formatCurrency(invoiceInfo.invoice.sub_total) }}</span>
              </td>
            </tr>
            <tr>
              <td
                class="total-description invoice-table-header"
                colspan="3"
                v-text="i18nData.VAT"
              ></td>
              <td class="invoice-currency invoice-total-amount">
                <span>{{ formatCurrency(invoiceInfo.invoice.vat_total) }}</span>
              </td>
            </tr>
            <tr>
              <td
                class="total-description invoice-table-header"
                colspan="3"
                v-text="i18nData.TotalInclVAT"
              ></td>
              <td class="invoice-currency invoice-total-amount">
                <span>{{ formatCurrency(invoiceInfo.invoice.total) }}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="payment-instructions">
        <h2 v-text="i18nData.paymentInstruction"></h2>
        <p>
          {{ i18nData.paymentInstruction_part1 }} {{ term }}
          {{ i18nData.paymentInstruction_part2 }}
          <strong
            v-text="
              `${i18nData.invoiceNumber_lc} ${invoiceInfo.invoice.number}`
            "
          ></strong>
          {{ i18nData.paymentInstruction_part3 }}
        </p>
      </div>
      <table class="payment-information">
        <thead>
          <tr>
            <th v-text="i18nData.bankAccount"></th>
            <th v-text="i18nData.inNameOf"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-text="`${bankInfo.name} ${i18nData.account} ${bankInfo.IBAN}`"
            ></td>
            <td>Front Crafter, Leiden</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
