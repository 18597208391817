<script lang="ts" setup>
import { Company } from '@/models/company';
import { Project } from '@/models/project';
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { ModalMutations } from '@/stores/modal.store';
import { Actions } from '@/store';

const store = useStore();
const companies = computed<Company[]>(() => store.state.companies);
const projects = computed<Project[]>(() => store.state.projects);

const companyList = computed((): Array<Company> => {
  const list = projects.value
    ? projects.value.reduce((li: Array<Company>, project) => {
        const company = li.find((c) => c.id === project.company_id);
        if (!company) {
          li.push({
            id: project.company_id,
            name: project.company_name,
            projects: [project],
          });
        } else {
          company.projects!.push(project);
        }
        return li;
      }, [])
    : [];
  const other: Array<Company> = companies.value
    .filter((c) => !list.find((co) => co.id === c.id))
    .map((company: Company) => {
      return {
        ...company,
        projects: [],
      };
    });
  return list.concat(other);
});

onMounted(() => {
  store.dispatch(Actions.GetCompanies);
  store.dispatch(Actions.GetProjects);
});

const onConfirmDeleteProject = (data: any) => {
  store.dispatch(Actions.RemoveProject, data.project.id);
};

const deleteProject = (project: Project) => {
  store.commit(ModalMutations.OpenModal, {
    modal: 'confirm-delete-modal',
    data: {
      name: project.name,
      project,
      confirm: onConfirmDeleteProject,
    },
  });
};

const onConfirmDeleteCompany = (data: any) => {
  store.dispatch(Actions.RemoveCompany, data.company.id);
};

const deleteCompany = (company: Company) => {
  store.commit(ModalMutations.OpenModal, {
    modal: 'confirm-delete-modal',
    data: {
      name: company.name,
      company,
      confirm: onConfirmDeleteCompany,
    },
  });
};
</script>
<template>
  <section class="admin-companies">
    <h3>Companies</h3>
    <ul class="simple">
      <li v-for="company in companyList" :key="company.id" class="card">
        <h4 v-text="company.name"></h4>
        <button
          v-if="company.projects && company.projects.length === 0"
          class="danger icononly"
          type="button"
          @click.prevent="deleteCompany(company)"
        >
          <i class="icon icon-remove"></i>
        </button>
        <ul class="cards">
          <li
            v-for="project in company.projects"
            :key="project.id"
            class="card"
          >
            <span v-text="project.name"></span>

            <button
              class="danger icononly"
              type="button"
              @click.prevent="deleteProject(project)"
            >
              <i class="icon icon-remove"></i>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>
