<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Actions, Mutations } from '@/store';
import Field from '@/components/common/Field.vue';
import { Result } from '@/models/results.model';
import UserSearchResult from './UserSearchResult.vue';
import CompanySearchResult from './CompanySearchResult.vue';
import ProjectSearchResult from './ProjectSearchResult.vue';
import ExpenseSearchResult from './ExpenseSearchResult.vue';
import InvoiceSearchResult from './InvoiceSearchResult.vue';
import HoursSearchResult from './HoursSearchResult.vue';

const store = useStore();

const results = computed<Result<any>[]>(() => store.state.results);

const value = ref<string>('');
const active = ref<boolean>(false);
const searchInput = ref<HTMLInputElement | null>(null);

let to: number = -1;
const search = () => {
  window.clearTimeout(to);
  to = window.setTimeout(() => {
    if (value.value) {
      store.dispatch(Actions.Search, value.value);
    } else {
      store.commit(Mutations.ResetSearchResults);
    }
  }, 200);
};

const clear = () => {
  value.value = '';
  search();

  if (searchInput.value && active.value) {
    console.log(searchInput.value);
    // searchInput.value.focus();
  }
};

const getResultType = (result: Result<any>) => {
  switch (result.type) {
    case 'user':
      return UserSearchResult;
    case 'company':
      return CompanySearchResult;
    case 'project':
      return ProjectSearchResult;
    case 'expense':
      return ExpenseSearchResult;
    case 'invoice':
      return InvoiceSearchResult;
    case 'hours':
      return HoursSearchResult;
  }
};
</script>
<template>
  <div
    :class="`search ${active ? 'search-active' : ''}`"
    v-click-outside="clear"
  >
    <Field
      v-if="active"
      label="Search"
      hide-label
      styling="inline"
      v-model="value"
      v-auto-focus="'focus'"
      @update:modelValue="search"
    />
    <span
      class="icon icon-search"
      @click="
        active = !active;
        clear();
      "
    />
    <ul class="results" v-if="results.length">
      <component
        :is="getResultType(result)"
        v-for="result in results"
        :key="`${result.item.id}-${result.type}`"
        :result="result"
      />
    </ul>
  </div>
</template>
