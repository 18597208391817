<script lang="ts" setup>
import { computed, reactive } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import AddressFields from '@/components/common/AddressFields.vue';
import TestItemFields from '@/components/TestItemFields.vue';

const data = reactive({
  firstName: '',
  lastName: '',
  address: {
    name: '',
    line_one: '',
    line_two: '',
    zipcode: '',
    city: '',
    country: 'Netherlands',
  },
  items: [
    {
      id: 1,
      price: 1,
      vat: 0.21,
    },
    {
      id: 2,
      price: 15,
      vat: 0.21,
    },
    {
      id: 3,
      price: 20,
      vat: 0.21,
    },
  ],
});

const rules: ValidationArgs = {
  firstName: {
    required,
  },
  address: {
    line_one: {
      required,
    },
    city: {
      required,
    },
  },
};

const $v = useVuelidate(rules, data);

const submit = () => {
  console.log(data);
};

const total = computed(() =>
  data.items.reduce(
    (acc, line) => (acc += (line.price || 0) * (line.vat + 1)),
    0,
  ),
);
</script>
<template>
  <section class="box test-form">
    <form @submit.prevent="submit">
      <h2>Test form</h2>

      <Field
        v-model="data.firstName"
        label="First name"
        placeholder="Your first name"
        :validation="$v.firstName"
      />

      <Field
        v-model="data.lastName"
        label="Last name"
        placeholder="Your last name"
        :validation="$v.lastName"
      />

      <AddressFields
        v-model="data.address"
        :validation="$v.address"
        :loading="false"
      />

      <ul>
        <li v-for="(item, index) in data.items" :key="item.id">
          <TestItemFields v-model="data.items[index]" />
        </li>
      </ul>
      <FieldNumber v-model="total" label="Total" step="1.00" readonly />
      <pre>{{ data }}</pre>
      <div class="buttons">
        <button class="confirm" type="submit">
          <span class="text">Test</span>
        </button>
      </div>
    </form>
  </section>
</template>
<style scoped>
.test-form {
  width: 50%;
  min-width: 500px;
  margin: 5em auto;
}

ul {
  padding: 0;

  list-style-type: none;
}
</style>
