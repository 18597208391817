<script lang="ts" setup>
import { PropType, computed } from 'vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';

const props = defineProps({
  modelValue: {
    type: Object as PropType<{ price: number; vat: number; id: number }>,
    required: true,
  },
});

const total = computed(
  () => props.modelValue.price * (props.modelValue.vat + 1),
);

const rules: ValidationArgs = {
  price: {
    required,
    decimal,
  },
};
const $v = useVuelidate(rules, props.modelValue);
</script>
<template>
  <div class="item">
    <FieldNumber
      label="Price"
      styling="block"
      v-model="props.modelValue.price"
      :validation="$v.price"
      step="1.00"
    />
    <FieldNumber
      label="VAT"
      styling="block"
      v-model="props.modelValue.vat"
      :validation="$v.vat"
      step="0.01"
    />
    <FieldNumber
      label="Total"
      readonly
      styling="block"
      v-model="total"
      step="1.00"
    />
  </div>
</template>
<style>
.item {
  display: flex;
}

.item .field {
  margin: 0 0.25em;
}

.item .field:first-child {
  margin-left: 0;
}
.item .field:last-child {
  margin-right: 0;
}
</style>
