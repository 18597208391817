<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from '@/core/i18n/i18n.composable';
import { Actions } from '@/store';
import { BalanceActions } from '@/stores/balance.store';
import BalanceOverview from './BalanceOverview.vue';
import TaxExpenseList from './TaxExpenseList.vue';
import TaxInvoiceList from './TaxInvoiceList.vue';
import Loading from '@/components/common/Loading.vue';
import LanguageSwitch from '@/components/common/LanguageSwitch.vue';
import { Balance } from '@/models/balance';
import { Company } from '@/models/company';
import { I18NLanguage } from '@/core/i18n';
import { useLoading } from '../common/loading.composable';

const store = useStore();
const route = useRoute();
const { loading, setLoading } = useLoading();

const balance = computed<Balance>(() => store.state.bs.balance);
const companies = computed<Company[]>(() => store.state.companies);

const { language, i18nData, setLanguage } = useI18n(I18NLanguage.en);

const type = () => {
  const is_year = balance.value.info.is_year;
  const is_quarter = balance.value.info.is_quarter;
  const is_month = balance.value.info.is_month;
  return is_year
    ? 'year'
    : is_quarter
    ? 'quarter'
    : is_month
    ? 'month'
    : 'quarter';
};

const year = computed(() => {
  return `/balance/year/${balance.value.info.title}`;
});

const load = async () => {
  const type = route.params.type;
  const date = route.params.date;
  setLoading(true);
  await store.dispatch(BalanceActions.GetBalance, {
    type,
    date,
  });
  await store.dispatch(Actions.GetCompanies);
  setLoading(false);
};

onMounted(() => {
  load();
});

watch(route, load);
</script>
<template>
  <section v-if="balance" class="screen balance">
    <Loading v-if="!balance"></Loading>

    <h2
      v-text="`Front Crafter ${i18nData.overview} ${balance.info.title}`"
    ></h2>
    <LanguageSwitch
      :language="language"
      @set-language="setLanguage($event)"
    ></LanguageSwitch>
    <div class="language-switch">
      <RouterLink :to="year">
        <span class="icon icon-calendar"></span>See year balance
      </RouterLink>
    </div>
    <BalanceOverview
      :i18n="i18nData"
      :overview="balance.overview"
      :loading="loading"
      hide-title
    ></BalanceOverview>
    <TaxInvoiceList
      :i18n="i18nData"
      :invoices="balance.invoices"
      :companies="companies"
    ></TaxInvoiceList>
    <TaxExpenseList
      :i18n="i18nData"
      :expenses="balance.expenses"
    ></TaxExpenseList>
  </section>
</template>
