<script lang="ts" setup>
import { computed, PropType, markRaw } from 'vue';
import { useStore } from 'vuex';
import { Company } from '@/models/company';
import { Invoice } from '@/models/invoice';
import { formatCurrency } from '@/filters/filters';
import InvoiceStatusModal from '@/components/balance/InvoiceStatusModal.vue';
import { ModalMutations } from '@/stores/modal.store';

const store = useStore();
const companies = computed<Company[]>(() => store.state.companies);

const { invoice } = defineProps({
  invoice: {
    type: Object as PropType<Invoice>,
    required: true,
  },
});

const invoiceUrl = computed(() => {
  return `/balance/invoice/${invoice.number}`;
});
const companyName = computed(() => {
  const company = companies.value.length
    ? companies.value.find((c) => c.id === invoice.company_id)
    : null;

  return company ? company.name : '';
});

const showInvoiceStatusModal = () => {
  if (!invoice.paid) {
    store.commit(ModalMutations.OpenModal, {
      modal: markRaw(InvoiceStatusModal),
      data: {
        invoice,
      },
    });
  }
};
</script>
<template>
  <li>
    <div class="primary-label" v-raise-ordinal>
      {{ invoice.date_created_f }}
    </div>
    <section>
      <div class="title">
        <RouterLink :to="invoiceUrl">{{ invoice.number }}</RouterLink>
        - {{ invoice.subject }}, {{ companyName }}
      </div>
    </section>
    <div class="tags">
      <div
        class="tag tag-link"
        @click="showInvoiceStatusModal"
        :class="{ 'tag-expired': invoice.expired }"
        v-if="!invoice.paid"
      >
        Unpaid
      </div>
      <div class="tag">
        <span class="currency">{{ formatCurrency(invoice.total) }}</span>
      </div>
    </div>
  </li>
</template>
