import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "compound"
}
const _hoisted_4 = { key: 2 }

import { PropType } from 'vue';
import { Invoice } from '@/models/invoice';
import InvoiceItem from './InvoiceItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceList',
  props: {
  invoices: {
    type: Array as PropType<Invoice[]>,
    required: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (__props.showHeader)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "Invoices"))
      : _createCommentVNode("", true),
    (__props.invoices.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.invoices, (invoice) => {
            return (_openBlock(), _createBlock(InvoiceItem, {
              key: invoice.id,
              invoice: invoice
            }, null, 8, ["invoice"]))
          }), 128))
        ]))
      : (__props.showHeader)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, "There are no invoices for this period."))
        : _createCommentVNode("", true)
  ]))
}
}

})