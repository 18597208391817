import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item" }

import { PropType, computed } from 'vue';
import FieldNumber from '@/components/common/FieldNumber.vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestItemFields',
  props: {
  modelValue: {
    type: Object as PropType<{ price: number; vat: number; id: number }>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const total = computed(
  () => props.modelValue.price * (props.modelValue.vat + 1),
);

const rules: ValidationArgs = {
  price: {
    required,
    decimal,
  },
};
const $v = useVuelidate(rules, props.modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FieldNumber, {
      label: "Price",
      styling: "block",
      modelValue: props.modelValue.price,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((props.modelValue.price) = $event)),
      validation: _unref($v).price,
      step: "1.00"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "VAT",
      styling: "block",
      modelValue: props.modelValue.vat,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((props.modelValue.vat) = $event)),
      validation: _unref($v).vat,
      step: "0.01"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(FieldNumber, {
      label: "Total",
      readonly: "",
      styling: "block",
      modelValue: total.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((total).value = $event)),
      step: "1.00"
    }, null, 8, ["modelValue"])
  ]))
}
}

})