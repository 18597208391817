<script lang="ts" setup>
import { computed, onMounted, markRaw } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Actions } from '@/store';
import { Company } from '@/models/company';
import { Project } from '@/models/project';
import CompanyForm from '@/components/company/CompanyForm.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import CompoundList, { CompoundListItem } from '../common/CompoundList.vue';
import { DrawerMutations } from '@/stores/drawer.store';

const store = useStore();
const route = useRoute();
const company = computed<Company>(() => store.state.company);
const companyProjects = computed<Project[]>(() => store.state.companyProjects);

onMounted(() => {
  const id = route.params.id;
  store.dispatch(Actions.GetCompany, id);
  store.dispatch(Actions.GetCompanyProjects, id);
});

const props = (): CompoundListItem[] => {
  let list = [
    { name: 'Description', description: company.value.description },
    { name: 'Contact', description: company.value.contact },
  ];
  if (company.value.label) {
    list.splice(0, 0, { name: 'Name', description: company.value.label });
  }
  if (company.value.address) {
    list = [
      ...list,
      { name: 'Address', description: company.value.address.name },
      {
        name: '',
        description: company.value.address.line_one,
      },
      {
        name: '',
        description: `${company.value.address.zipcode} ${company.value.address.city}`,
      },
      {
        name: '',
        description: company.value.address.country,
      },
    ];
  }
  return list;
};

const editCompany = async (id: number) => {
  await store.dispatch(Actions.GetCompany, id);
  store.commit(DrawerMutations.OpenDrawer, {
    drawer: markRaw(CompanyForm),
    data: {
      company: company.value,
    },
  });
};
</script>
<template>
  <section class="company" v-if="company">
    <h2>
      <span v-text="company.name"></span>
      <Tooltip text="Edit company" inline>
        <button
          type="button"
          class="option icononly"
          @click="editCompany(company.id)"
        >
          <span class="icon icon-pencil"></span>
        </button>
      </Tooltip>
    </h2>
    <CompoundList :list="props()" simple />
    <h3>Projects</h3>
    <ol>
      <li
        v-for="project in companyProjects"
        :key="project.id"
        :class="{ 'project-finished': project.archived }"
      >
        <RouterLink
          :to="`/project/${project.id}`"
          v-text="project.name"
        ></RouterLink>
      </li>
    </ol>
  </section>
</template>
