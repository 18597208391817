import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "invoiced-company" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]

import { PropType } from 'vue';
import { Company } from '@/models/company';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceCompanyInfo',
  props: {
  company: {
    type: Object as PropType<Company>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      textContent: _toDisplayString(__props.company.name)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      textContent: _toDisplayString(__props.company.address.name)
    }, null, 8, _hoisted_3),
    _createElementVNode("div", {
      textContent: _toDisplayString(__props.company.address.line_one)
    }, null, 8, _hoisted_4),
    (__props.company.address.line_two)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          textContent: _toDisplayString(__props.company.address.line_two)
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        textContent: _toDisplayString(__props.company.address.zipcode)
      }, null, 8, _hoisted_6),
      _cache[0] || (_cache[0] = _createTextVNode("  ")),
      _createElementVNode("span", {
        textContent: _toDisplayString(__props.company.address.city)
      }, null, 8, _hoisted_7)
    ])
  ]))
}
}

})