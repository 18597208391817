import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = {
  key: 0,
  class: "compound"
}
const _hoisted_3 = { key: 1 }

import { PropType } from 'vue';
import { Expense } from '@/models/expense';
import ExpenseItem from './ExpenseItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseList',
  props: {
  expenses: {
    type: Array as PropType<Expense[]>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Expenses", -1)),
    (__props.expenses.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.expenses, (expense) => {
            return (_openBlock(), _createBlock(ExpenseItem, {
              key: expense.id,
              expense: expense
            }, null, 8, ["expense"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, "There are no expenses for this period."))
  ]))
}
}

})