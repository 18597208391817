import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import { computed, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { ProjectOverview } from '@/models/timesheet';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectOverview',
  props: {
  project: {
    type: Object as PropType<ProjectOverview>,
    required: true,
  },
},
  setup(__props) {

const route = useRoute();


const timesheet = computed(() => {
  return route.params.date
    ? `/timesheet/project/${__props.project.id}/${route.params.date}`
    : `/timesheet/project/${__props.project.id}`;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(_component_RouterLink, { to: timesheet.value }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.project.name), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("td", {
      class: "hours",
      textContent: _toDisplayString(__props.project.hours)
    }, null, 8, _hoisted_1)
  ]))
}
}

})