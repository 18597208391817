import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "fields" }

import { Address } from '@/models/address';
import Field from '@/components/common/Field.vue';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressFields',
  props: {
  modelValue: { type: Object as PropType<Address>, required: true },
  validation: { type: Object, required: true },
  loading: { type: Boolean, required: true },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const update = () => {
  emit('update:modelValue', __props.modelValue);
};

return (_ctx: any,_cache: any) => {
  return (__props.validation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Address information", -1)),
        _createVNode(Field, {
          label: "Name",
          prop: "address",
          onInput: update,
          validation: __props.validation.name,
          modelValue: __props.modelValue.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.modelValue.name) = $event)),
          disabled: __props.loading
        }, null, 8, ["validation", "modelValue", "disabled"]),
        _createVNode(Field, {
          label: "Line 1",
          prop: "address",
          onInput: update,
          validation: __props.validation.line_one,
          modelValue: __props.modelValue.line_one,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.modelValue.line_one) = $event)),
          disabled: __props.loading
        }, null, 8, ["validation", "modelValue", "disabled"]),
        _createVNode(Field, {
          label: "Line 2",
          prop: "address",
          onInput: update,
          validation: __props.validation.line_two,
          modelValue: __props.modelValue.line_two,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.modelValue.line_two) = $event)),
          disabled: __props.loading
        }, null, 8, ["validation", "modelValue", "disabled"]),
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", null, null, -1)),
          _createVNode(Field, {
            label: "Zipcode",
            prop: "address",
            onInput: update,
            validation: __props.validation.zipcode,
            modelValue: __props.modelValue.zipcode,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((__props.modelValue.zipcode) = $event)),
            disabled: __props.loading,
            styling: "block"
          }, null, 8, ["validation", "modelValue", "disabled"]),
          _createVNode(Field, {
            label: "City",
            prop: "address",
            onInput: update,
            validation: __props.validation.city,
            modelValue: __props.modelValue.city,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((__props.modelValue.city) = $event)),
            disabled: __props.loading,
            styling: "block",
            "message-styling": "bottom"
          }, null, 8, ["validation", "modelValue", "disabled"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})