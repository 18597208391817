<script lang="ts" setup>
import { computed, onMounted, PropType } from 'vue';
import { useStore } from 'vuex';
import { BalanceActions } from '@/stores/balance.store';
import { Actions } from '@/store';
import { Invoice } from '@/models/invoice';
import { Project } from '@/models/project';
import { Company } from '@/models/company';
import InvoiceList from '../balance/InvoiceList.vue';

const store = useStore();
const props = defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const invoices = computed<Invoice[]>(() => store.state.bs.invoices);
const companies = computed<Company[]>(() => store.state.companies);

onMounted(async () => {
  await store.dispatch(Actions.GetCompanies);
  await store.dispatch(BalanceActions.GetProjectInvoices, {
    projectId: props.project.id,
  });
});
</script>
<template>
  <InvoiceList
    :invoices="invoices"
    :companies="companies"
    v-if="invoices.length"
  />
</template>
