<script lang="ts" setup>
import { PropType } from 'vue';
import { TaxData, taxDataValidations } from '@/models/taxes';
import useVuelidate from '@vuelidate/core';
import FieldNumber from '../common/FieldNumber.vue';
import FieldSwitch from '../common/FieldSwitch.vue';
import Field from '../common/Field.vue';

const { data } = defineProps({
  data: {
    type: Object as PropType<TaxData>,
    required: true
  },
});

const $v = useVuelidate(taxDataValidations, data);

</script>
<template>
  <div class="card white spaced">
    <div class="columns">
      <label class="col half">Date received</label>
      <Field
      type="date"
        styling="col half block"
        v-model="data.date_received"
        :validation="$v.date_received"
      />
    </div>
    <div class="columns tax-data-fields">
      <label class="col two-quarters reposition">Box 1</label>
      <FieldNumber
        label="Income"
        styling="col quarter block"
        v-model.number="data.box_1_income"
        :validation="$v.box_1_income"
        step="1.00"
      />
      <FieldNumber
        label="Taxes"
        styling="col quarter block"
        v-model.number="data.box_1_taxes"
        :validation="$v.box_1_taxes"
        step="1.00"
      />
      </div>
      <div class="columns">
        <label class="col two-quarters">Box 2</label>
        <FieldNumber
        styling="col quarter block"
        v-model.number="data.box_2_income"
        :validation="$v.box_2_income"
        step="1.00"
      />
      <FieldNumber
        styling="col quarter block"
        v-model.number="data.box_2_taxes"
        :validation="$v.box_2_taxes"
        step="1.00"
      />
          </div>
      <div class="columns">
        <label class="col two-quarters">Box 3</label>
        <FieldNumber
        styling="col quarter block"
        v-model.number="data.box_3_income"
        :validation="$v.box_3_income"
        step="1.00"
      />
      <FieldNumber
        styling="col quarter block"
        v-model.number="data.box_3_taxes"
        :validation="$v.box_3_taxes"
        step="1.00"
      />
    </div>
    <div class="columns">
      <label class="col three-quarters">National Insurance Premium</label>
      <FieldNumber
        styling="col quarter block"
        v-model.number="data.national_insurance_premium"
        :validation="$v.national_insurance_premium"
        step="1.00"
      />
    </div>
    <div class="columns">
      <label class="col three-quarters">Tax Credits</label>
      <FieldNumber
        styling="col quarter block"
        v-model.number="data.tax_credits"
        :validation="$v.tax_credits"
        step="1.00"
      />
    </div>
    <FieldSwitch label="Preliminary" styling="block" v-model="data.preliminary" />
  </div>
</template>
<style scoped>
.reposition {
  position: relative;
  top: 23px;
}
</style>
