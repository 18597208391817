<script lang="ts" setup>
import { I18NData } from '@/core/i18n';
import { TaxData, TaxInfo } from '@/models/taxes';
import { computed, PropType } from 'vue';
import { formatCurrency } from '@/filters/filters';
import { useStore } from 'vuex';
import { Balance } from '@/models/balance';

const { i18n, type } = defineProps({
  i18n: {
    type: Object as PropType<I18NData>,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

const store = useStore();

const balance = computed<Balance>(() => store.state.bs.balance);

const taxInfo = computed<TaxInfo>(() => balance.value.taxes)

const data = computed<TaxData>(() => {
  const definitive = taxInfo.value.data.find(d => !d.preliminary);

  return definitive || taxInfo.value.data[taxInfo.value.data.length -1]
})

const total = computed<number>(() => {
  return data.value.box_1_taxes + data.value.box_2_taxes + data.value.box_3_taxes + data.value.national_insurance_premium - data.value.tax_credits
})

const percentage = computed<string>(() => {
  return `${Math.round(total.value / balance.value.overview.balance.without_vat * 100)}%`
})

</script>
<template>
  <h3 v-if="type === 'year'" v-text="i18n.taxes"></h3>
  <table v-if="type === 'year' && taxInfo.data && taxInfo.data.length">
    <tbody>
      <tr>
        <td width="25%"></td>
        <th  width="25%" class="invoice-currency" v-text="i18n.taxes_box_1_income"></th>
        <td  width="25%" class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_1_income)}}</span>
        </td>
        <td width="25%"></td>
      </tr>
      <tr>
        <th class="invoice-currency" colspan="3" v-text="i18n.taxes_box_1_taxes"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_1_taxes)}}</span>
        </td>
      </tr>
      <tr v-if="data.box_2_taxes">
        <td></td>
        <th class="invoice-currency" v-text="i18n.taxes_box_2_income"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_2_income)}}</span>
        </td>
        <td></td>
      </tr>
      <tr v-if="data.box_2_taxes">
        <th class="invoice-currency" colspan="3" v-text="i18n.taxes_box_2_taxes"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_2_taxes)}}</span>
        </td>
      </tr>
      <tr v-if="data.box_3_taxes">
        <td></td>
        <th class="invoice-currency" v-text="i18n.taxes_box_3_income"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_3_income)}}</span>
        </td>
        <td></td>
      </tr>
      <tr v-if="data.box_3_taxes">
        <th class="invoice-currency" colspan="3" v-text="i18n.taxes_box_3_taxes"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.box_3_taxes)}}</span>
        </td>
      </tr>
      <tr>
        <th class="invoice-currency" colspan="3" v-text="i18n.taxes_national_insurance_premium"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(data.national_insurance_premium)}}</span>
        </td>
      </tr>
      <tr v-if="data.tax_credits">
        <th class="invoice-currency" colspan="3" v-text="i18n.taxes_taxcredits"></th>
        <td class="balance-detail invoice-currency">
            <span class="currency">-{{formatCurrency(data.tax_credits)}}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th class="invoice-currency" colspan="3">{{i18n.taxes_total}} ({{percentage}})</th>
        <td class="balance-detail invoice-currency">
            <span class="currency">{{formatCurrency(total)}}</span>
        </td>
      </tr>
    </tfoot>
  </table>
  <p v-if="type === 'year' && taxInfo.data && !taxInfo.data.length" class="" v-text="i18n.taxesNoInfo"></p>
</template>
