<script lang="ts" setup>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import Field from '@/components/common/Field.vue';
import Loading from '@/components/common/Loading.vue';
import Message from '@/components/common/Message.vue';
import { Auth } from '../models/auth';
import { useLoading } from './common/loading.composable';
import { Actions } from '@/store';

const store = useStore();
const router = useRouter();

const data = reactive({
  user_email: '',
  user_password: '',
});
const rules = {
  user_email: {
    required,
    email,
  },
  user_password: {
    required,
    minLength: minLength(7),
  },
};
const $v = useVuelidate(rules, data);
const { loading, setLoading } = useLoading();
const showError = ref<boolean>(false);

const submit = async () => {
  $v.value.$touch();

  if (!$v.value.$invalid) {
    showError.value = false;
    setLoading(true);

    const auth: Auth = await store.dispatch(Actions.Login, {
      user_email: data.user_email,
      user_password: data.user_password,
    });

    if (auth) {
      router.push('/');
    } else {
      showError.value = true;
    }
    setLoading(false);
  }
};
</script>
<template>
  <section class="box login-form">
    <form @submit.prevent="submit" :class="{ submitting: loading }">
      <Loading v-if="loading"></Loading>
      <h2>Login</h2>
      <p>Enter your email and password to login.</p>
      <Field
        label="Email"
        v-auto-focus="'focus'"
        autocomplete="on"
        :validation="$v.user_email"
        v-model="data.user_email"
        :disabled="loading"
      ></Field>
      <Field
        label="Password"
        type="password"
        :validation="$v.user_password"
        v-model="data.user_password"
        :disabled="loading"
        message-styling="bottom"
      ></Field>
      <Message
        :show="showError"
        type="alert"
        message="Login failed"
        styling="inline"
      ></Message>
      <div class="buttons">
        <button
          class="confirm"
          type="submit"
          :disabled="loading || $v.$invalid"
        >
          <span class="icon icon-key"></span>
          <span class="text">Login</span>
        </button>
      </div>
    </form>
  </section>
</template>

<style scoped>
.login-form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 120px;
}
</style>
