import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["value", "disabled", "onKeydown"]
const _hoisted_4 = {
  key: 1,
  class: "options"
}
const _hoisted_5 = ["textContent", "onClick"]

import { computed, onMounted, PropType, ref, watch } from 'vue';
import { FieldComposable, fieldProps } from './field.composable';
import { getMessage } from '@/core/validation';
import { Option } from '@/models/option';
import Message from '@/components/common/Message.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldSelect',
  props: {
  ...fieldProps,
  modelValue: {
    type: [String, Number, () => null], //Object as PropType<String | Number | null>, // causes complaints about input
    default: '',
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
  },
  options: {
    type: Array as PropType<Option<string | null | number>[]>,
    default: () => [],
  },
  showNull: {
    type: Boolean,
    default: true,
  },
},
  emits: [
  'update:modelValue',
  'enter',
  'input',
  'escape',
  `key.${String}`,
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { fieldValue, update, name } = FieldComposable<string | number | null>(
  props.modelValue as any,
  emit,
  props.label,
  props.prop,
);

const isOpen = ref<boolean>(false);
const focusIndex = ref<number>(-1);

const fieldValueName = computed(() => {
  const item = props.options.find((v) => v.value === fieldValue.value);
  return fieldValue.value && item ? item.name : `[${name.value}]`;
});

const updateValue = () => {
  if (props.options.length) {
    const item = props.options.find((o) => o.value === props.modelValue);

    fieldValue.value = item ? item.value : null;
  }
};

watch(() => props.modelValue, updateValue);

onMounted(() => {
  updateValue();
  window.setTimeout(updateValue.bind(this), 50);
});

const open = () => {
  isOpen.value = true;
};

const close = (_: any, to = 150) => {
  setTimeout(() => {
    isOpen.value = false;
  }, to);
};

const choose = (evt: Event, option?: Option<string | number | null>) => {
  if (!option) {
    option = props.options[focusIndex.value];
  }

  fieldValue.value = option.value;
  update();
  close(null, 0);
  evt.stopPropagation();
};

const down = () => {
  if (!isOpen.value) {
    open();
  }
  focusIndex.value += 1;
};

const up = () => {
  focusIndex.value -= 1;
};

const stopping = (evt: Event) => {
  evt.stopImmediatePropagation();
  return false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field field-select", [
      { invalid: props.validation.$invalid && props.validation.$dirty },
      props.styling,
      { open: isOpen.value },
    ]]),
    onClick: _withModifiers(stopping, ["stop"])
  }, [
    (props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(props.label) + " ", 1),
          (props.validation && props.validation.$invalid)
            ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "text",
      value: fieldValueName.value,
      readonly: "",
      disabled: props.disabled,
      onClick: open,
      onBlur: close,
      onKeydown: [
        _withKeys(_withModifiers(down, ["stop"]), ["down"]),
        _withKeys(_withModifiers(up, ["stop"]), ["up"]),
        _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (choose($event)), ["stop"]), ["enter"]))
      ]
    }, null, 40, _hoisted_3),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              textContent: _toDisplayString(option.name),
              onClick: _withModifiers(($event: any) => (choose($event, option)), ["stop"]),
              class: _normalizeClass({ focussed: index === focusIndex.value })
            }, null, 10, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(Message, {
      type: "alert",
      styling: props.messageStyling,
      message: _unref(getMessage)(props.validation),
      show: props.validation.$invalid && props.validation.$dirty,
      onDismiss: _cache[1] || (_cache[1] = ($event: any) => (props.validation.$reset()))
    }, null, 8, ["styling", "message", "show"])
  ], 2))
}
}

})