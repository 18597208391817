<script lang="ts" setup>
import { computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { TimeUtil } from '@/core/util/time.util';
import Field from '@/components/common/Field.vue';

interface HoursHelpTime {
  start: string;
  end: string;
}

const rules = {
  start: {
    required,
  },
  end: {
    required,
  },
};

const times = reactive<HoursHelpTime>({
  start: '',
  end: '',
});

const $v = useVuelidate(rules, times);

const value = computed(() => {
  return TimeUtil.diff(times.start, times.end);
});

const emit = defineEmits(['close']);

const submit = (evt: Event) => {
  evt.preventDefault();
  emit('close', 'help', value.value);
};

const close = () => {
  emit('close', 'help');
};
</script>
<template>
  <section class="times">
    <Field
      label="Start"
      v-auto-focus="'focus'"
      @enter="submit"
      @escape="close"
      placeholder="00:00"
      :validation="$v.start"
      v-model="times.start"
    ></Field>
    <Field
      label="End"
      @enter="submit"
      @escape="close"
      placeholder="00:00"
      :validation="$v.end"
      v-model="times.end"
    ></Field>
  </section>
</template>
