import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "weektotals" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

import { computed, PropType } from 'vue';
import { useStore } from 'vuex';
import { TimeUtil } from '@/core/util/time.util';
import { isWeekTotal, Days, WeekTotal } from '@/models/timesheet';
import { TimesheetDay } from '@/models/timesheet-day';
import Tooltip from '@/components/common/Tooltip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WeekTotals',
  props: {
  data: {
    type: Object as PropType<WeekTotal>,
    default: () => {
      return {};
    },
  },
},
  setup(__props) {

const store = useStore();

const timesheet_hours = computed<TimesheetDay[]>(
  () => store.getters['ts/timesheet_hours'],
);



const week_total = computed<string>(() => {
  return TimeUtil.minutesToTime(
    timesheet_hours.value
      .filter(
        (day: Days) =>
          day.week_number === __props.data.week_number && !isWeekTotal(day),
      )
      .reduce((acc: number, day: Days) => {
        acc += (day as TimesheetDay).minutes || 0;
        return acc;
      }, 0),
  );
});

const week_all_total = computed(() => {
  return TimeUtil.minutesToTime(
    TimeUtil.decimalToMinutes(__props.data.week_all_total) -
      TimeUtil.decimalToMinutes(__props.data.week_total) +
      TimeUtil.timeToMinutes(week_total.value),
  );
});

const getTooltipWeek = () => {
  return `Total hours for this project in week ${__props.data.week_number}`;
};
const getTooltipWeekAll = () => {
  return `Total hours for all projects in week ${__props.data.week_number}`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(Tooltip, {
      text: getTooltipWeek(),
      containerStyle: "total week-project-total"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          textContent: _toDisplayString(week_total.value)
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["text"]),
    _createVNode(Tooltip, {
      text: getTooltipWeekAll(),
      containerStyle: "total week-all-total"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          textContent: _toDisplayString(week_all_total.value)
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["text"])
  ]))
}
}

})